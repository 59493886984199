import React from 'react';
import PropTypes from 'prop-types';

import { createCssUrl } from 'common/utils/createUrl';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';

import formatPrice from 'common/utils/formatPrice';
import useSite from 'sites/contexts/sites';
import NotFoundImage from '../../../assets/image-not-found.jpg';

const OrderItem = ({ item }) => {
  const { currentSite } = useSite();

  return (
    <>
      <div className="order-details--item">
        <div
          className="order-details--item-img"
          style={{
            backgroundImage: item.product?.images[0]?.url || item.service?.images[0].url
              ? createCssUrl(item.product?.images[0]?.url
                ? item.product?.images[0]?.url
                : item.service?.images[0]?.url)
              : `url(${NotFoundImage})`,
          }}
          alt={item.product.name['en-GB']}
        />
        <div className="order-details--item-content">
          <div>
            <span className="order-details--item-name">
              <TranslatedText value={item.product ? item.product.name : item.service.name} />
            </span>
            <span className="order-details--item-quantity">
              {`${item.quantity} x ${item.product ? formatPrice(item.product.price, currentSite?.currency)
                : formatPrice(item.product.price, currentSite?.currency)}`}

            </span>
            <p>
              {item.selected_options.map((selected, selectedIndex) => (
                <>
                  {selected.variants.map((variant, variantIndex) => (
                    <>
                      <TranslatedText value={variant.name} />
                      {variantIndex + 1 < selected.variants.length && (
                      <span> + </span>
                      )}
                    </>
                  ))}
                  {selectedIndex + 1 < item.selected_options.length && (
                  <span>, </span>
                  )}
                </>
              ))}
            </p>

          </div>
          {/* <span className="order-details--item-total">
            {console.log(item)}
            {`${item.product ? formatPrice(item.product?.price * item.quantity, currentSite?.currency)
              : formatPrice(item.service?.price * item.quantity, currentSite?.currency)}`}
            </span> */ }

        </div>
        { item.comments && (
        <div className="order-details--comments">
          {item.comments}
        </div>
        ) }
      </div>
    </>

  );
};

OrderItem.propTypes = {
  item: PropTypes.shape.isRequired,
};

export default OrderItem;
