import FormInput from 'common/components/FormInput/FormInput';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import useLang from 'common/hooks/use-lang';
import fetchJSON from 'common/utils/fetchJSON';
import Button from 'common/components/Button/Button';

const SiteCompanyForm = ({ site }) => {
  const { t } = useTranslation();
  const { langsBo } = useLang();

  const currencyOptions = [
    { value: 'EUR', label: 'Euro' },
    { value: 'USD', label: 'US Dollar' },
    { value: 'AED', label: 'UAE Dirham' },
    { value: 'GBP', label: 'Pound Sterling' },
    { value: 'MXN', label: 'Mexican Peso' },
  ];

  const handleStripeConnect = async () => {
    const accountLink = await fetchJSON({
      url: `sites/${site.id}/stripe`,
      method: 'GET',
    });

    window.open(accountLink.url, '_self');
  };

  const handleStripeLogOut = async () => {
    await fetchJSON({
      url: `sites/${site.id}/stripe`,
      method: 'DELETE',
    });

    window.location.reload();
  };

  useEffect(() => {
    const fetchStripeAccountData = async () => {
      if (site) {
        const stripeAccountVerified = await fetchJSON({
          url: `sites/${site.id}/stripe/verify`,
          method: 'GET',
        });

        site.stripeAccountVerified = stripeAccountVerified;
      }
    };

    fetchStripeAccountData();
  }, [site]);

  return (
    <>
      <section className="section" style={{ marginTop: 20, marginBottom: 20 }}>
        <div className="row">
          <div>
            <FormInput
              name="company_name"
              label={t('sites.companyName')}
              icon="heading"
            />
            {/* <FormInput
              name="company_address"
              label={t('sites.companyAddress')}
  /> */}
            <FormInput
              name="address"
              type="geolocation"
              label={t('common.address')}
              required
            />
            <FormInput
              name="phone"
              label={t('sites.phone')}
              icon="phone"
            />

            <FormInput
              type="select"
              name="default_language"
              label={t('common.language')}
              options={langsBo.map((lang) => ({ value: lang, label: t(`common.${lang}`) }))}
              required
            />
            <FormInput
              type="select"
              name="currency"
              label={t('common.currency')}
              options={currencyOptions}
              required
            />
            {site?.canPayOnline && (
            <Button
              style={{ marginBottom: '2rem' }}
              label={site?.stripeAccountId && site?.stripeAccountVerified
                ? t('sites.stripeLogOut') : t('sites.stripeConnect')}
              color="primary"
              onClick={() => site?.stripeAccountId ? handleStripeLogOut() : handleStripeConnect()}
            />
            )}

            {/* instagram */}
            <FormInput
              name="instagramShow"
              label={t('sites.instagramShow')}
              type="checkbox"

            />

            <FormInput
              name="instagramLink"
              label={t('sites.instagramLink')}
            />
          </div>
          <div style={{
            textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
          >
            {site?.id ? (
              <QRCode
                size={200}
                // value={`${process.env.REACT_APP_FRONT_URL}/?site=${site?.id}`}
                // env var doesn't work in prod mod
                value={`https://app.pleasemycar.com/?site=${site?.id}`}
              />
                ) : null}
          </div>
        </div>

      </section>
    </>
  );
};

SiteCompanyForm.propTypes = {
  site: PropTypes.object,
};

SiteCompanyForm.defaultProps = {
  site: {},

};

export default SiteCompanyForm;
