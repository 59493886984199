import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const HourSlotModelContext = createContext();

export const HourslotModelsProvider = ({ children }) => (
  <ContextProvider url="hour-slot-models" context={HourSlotModelContext}>{children}</ContextProvider>
);

HourslotModelsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useHourSlotModel = () => useContext(HourSlotModelContext);

export default useHourSlotModel;
