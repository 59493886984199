import React, {
  useEffect, useCallback, useContext, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import useMap from 'maps/contexts/maps';
import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import useSite from 'sites/contexts/sites';
import SelectorPanel from './components/SelectorPanel/SelectorPanel';
import useBeachEditor from './contexts/beachEditor';
import Board from './components/Board/Board';

const BeachEditor = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    fetchItem: fetchMap, item: map, update, isFetching,
  } = useMap();
  const [mapScale, setMapScale] = useState(0);
  const { id: mapId } = router.match.params;
  const {
    setSeats, seats, mode, changeMode, setSite, setMap, map: updatedMap,
  } = useBeachEditor();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const { currentSite } = useSite();

  const areaRef = useCallback((node) => {
    if (node) {
      setDimensions({ width: node.getBoundingClientRect().width, height: node.getBoundingClientRect().height });
    }
  }, []);

  const { setAlert } = useContext(AlertsContext);

  useEffect(() => {
    const getDatas = (async () => {
      const res = await fetchMap(mapId);

      let seats = res?.seats ? res.seats : [];

      seats = seats.filter(({ active }) => active);

      if (seats.length > 0 && seats[0].x < 10) {
        seats = seats.map((seat) => ({ ...seat, x: seat.x * 90 + 50, y: seat.y * 90 + 200 }));
      }

      setSeats(seats);
      setMap(res);
      setSite(res?.site);
      setMapScale(res.mapBackOfficeScale);
    });

    getDatas();
  }, [setSeats, setMap, setSite, fetchMap, mapId]);

  const saveConfiguration = useCallback(async () => {
    const payload = { ...updatedMap };

    payload.seats = seats;
    payload.mapBackOfficeScale = mapScale;
    const res = await update(payload.id, payload, false);

    if (!res.error) {
      setAlert(t('maps.successEdit'), 'success');
    } else {
      setAlert(res.message, 'success');
    }
  }, [update, setAlert, t, updatedMap, seats, mapScale]);

  // Pour éviter d'afficher une autre plage (a cause du cache du Context)
  if (!map || map.id !== parseInt(mapId, 10)) {
    return null;
  }

  const backImage = map?.backgroundImage ? (
    process.env.REACT_APP_API_URL + map.backgroundImage.url)
    : '/assets/haut-de-plage.png';

  let width = 800;

  let height = 800;

  let scale = 1;

  if (dimensions) {
    width = (map?.backgroundWidth ? map.backgroundWidth : 800) * mapScale;
    height = (map?.backgroundHeight ? map.backgroundHeight : 800) * mapScale;
    const scaleH = dimensions.height / height;
    const scaleW = dimensions.width / width;

    scale = scaleH < scaleW ? scaleH : scaleW;
  }

  return (

    <TransformWrapper
      pan={{ disableOnTarget: ['BoardItem'] }}
      doubleClick={{ disabled: true }}
      defaultScale={mapScale || 1}
      defaultPositionX={map.mapBackOfficePositionX ? map.mapBackOfficePositionX : 0}
      defaultPositionY={map.mapBackOfficePositionY ? map.mapBackOfficePositionY : 0}
      options={{ limitToBounds: false }}
    >
      {({
        zoomIn, zoomOut, positionX, positionY, previousScale,
      }) => (
        <div className="beach-organizer">
          <SelectorPanel />
          <div className="beach-editor" ref={areaRef}>
            <div className="beach-header">
              <div className="beach-mode buttons has-addons">
                <button
                  className={mode === 'plan'
                    ? 'button is-active beach-editor-menu--button'
                    : 'button beach-editor-menu--button'}
                  onClick={() => changeMode('plan')}
                >
                  { t('menu.plan') }
                </button>
                <button
                  className={mode === 'furniture'
                    ? 'button is-active beach-editor-menu--button'
                    : 'button beach-editor-menu--button'}
                  onClick={() => changeMode('furniture')}
                >
                  { t('menu.furnitures') }
                </button>
                <button
                  className={mode === 'price'
                    ? 'button is-active beach-editor-menu--button'
                    : 'button beach-editor-menu--button'}
                  onClick={() => changeMode('price')}
                >
                  { t('menu.priceAreas') }
                </button>
                <button
                  className={mode === 'waiter'
                    ? 'button is-active beach-editor-menu--button'
                    : 'button beach-editor-menu--button'}
                  onClick={() => changeMode('waiter')}
                >
                  { t('menu.waiterAreas') }
                </button>
              </div>
              <button
                className={`button save-button is-success ${isFetching ? 'is-loading' : ''}`}
                disabled={isFetching}
                onClick={saveConfiguration}
              >
                {t('beach.save')}
              </button>
            </div>
            <TransformComponent>
              <div style={dimensions}>
                <DndProvider backend={Backend}>
                  <Board
                    className="beach-visualizer"
                    scale={scale}
                    previousScale={previousScale}
                    siteId={currentSite.id}
                    mapId={map?.id}
                    style={{
                      backgroundImage: `url(${backImage})`,
                      width,
                      height,
                      transform: `scale(${scale})`,
                      transformOrigin: 'top left',
                    }}
                  />
                </DndProvider>
              </div>
            </TransformComponent>
            <div className="zoom">
              <button
                className="zoom-plus"
                onClick={zoomIn}
              >
                +
              </button>
              <button
                className="zoom-minus"
                onClick={zoomOut}
              >
                -
              </button>
            </div>
            { mode === 'plan' && (
            <div className="map-scale">
              <button
                className="zoom-plus"
                onClick={() => setMapScale(Math.round((mapScale + 0.1) * 10) / 10)}
              >
                +
              </button>
              <span>{mapScale}</span>
              <button
                className="zoom-minus"
                onClick={() => mapScale > 0.1 && setMapScale(Math.round((mapScale - 0.1) * 10) / 10)}
              >
                -
              </button>
            </div>
            )}
            <div className="mapInfo">
              <div>{`x : ${positionX}, y : ${positionY}, scale :${previousScale}`}</div>
            </div>
          </div>
        </div>
      )}
    </TransformWrapper>

  );
};

export default BeachEditor;
