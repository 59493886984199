import { useTranslation } from 'react-i18next';
import useSite from 'sites/contexts/sites';

const useLang = (defaultLang) => {
  const { i18n } = useTranslation();
  const { currentSite } = useSite();
  // TODO load langs from API
  const langsBo = ['en-GB', 'fr-FR', 'it-IT', 'ru-RU'];
  const langsBoOrdered = [];

  const langsFront = ['en-GB', 'fr-FR', 'de-DE', 'es-ES', 'pt-PT', 'it-IT', 'ru-RU', 'ar', 'zh'];
  const langsFrontOrdered = [];

  // bo

  if (langsBo.includes(defaultLang)) {
    langsBoOrdered.push(defaultLang);
    langsBo.splice(langsBo.indexOf(defaultLang), 1);
  }

  if (currentSite && langsBo.includes(currentSite.default_language)) {
    langsBoOrdered.push(currentSite.default_language);
    langsBo.splice(langsBo.indexOf(currentSite.default_language), 1);
  }

  if (langsBo.includes(i18n.language)) {
    langsBoOrdered.push(i18n.language);
    langsBo.splice(langsBo.indexOf(i18n.language), 1);
  }

  // front

  if (langsFront.includes(defaultLang)) {
    langsFrontOrdered.push(defaultLang);
    langsFront.splice(langsFront.indexOf(defaultLang), 1);
  }

  if (currentSite && langsFront.includes(currentSite.default_language)) {
    langsFrontOrdered.push(currentSite.default_language);
    langsFront.splice(langsFront.indexOf(currentSite.default_language), 1);
  }

  if (langsFront.includes(i18n.language)) {
    langsFrontOrdered.push(i18n.language);
    langsFront.splice(langsFront.indexOf(i18n.language), 1);
  }

  return { langsBo: [...langsBoOrdered, ...langsBo], langsFront: [...langsFrontOrdered, ...langsFront] };
};

export default useLang;
