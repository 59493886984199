/* eslint-disable object-curly-newline */
import Button from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import AlertsContext from 'common/contexts/alerts';
import usePriceArea from 'priceAreas/contexts/priceAreas';
import PriceAreaForm from 'priceAreas/pages/PriceAreaFormPage/PriceAreaForm';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useBeachEditor from 'sites/pages/BeachEditor/contexts/beachEditor';

const PriceAreaSelector = () => {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);

  const {
    save, update, remove, fetchItems: fetchPriceAreas, items: priceAreas,
  } = usePriceArea();

  const {
    setSelectedSelector, selectedSelector, mode, updateSeatInformation, site,
  } = useBeachEditor();

  const [showModal, setShowModal] = useState(false);
  const [selectedPriceAreaModal, setSelectedPriceAreaModal] = useState(null);

  const handleOpenModal = useCallback((priceArea) => {
    setSelectedPriceAreaModal(priceArea);
    setShowModal(true);
  }, [setShowModal]);

  const handleDelete = useCallback(async (row) => {
    await remove(row.id);
    await fetchPriceAreas();
  }, [remove, fetchPriceAreas]);

  const handleSubmitModal = useCallback(async (data) => {
    const payload = { ...data };

    if (data.id) {
      const res = await update(payload.id, payload);

      if (!res.error) {
        setAlert(t('priceAreas.successEdit'), 'success');
        fetchPriceAreas();
        setShowModal(false);
      } else {
        setAlert(res.message, 'danger');
      }
    } else {
      const res = await save(payload);

      if (!res.error) {
        setAlert(t('priceAreas.successAdd'), 'success');
        fetchPriceAreas();
        setShowModal(false);
      } else {
        setAlert(res.message, 'danger');
      }
    }
  }, [fetchPriceAreas, save, setAlert, t, update]);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  useEffect(() => {
    if (mode === 'price' && priceAreas?.length) {
      setSelectedSelector({
        id: priceAreas[0].id,
        type: 'price',
        price_area: priceAreas[0],
      });
    }
  }, [mode, priceAreas, setSelectedSelector]);

  useEffect(() => {
    fetchPriceAreas();
  }, [fetchPriceAreas]);

  useEffect(() => {
    if (priceAreas?.length) {
      updateSeatInformation('price_area', priceAreas);
    }
  }, [priceAreas, updateSeatInformation]);

  return (
    <div className="selector-panel">
      { showModal && (
      <Modal confirmModal={() => {}} closeModal={handleCloseModal}>
        <PriceAreaForm
          site={site.id}
          onSubmit={handleSubmitModal}
          priceArea={selectedPriceAreaModal}
        />
      </Modal>
      )}
      <Button
        color="primary"
        label={t('priceAreas.addPriceArea')}
        icon="fa-plus"
        onClick={handleOpenModal}
      />
      <div className="selectors">
        { priceAreas && priceAreas.map((item, index) => (
          <div
            key={index}
            alt={item.name}
            className={`selector ${item.id === selectedSelector.id && 'selected'}`}
            onClick={
              () => setSelectedSelector({
                id: item.id,
                type: 'price',
                price_area: item,
              })
            }
          >
            <div className="circleSelectorWrapper">
              <div
                className="circleSelector"
                style={{ backgroundColor: item.color }}
              />
            </div>
            <div className="selectorName">
              { item.name }
              {item.id === selectedSelector.id && (
                <p>
                  {t('common.clicOnMap')}
                </p>
              )}
            </div>
            <div className="actionsButton">
              <Button
                onClick={() => handleOpenModal(item)}
                icon="fa-pencil-alt"
                color="primary"
                transparent
              />
              <Button
                onClick={() => handleDelete(item)}
                icon="fa-trash-alt"
                color="danger"
                confirm
                transparent
                confirmMessage={t('priceAreas.confirmDelete')}
              />
            </div>

          </div>
        ))}
        <div
          className={`selector ${selectedSelector.id === 'delete' && 'selected'}`}
          onClick={() => {
            setSelectedSelector({
              id: 'delete',
              type: 'furniture',
              price_area: null,
            });
          }}
        >
          <i className=" selector-icon fas fa-trash-alt" />
          <div className="selectorName">
            {t('common.delete')}
            {selectedSelector.id === 'delete' && 'selected' && (
            <p>
              {t('common.clicOnMapDelete')}
            </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceAreaSelector;
