import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import FormInput from 'common/components/FormInput/FormInput';
import useSite from 'sites/contexts/sites';
import SiteSelect from 'sites/components/SiteSelect';
import UserSelect from 'users/components/UserSelect';

const PreparatorAreaForm = ({ onSubmit, preparatorArea }) => {
  const { t } = useTranslation();
  const { currentSite } = useSite();
  const role = 'barman';

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={preparatorArea?.id ? {
        ...preparatorArea,
        users: preparatorArea.users?.map(({ id }) => id),
        site: preparatorArea?.site?.id,
      } : { site: currentSite ? currentSite.id : null }}
      render={({
        form,
        handleSubmit,
        submitting,
        pristine,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormInput
            name="name"
            label={t('common.name')}
            icon="heading"
            required
          />
          <i style={{ display: 'block', marginBottom: '10px' }}>
            {t('preparatorAreas.preparatorNameExplanation')}
          </i>
          <FormInput
            name="color"
            label={t('users.color')}
            type="circle-color"
            required
          />
          <FormInput
            type="custom"
            name="users"
            label={t('common.barmans')}
          >
            <UserSelect
              role={role}
              multiple
            />
          </FormInput>
          {!currentSite && (
            <FormInput
              type="custom"
              name="site"
              label={t('common.site')}
              required
            >
              <SiteSelect />
            </FormInput>
          )}
          <FormInput
            type="submit"
            label={t('common.save')}
            icon="check"
            disabled={submitting || pristine}
          />
        </form>
      )}
    />
  );
};

PreparatorAreaForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  preparatorArea: PropTypes.object,
};

PreparatorAreaForm.defaultProps = {
  preparatorArea: {},
};

export default PreparatorAreaForm;
