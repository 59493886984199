/* eslint-disable react/prop-types */
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo } from 'react';
import useWatch from 'watches/contexts/watches';
import Button from 'common/components/Button/Button';
import useRouter from '../../../common/hooks/use-router';
import useSite from '../../../sites/contexts/sites';
import SiteSelect from '../../../sites/components/SiteSelect';
import PageHeader from '../../../common/components/PageHeader/PageHeader';
import Table from '../../../common/components/Table/Table';

const WatchesList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { currentSite } = useSite();
  const {
    fetchItems: fetchWatches, items: watches, isFetching: isFetchingWatches,
    pageCount: pageCountWatches, filters: filtersWatches, setFilter: setFilterWatches,
    itemsCount: itemsCountWatches, remove: deleteWatch,
  } = useWatch();

  useEffect(() => {
    fetchWatches();
  }, [fetchWatches]);

  const handleDelete = useCallback(async (row) => {
    await deleteWatch(row.original.id);
    fetchWatches();
  }, [deleteWatch, fetchWatches]);

  const handleEdit = useCallback((row) => (
    router.push(`/watches/edit/${row.original.id}`)
  ), [router]);

  const columnsWatches = useMemo(() => [
    {
      Header: t('common.name'),
      accessor: 'name',
      filterName: 'name_contains',
      Cell: ({ row: { original: { id, name } } }) => (
        <span className="table-link" onClick={() => router.push(`/watches/edit/${id}`)}>
          {name}
        </span>
      ),
    },
    {
      Header: t('watch.comments'),
      accessor: 'comments',
      filterName: 'comments_contains',
      Cell: ({ row: { original: { comments } } }) => (
        <span>
          {comments}
        </span>
      ),
    },
    {
      Header: t('watch.id'),
      accessor: 'pushToken',
      filterName: 'pushToken_contains',
      Cell: ({ row: { original: { pushToken } } }) => (
        <span>
          {pushToken}
        </span>
      ),
    },
    {
      Header: t('watch.userName'),
      accessor: 'user.id',
      filterName: 'user.username_contains',
      Cell: ({ row: { original: { user } } }) => (
        <span style={{ fontWeight: 'bold' }}>
          {user?.username || ''}
        </span>
      ),
    },
    {
      Header: t('common.site'),
      filterName: 'site.id',
      hidden: !!currentSite,
      filterComponent: (props) => <SiteSelect isFilter {...props} />,
      Cell: ({ row: { original: { site } } }) => (
        <span>
          {site?.name}
        </span>
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDelete(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('watch.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, currentSite, router, handleEdit, handleDelete]);

  return (
    <>
      <PageHeader
        title={t('watch.watches')}
        link={{ url: '/watches/add', icon: 'user-plus', label: t('watch.addWatch') }}
        /* customHeader={(
          <div className="headerlink" onClick={() => console.log('What ?')}>
            <span className="page-header--link-label">{t('customers.importWatches')}</span>
            <span className="icon page-header--link-button button is-light">
              <i className="fas fa-upload" />
            </span>
          </div>
        )} */
      />

      <section className="section list-page has-overflow-x-table">
        <Table
          data={watches}
          columns={columnsWatches}
          fetchData={fetchWatches}
          isFetching={isFetchingWatches}
          pageCount={pageCountWatches}
          filters={filtersWatches}
          setFilter={setFilterWatches}
          itemsCount={itemsCountWatches}
        />

      </section>
    </>
  );
};

export default WatchesList;
