import FormInput from 'common/components/FormInput/FormInput';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSite from 'sites/contexts/sites';

const TemplateProductForm = ({ template, values }) => {
  const { t } = useTranslation();
  const { currentSite } = useSite();

  // TEMPORARY ARRAY
  const productDisplayOptions = [
    { value: 'list', label: t('template.productList') },
    { value: 'list-icons', label: t('template.productIcons') },
    { value: 'grid', label: t('template.productColumn') },
  ];

  const productsByColumnsOptions = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
  ];

  if (currentSite && template && template.site.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  return (

    <section className="section">
      <div className="row">
        <FormInput
          label={t('template.productPriceDisplay')}
          name="isPriceVisible"
          type="checkbox"
        />
        <FormInput
          label={t('template.productDetailDisplay')}
          name="isProductDetailVisible"
          type="checkbox"
        />
      </div>
      <div className="row">
        <FormInput
          label={t('template.productDisplay')}
          name="productDisplayType"
          type="select"
          options={productDisplayOptions}
        />

        {values.productDisplayType === 'grid' && (
        <FormInput
          label={t('template.productsByColumn')}
          name="productsByColumn"
          type="select"
          options={productsByColumnsOptions}
        />
        )}
      </div>
    </section>

  );
};

TemplateProductForm.propTypes = {
  template: PropTypes.object,
  values: PropTypes.object,
};

TemplateProductForm.defaultProps = {
  template: {},
  values: {},
};

export default TemplateProductForm;
