import React, {
  useEffect, useContext, useCallback,
} from 'react';

import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import PageHeader from 'common/components/PageHeader/PageHeader';

import useSite from 'sites/contexts/sites';

import PageLoader from 'common/components/PageLoader/PageLoader';
import SiteForm from './SiteForm';

const SiteFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, fetchItem: fetchSite, item: site, currentSite, isFetching,
  } = useSite();
  const { id: siteId } = router.match.params;

  useEffect(() => { fetchSite(siteId); }, [fetchSite, siteId]);

  const onSubmit = useCallback(async (site) => {
    site.beach_data = {
      col: 6,
      row: 4,
    };
    site.active = true;

    if (site.id) {
      const res = await update(site.id, site);

      if (!res.error) {
        setAlert(t('sites.successEdit'), 'success');
        if (!(currentSite && currentSite.id === res.id)) {
          router.push('/sites');
        }
      } else {
        setAlert(res.message, 'success');
      }
    } else {
      const res = await save(site);

      if (!res.error) {
        setAlert(t('sites.successAdd'), 'success');
        if (!(currentSite && currentSite.id === res.id)) {
          router.push('/sites');
        }
      } else {
        setAlert(res.message, 'success');
      }
    }
  }, [router, update, save, setAlert, t, currentSite]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`sites.${site && site.id ? 'edit' : 'add'}Site`)}
        subLink={!(currentSite && site && currentSite.id === site.id)
          ? { url: '/sites', icon: 'arrow-left', label: t('common.back') } : null}
      />
      <section className="section form-page">
        <SiteForm
          onSubmit={onSubmit}
          site={site}
        />
      </section>
    </>
  );
};

export default SiteFormPage;
