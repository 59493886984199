import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import Table from 'common/components/Table/Table';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Button from 'common/components/Button/Button';

import useHourSlotModel from 'hourSlotModels/contexts/hourSlot';
import SiteSelect from 'sites/components/SiteSelect';
import useSite from 'sites/contexts/sites';

const HourSlotModelsList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    fetchItems: fetchHourSlots, items: hourSlotModel, isFetching, pageCount, remove, filters, setFilter, update,
    itemsCount,
  } = useHourSlotModel();
  const { currentSite } = useSite();

  const handleDelete = useCallback(async (row) => {
    await remove(row.original.id);
    await fetchHourSlots();
  }, [remove, fetchHourSlots]);

  const handleEdit = useCallback((row) => router.push(`/hour-slot-model/edit/${row.original.id}`), [router]);

  const handleToggle = useCallback(async (id) => {
    const hourSlot = hourSlotModel && hourSlotModel.find((p) => p.id === id);

    // eslint-disable-next-line no-restricted-syntax
    for (const hourItem of hourSlotModel) {
      if (hourItem.id !== hourSlot.id && hourItem.active === true) {
        update(hourItem.id, { ...hourItem, active: false });
      }
    }
    await update(hourSlot.id, { ...hourSlot, active: true });
    await fetchHourSlots();
  }, [update, fetchHourSlots, hourSlotModel]);

  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      filterName: 'name_contains',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { id, name } } }) => (
        <span className="table-link" onClick={() => router.push(`/hour-slot-model/edit/${id}`)}>
          {name}
        </span>
      ),
    },
    {
      Header: t('common.site'),
      filterName: 'category_item.site.id',
      hidden: !!currentSite,
      filterComponent: (props) => <SiteSelect isFilter {...props} />,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { category_item: category } } }) => (
        <span>
          {category?.site?.name}
        </span>
      ),
    },
    {
      Header: t('common.active'),
      accessor: 'active',
      Cell: ({ row: { original: { active, id } } }) => (
        <Button
          onClick={() => { if (!active) { handleToggle(id); } }}
          icon={active ? 'fa-check' : 'fa-times'}
          color={active ? 'success' : 'danger'}
          transparent
        />
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDelete(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('hourSlotModel.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, currentSite, router, handleToggle, handleEdit, handleDelete]);

  return (
    <>
      <PageHeader
        title={t('menu.listHourSlotModel')}
        link={{
          url: '/hour-slot-model/add',
          icon: 'plus',
          label: t('hourSlotModel.addNewModel'),
        }}
      />
      <section className="section list-page">
        <Table
          data={hourSlotModel}
          columns={columns}
          fetchData={fetchHourSlots}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          itemsCount={itemsCount}
        />
      </section>
    </>
  );
};

export default HourSlotModelsList;
