import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import FormInput from 'common/components/FormInput/FormInput';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';

import useSite from 'sites/contexts/sites';
import SiteSelect from 'sites/components/SiteSelect';
import CategoryServiceSelect from 'categoryServices/components/CategoryServiceSelect';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import Button from 'common/components/Button/Button';
import fetchJSON from 'common/utils/fetchJSON';

const processCategoryServiceOptions = (options) => options.filter(({ parent }) => parent === null)
  .map(({ id, name }) => ({ value: id, label: <TranslatedText value={name} displayEnglish /> }));

const CategoryServiceForm = ({ onSubmit, categoryService }) => {
  const { t, i18n } = useTranslation();
  const [children, setChildren] = useState([]);
  const { currentSite } = useSite();

  const handleOnDataLoaded = useCallback((categoryServices) => {
    if (categoryService && categoryService.id) {
      setChildren(categoryServices.find(({ parent }) => parent && categoryService.id === parent.id));
    }
  }, [categoryService]);

  const handleTranslate = async (values, setFieldValue) => {
    let translated;
    const { name } = values;

    if (name) {
      translated = await fetchJSON({
        url: 'sites/translate',
        method: 'POST',
        payload: { items: { name }, site: currentSite.id },
      });

      setFieldValue({
        field: 'name',
        value: translated.name,
      });
    }
  };

  if (currentSite && categoryService && categoryService.id && categoryService.site?.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  // eslint-disable-next-line camelcase
  const defaultLang = categoryService ? categoryService.site?.default_language : (
    currentSite ? currentSite.default_language : i18n.language);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={categoryService?.id ? {
        ...categoryService,
        site: categoryService?.site?.id,
        parent: categoryService?.parent?.id,
      } : { site: currentSite ? currentSite.id : null }}
      mutators={{
        setFieldValue: ([field], state, utils) => {
          utils.changeValue(state, field.field, () => field.value);
        },
      }}
      render={({
        values, handleSubmit, submitting, pristine, form,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormScrollError />
          <Button
            style={{ marginBottom: '2rem' }}
            label="Translate"
            icon="fa-language"
            color="primary"
            confirm
            confirmMessage={t('common.translateConfirm')}
            onClick={() => handleTranslate(values, form.mutators.setFieldValue)}
          />
          <MultilingualInput
            name="name"
            label={t('common.name')}
            icon="heading"
            value={values.name}
            defaultLang={defaultLang}
            required
          />
          <FormInput
            name="reference"
            label={t('common.reference')}
            icon="tag"
          />
          <FormInput
            type="custom"
            name="parent"
            label={t('common.parent')}
          >
            <CategoryServiceSelect
              filters={['id_ne']}
              id_ne={categoryService?.id}
              disabled={children && children.length !== 0}
              onDataLoaded={handleOnDataLoaded}
              processOptions={processCategoryServiceOptions}
            />
          </FormInput>
          <FormInput
            type="number"
            name="ordering"
            label={t('common.index')}
          />
          {!currentSite && (
          <FormInput
            type="custom"
            name="site"
            label={t('common.site')}
            required
          >
            <SiteSelect />
          </FormInput>
          )}
          <FormInput
            type="submit"
            label={t('common.save')}
            icon="tag"
            disabled={submitting || pristine}
          />
        </form>
      )}
    />
  );
};

CategoryServiceForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  categoryService: PropTypes.object,
};

CategoryServiceForm.defaultProps = {
  categoryService: {},
};

export default CategoryServiceForm;
