import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import useRouter from 'common/hooks/use-router';
import FormInput from 'common/components/FormInput/FormInput';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';
import useSite from 'sites/contexts/sites';
import SiteSelect from 'sites/components/SiteSelect';
// import BookingSelect from 'bookings/components/BookingSelect';
import CategorySelect from 'categories/components/CategorySelect';
import CategoryServiceSelect from 'categoryServices/components/CategoryServiceSelect';
import ProductSelect from 'products/components/ProductSelect';
import ServiceSelect from 'services/components/ServiceSelect';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';

const SpecialOfferForm = ({ onSubmit, specialOffer }) => {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const { currentSite } = useSite();
  const { destBooking } = router.query;

  const initialValues = useMemo(() => specialOffer?.id
    ? {
      ...specialOffer,
      site: specialOffer.site.id,
      booking: specialOffer.booking?.id,
      categories: specialOffer.categories?.map(({ id }) => id),
      // eslint-disable-next-line camelcase
      category_services: specialOffer.category_services?.map(({ id }) => id),
      products: specialOffer.products?.map(({ id }) => id),
      services: specialOffer.services?.map(({ id }) => id),
    }
    : {
      ...specialOffer,
      site: currentSite ? currentSite.id : null,
      booking: destBooking ? parseInt(destBooking, 10) : null,
    }, [specialOffer, currentSite, destBooking]);

  if (currentSite && specialOffer && specialOffer.id && specialOffer.site?.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  // eslint-disable-next-line camelcase
  const defaultLang = specialOffer ? specialOffer.site?.default_language : (
    currentSite ? currentSite.default_language : i18n.language);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({
        values, handleSubmit, submitting, pristine, form,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className="columns">
            <div className="column">
              <MultilingualInput
                name="message"
                label={t('specialOffers.message')}
                icon="heading"
                value={values.message}
                defaultLang={defaultLang}
                required
              />
              {specialOffer && (
              <FormInput
                isHorizontal
                type="checkbox"
                name="active"
                label={t('common.active')}
              />
              )}
              <FormInput
                type="number"
                name="discount_rate"
                label={t('specialOffers.discountRate')}
                icon="money-bill"
                required
              />
              { /* <FormInput
                type="custom"
                name="booking"
                label={t('specialOffers.booking')}
              >
                <BookingSelect />
              </FormInput>
              */ }

              {!currentSite && (
                <FormInput
                  type="custom"
                  name="site"
                  label={t('common.site')}
                  required
                >
                  <SiteSelect />
                </FormInput>
              )}
            </div>
            <div className="column">
              <div className="helpText">
                { t('specialOffers.helpText') }
              </div>
              <FormInput
                type="custom"
                name="categories"
                label={t('specialOffers.categories')}
              >
                <CategorySelect
                  site_null={0}
                  multiple
                  site={values.site}
                />
              </FormInput>
              <FormInput
                type="custom"
                name="category_services"
                label={t('specialOffers.categoryServices')}
              >
                <CategoryServiceSelect
                  multiple
                  site={values.site}
                />
              </FormInput>
              <FormInput
                type="custom"
                name="products"
                label={t('specialOffers.products')}
              >
                <ProductSelect
                  multiple
                />
              </FormInput>
              <FormInput
                type="custom"
                name="services"
                label={t('specialOffers.services')}
              >
                <ServiceSelect
                  multiple
                />
              </FormInput>
            </div>
          </div>
          <FormScrollError />
          <FormInput
            type="submit"
            label={t('common.save')}
            icon="gift"
            disabled={submitting || pristine}
          />
        </form>
      )}
    />
  );
};

SpecialOfferForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  specialOffer: PropTypes.object,
};

SpecialOfferForm.defaultProps = {
  specialOffer: {},
};

export default SpecialOfferForm;
