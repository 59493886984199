import React, {
  // eslint-disable-next-line comma-dangle
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import useSite from 'sites/contexts/sites';

import Button from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Table from 'common/components/Table/Table';
import { createUploadUrl } from 'common/utils/createUrl';
import getUserDisplayName from 'common/utils/getUserDisplayName';
import moment from 'moment';
import OrderDetails from 'orders/components/OrderDetails/OrderDetails';
import useOrder from 'orders/contexts/orders';
import zeroPad from 'common/utils/zeroPad';
import formatPrice from 'common/utils/formatPrice';

const ActivesOrdersList = () => {
  const { t, i18n: { language } } = useTranslation();
  const { currentSite } = useSite();
  const {
    fetchItem: fetchOrder,
    fetchItems: fetchOrders,
    setItems: setOrders,
    items: orders,
    isFetching,
    pageCount, remove,
    filters: orderFilters,
    setFilters,
    setFilter: setOrderFilter,
    setSort,
    updateOrderState,
    // fetchActiveOrders,
    itemsCount,
  } = useOrder();
  const [modalActive, setModalActive] = useState(false);
  const [detailedOrder, setDetailedOrder] = useState(null);

  /* const getActivesAndSetOrders = useCallback(async () => {
    const activeOrders = await fetchActiveOrders();

    setOrders(activeOrders);
  }, [fetchActiveOrders, setOrders]);

  useEffect(() => {
    getActivesAndSetOrders();
  }, [getActivesAndSetOrders]); */

  const openModal = useCallback(async (row) => {
    const order = await fetchOrder(row.original.id);

    setDetailedOrder(order);
    setModalActive(true);
  }, [fetchOrder]);

  const closeModal = () => {
    setModalActive(false);
  };

  const handleStateChange = async (id, value) => {
    const order = orders && orders.find((p) => p.id === id);

    if (order) {
      await updateOrderState(order.id, { state: value });
      await fetchOrders();

      // await getActivesAndSetOrders();
    }
  };

  const handleDelete = useCallback(async (id) => {
    await remove(id);
    await fetchOrders();

    // await getActivesAndSetOrders();
    closeModal();
  }, [remove, fetchOrders]);

  const columns = useMemo(() => [
    {
      Header: t('orders.id'),
      Cell: ({ row: { original: { id } } }) => (
        <span>
          {id}
        </span>
      ),
    },
    {
      Header: '#',
      Cell: ({ row: { original: { displayedId } } }) => (
        <span>
          {zeroPad(displayedId, 3)}
        </span>
      ),
    },
    {
      Header: t('orders.user'),
      Cell: ({ row: { original: { user, userInfo } } }) => (
        <span>
          {user?.name || userInfo?.name || (user ? getUserDisplayName(user) : t('users.unregistered'))}
        </span>
      ),
    },
    {
      Header: t('orders.created'),
      customFormat: (value) => (moment(value).utc().startOf('day').format()),
      Cell: ({ row: { original: { created_at: createdAt } } }) => (
        <>
          {moment(createdAt).locale(language).format('LL hh:mm')}
        </>
      ),
    },
    {
      Header: t('menu.preparatorAreas'),
      Cell: ({ row: { original: { preparatorAreas } } }) => (
        preparatorAreas ? (
          <span>{preparatorAreas.name}</span>
        ) : (
          <></>
        )
      ),
    },
    {
      Header: t('orders.state'),
      Cell: ({ row: { original: { state } } }) => (
        <span className={`order-state-tag ${state === 'done' ? 'is-done' : ''}`}>
          <i className={`is-size-7 fas fa-${state === 'done' ? 'check' : 'hourglass'}`} />
          <span>
            {t(`orders.${state}`)}
          </span>
        </span>
      ),
    },
    {
      Header: t('orders.paymentMethod'),
      Cell: ({ row: { original: { payment, price, tip } } }) => {
        const pay = payment || {};
        const { online: isPaidOnline } = pay;

        const icon = isPaidOnline ? 'credit-card' : 'money-bill';
        const label = isPaidOnline ? t('orders.creditCard') : t('orders.cash');

        return icon ? (
          <span>
            <i className={`has-text-primary fas fa-${icon}`} />
            &nbsp;
            <b>
              {`${formatPrice(price, currentSite?.currency)}`}
            </b>
            <br />
            (
            {label}
            )

            {currentSite?.pleaseMyCar && (
              <>
                <br />
                <span>
                  {`${t('orders.tip')} : ${`${formatPrice(tip || 0, currentSite?.currency)}`}`}
                </span>
              </>
            )}
          </span>

        ) : (
          <></>
        );
      },
    },
    {
      Header: t('invoices.invoiceNumber'),
      Cell: ({ row: { original: { payment } } }) => {
        const pay = payment || {};
        const { invoiceNumber } = pay;

        return (
          <span>
            {pay?.invoicePath
              ? (
                <Button
                  onClick={() => window.open(createUploadUrl(pay.invoicePath))}
                  label={invoiceNumber}
                  color="primary"
                />
            ) : (
              invoiceNumber
            )}
          </span>
        );
      },
    },
    /* {
      Header: t('menu.waiterAreas'),
      filterName: 'waiterAreas.name',
      Cell: ({ row: { original: { waiterAreas } } }) => (
        waiterAreas && (
          <span>{waiterAreas.name}</span>
        )
      ),
    }, */
    {
      Header: t('common.site'),
      hidden: !!currentSite,
      Cell: ({ row: { original: { site } } }) => (
        <span>
          {site?.name}
        </span>
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => openModal(row)}
            icon="fa-eye"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDelete(row.original.id)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('orders.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, currentSite, openModal, handleDelete, language]);

  useEffect(() => {
    setFilters({ state_ne: 'done' });
    setSort('created_at:DESC');
  }, [setSort, setFilters]);

  /* useEffect(() => {
    setOrderFilter('site_null', 0);
  }, [setOrderFilter]); */

  return (
    <>
      <PageHeader title={t('menu.activesListOrders')} />
      <section className="section list-page has-overflow-x-table">
        <Table
          data={orders}
          columns={columns}
          fetchData={fetchOrders}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={orderFilters}
          setFilter={setOrderFilter}
          itemsCount={itemsCount}
        />
      </section>
      {modalActive && (
        <Modal closeModal={closeModal}>
          <OrderDetails handleDelete={handleDelete} handleStateChange={handleStateChange} order={detailedOrder} />
        </Modal>
      )}
    </>
  );
};

export default ActivesOrdersList;
