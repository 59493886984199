import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import Table from 'common/components/Table/Table';
import DatePicker from 'common/components/DatePicker/DatePicker';
import Button from 'common/components/Button/Button';
import useReport from 'reports/contexts/reports';
import PageHeader from 'common/components/PageHeader/PageHeader';

const ReportsList = () => {
  const { t, i18n: { language } } = useTranslation();
  const {
    fetchItems: fetchReports,
    items: reports,
    isFetching,
    pageCount,
    remove,
    filters,
    setFilter,
    itemsCount,
  } = useReport();

  const handleDelete = useCallback(async (row) => {
    await remove(row.original.id);
    fetchReports();
  }, [remove, fetchReports]);

  const columns = useMemo(() => [
    {
      Header: t('report.createdBy'),
      accessor: 'createdBy.name',
      filterName: 'createdBy.name_contains',
    },
    {
      Header: t('report.chatUser'),
      accessor: 'chatUser.name',
      filterName: 'chatUser.name_contains',
    },
    {
      Header: t('report.reportDate'),
      filterName: 'created_at',
      filterComponent: (props) => <DatePicker isFilter {...props} />,
      customFormat: (value) => (moment(value).utc().startOf('day').format()),
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { created_at: createdAt } } }) => (
        <span>{moment(createdAt).locale(language).format('LLLL')}</span>
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleDelete(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('report.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, language, handleDelete]);

  return (
    <>
      <PageHeader
        title={t('menu.listReports')}
      />
      <section className="section list-page">
        <Table
          data={reports}
          columns={columns}
          fetchData={fetchReports}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          itemsCount={itemsCount}
        />
      </section>
    </>
  );
};

export default ReportsList;
