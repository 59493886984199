import Modal from 'common/components/Modal/Modal';
import AlertsContext from 'common/contexts/alerts';
import useRouter from 'common/hooks/use-router';
import useMap from 'maps/contexts/maps';
import MapForm from 'maps/pages/MapFormPage/MapForm';
import React, {
  useCallback, useContext,
  useEffect,
  // eslint-disable-next-line comma-dangle
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import useSite from 'sites/contexts/sites';
import useBeachEditor from 'sites/pages/BeachEditor/contexts/beachEditor';

const SeatSelector = () => {
  const {
    mode, setSelectedSelector, selectedSelector,
  } = useBeachEditor();
  const {
    update, item: map,
  } = useMap();
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const { currentSite, setCurrentSite } = useSite();
  const { setAlert } = useContext(AlertsContext);
  const router = useRouter();

  const { id: mapId } = router.match.params;

  useEffect(() => {
    if (mode === 'plan') {
      setSelectedSelector({
        id: 'create',
      });
    }
  }, [mode, setSelectedSelector]);

  const handleSubmitModal = useCallback(async (data) => {
    const payload = { ...data };

    const updatedMap = await update(mapId, payload);

    setAlert(t('maps.successEdit'), 'success');
    // Updating currentSite to refresh left menu

    if (currentSite) {
      // Updating currentSite to refresh left menu
      const newCurrentSite = { ...currentSite };

      const mapIndex = newCurrentSite.maps.findIndex(((iMap) => iMap.id === map.id));

      newCurrentSite.maps[mapIndex] = updatedMap;
      setCurrentSite(newCurrentSite);
    }
    setShowModal(false);
  }, [currentSite, map.id, mapId, setAlert, setCurrentSite, t, update]);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  return (
    <div className="selector-panel">
      { showModal && (
      <Modal confirmModal={() => {}} closeModal={handleCloseModal}>
        <MapForm
          onSubmit={handleSubmitModal}
          map={map}
        />
      </Modal>
      )}
      <div className="selectors">
        <div
          className={`selector ${selectedSelector.id === 'create' && 'selected'}`}
          onClick={() => {
            setSelectedSelector({
              id: 'create',
              type: 'plan',
            });
          }}
        >
          <i className=" selector-icon fas fa-plus-square" style={{ color: 'royalblue' }} />
          <div className="selectorName">
            {t('common.add')}
            {selectedSelector.id === 'create' && (
            <p>
              {t('common.doubleClicOnMap')}
            </p>
            )}
          </div>
        </div>
        <div
          className={`selector ${selectedSelector.id === 'delete' && 'selected'}`}
          onClick={() => {
            setSelectedSelector({
              id: 'delete',
              type: 'plan',
            });
          }}
        >
          <i className=" selector-icon fas fa-trash-alt" />
          <div className="selectorName">
            {t('common.delete')}
            {selectedSelector.id === 'delete' && 'selected' && (
            <p>
              {t('common.clicOnMapDelete')}
            </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeatSelector;
