import React from 'react';
import Select from 'common/components/Select/Select';
import { useTranslation } from 'react-i18next';

const OrderFormuleSelect = (props) => {
  const { t } = useTranslation();

  const options = [
    { label: t('allInclusive.isIncluded'), value: true },
    { label: t('allInclusive.isNotIncluded'), value: false }, // false ne filtre rien
  ];

  return (
    <Select
      options={options}
      isFilter
      placeholder={t('allInclusive.title')}
      {...props}
    />
  );
};

export default OrderFormuleSelect;
