/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/prop-types */
import 'moment/locale/fr';
import React from 'react';
import DayPickerInput, { DateUtils } from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, {
  formatDate,
  // eslint-disable-next-line comma-dangle
  parseDate
} from 'react-day-picker/moment';
import { useTranslation } from 'react-i18next';

class CustomInput extends React.Component {
  render() {
    return (
      <div className="control">
        <input
          className={`input ${this.props.isfilter ? 'is-filter' : ''}`}
          label={this.props.isFilter ? this.props.label : ''}
          data-cy={this.props['data-cy']}
          {...this.props}
        />
      </div>
    );
  }
}
const DatePicker = ({
  value, onChange, isRange, 'data-cy': dataCy,
}) => {
  const { t, i18n } = useTranslation();
  const formattedValue = !value ? '' : new Date(value);
  const modifiersStyles = {
    selected: {
      backgroundColor: '#009ad4',
    },
    today: {
      color: '#ffab41',
    },
  };
  const handleDayClick = (day) => {
    const range = DateUtils.addDayToRange(day, value);

    onChange(range);
  };

  return (
    <div className="date-picker">
      {isRange ? (
        <DayPickerInput
          value={formattedValue}
          component={(props) => <CustomInput {...props} data-cy={dataCy} />}
          formatDate={formatDate}
          parseDate={parseDate}
          label={t('datePicker.label')}
          onDayClick={(day) => handleDayClick(day)}
          dayPickerProps={{
            selectedDays: value,
            locale: i18n.language,
            localeUtils: MomentLocaleUtils,
            modifiersStyles,
          }}
        />
      ) : (
        <DayPickerInput
          value={formattedValue}
          component={(props) => <CustomInput {...props} data-cy={dataCy} />}
          formatDate={formatDate}
          parseDate={parseDate}
          label={t('datePicker.label')}
          onDayChange={(day) => onChange(day)}
          dayPickerProps={{
            selectedDays: formattedValue,
            locale: i18n.language,
            localeUtils: MomentLocaleUtils,
            modifiersStyles,
          }}
        />
      )}
    </div>
  );
};

export default DatePicker;
