import FormInput from 'common/components/FormInput/FormInput';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSite from 'sites/contexts/sites';

const TemplateCategoriesForm = ({ values, template }) => {
  const { t } = useTranslation();
  const { currentSite } = useSite();

  // TEMPORARY ARRAY
  const categoriesDisplayOptions = [
    { value: 'list', label: t('template.productList') },
    { value: 'list-icons', label: t('template.productIcons') },
    { value: 'grid', label: t('template.productColumn') },
  ];

  const categoriesByColumnsOptions = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
  ];

  if (currentSite && template && template.site.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  return (

    <section className="section">

      <FormInput
        label={t('template.showRootCategoriesPage')}
        name="showRootCategoriesPage"
        type="checkbox"
      />
      {values.showRootCategoriesPage && (
      <div className="row">
        <FormInput
          label={t('template.categoriesDisplay')}
          name="categoriesDisplayType"
          type="select"
          options={categoriesDisplayOptions}
        />

        {values.categoriesDisplayType === 'grid' && (
        <FormInput
          label={t('template.categoriesByColumn')}
          name="categoriesByColumn"
          type="select"
          options={categoriesByColumnsOptions}
        />
        )}
      </div>
      )}
    </section>
  );
};

TemplateCategoriesForm.propTypes = {
  template: PropTypes.object,
  values: PropTypes.object,
};

TemplateCategoriesForm.defaultProps = {
  template: {},
  values: {},
};

export default TemplateCategoriesForm;
