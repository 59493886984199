import FormInput from 'common/components/FormInput/FormInput';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import VatSelect from 'vat/components/VatSelect';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';

const SiteStoreForm = ({ values, user }) => {
  const { t, i18n } = useTranslation();

  const defaultLang = values ? values.default_language : i18n.language;

  return (
    <>
      {user?.role.name === 'superadmin' && (
        <section className="section" style={{ marginTop: 20 }}>

          <div className="row">
            {/* <FormInput
                  name="hotel"
                  type="checkbox"
                  label={t('sites.isHotel')}
            /> */}

            <FormInput
              name="isDemo"
              type="checkbox"
              label={t('sites.isDemo')}
            />

            {/* <FormInput
              name="pleaseMyCar"
              type="checkbox"
              label={t('sites.isPleaseMyCar')}
              disabled
              /> */}

            <FormInput
              name="pleaseMyCarValet"
              type="checkbox"
              label={t('sites.isPleaseMyCarValet')}
            />

            <FormInput
              name="pleaseMyCarDriver"
              type="checkbox"
              label={t('sites.isPleaseMyCarDriver')}
            />
            <span className="spacer" />
          </div>

          <h2 className="subtitle" style={{ marginTop: 16 }}>{t('sites.basketAndPriceSettings')}</h2>

          <div className="row" style={{ marginTop: 16 }}>
            <FormInput
              name="all_inclusive"
              type="checkbox"
              label={t('allInclusive.siteAllInclusive')}
            />

            <FormInput
              name="showStepBasketPage"
              type="checkbox"
              label={t('sites.showStepBasketPage')}
            />

            {values.showStepBasketPage ? (
              <>
                <FormInput
                  name="showBasketAmount"
                  type="checkbox"
                  label={t('sites.showBasketAmount')}
                />

                <FormInput
                  name="showBasketTip"
                  type="checkbox"
                  label={t('sites.showBasketTip')}
                />
              </>
            ) : (
              <>
                <span className="spacer" />
                <span className="spacer" />
              </>
            )}
          </div>
          <div className="row" style={{ marginTop: 16 }}>

            <FormInput
              type="custom"
              name="vat"
              label={t('vat.percentage')}
              required
            >
              <VatSelect />
            </FormInput>
            <span className="spacer" />
            <span className="spacer" />
            <span className="spacer" />
          </div>

          <h2 className="subtitle" style={{ marginTop: 16 }}>{t('sites.paymentMethodOnline')}</h2>

          <div className="row" style={{ marginTop: 16 }}>
            <FormInput
              name="canPayOnline"
              type="checkbox"
              label={t('sites.canPayOnline')}
              disabled={values?.canPayOnline && !values?.canPayOffline}
            />

            <span className="spacer" />
          </div>
          {values?.canPayOnline && (
            <div className="row" style={{ marginTop: 16 }}>
              <FormInput
                name="onlineFees"
                type="number"
                label={t('sites.onlineFees')}
                required
              />
              <FormInput
                name="onlineTransactionFees"
                type="number"
                label={t('sites.transactionFees')}
                required
              />
              <FormInput
                name="onlineMinPrice"
                type="number"
                label={t('sites.onlineMinPrice')}
                required
              />
              <FormInput
                name="onlineMaxPrice"
                type="number"
                label={t('sites.onlineMaxPrice')}
                required
              />
            </div>
          )}

          <h2 className="subtitle" style={{ marginTop: 16 }}>{t('sites.paymentMethodOffline')}</h2>

          <div className="row" style={{ marginTop: 16 }}>

            <FormInput
              name="canPayOffline"
              type="checkbox"
              label={t('sites.canPayOffline')}
              disabled={values?.canPayOffline && !values?.canPayOnline}
            />

            {values?.canPayOffline ? (
              <FormInput
                name="offlineFees"
                type="number"
                label={t('sites.offlineFees')}
                required
              />
            ) : (
              <span className="spacer" />
            )}

            <span className="spacer" />
            <span className="spacer" />

          </div>

          {/*
          <h2 className="subtitle" style={{ marginTop: 16 }}>{t('orders.deliveryMethod')}</h2>
          <div className="row">

            <FormInput
              name="canDeliver"
              type="checkbox"
              label={t('sites.canDeliver')}
              disabled={values?.canDeliver && !values?.canTakeaway}
            />
            <FormInput
              name="canTakeaway"
              type="checkbox"
              label={t('sites.canTakeaway')}
              disabled={!values?.canDeliver && values?.canTakeaway}
            />

            <span className="spacer" />
          </div>
          */}

          <h2 className="subtitle" style={{ marginTop: 16 }}>{t('orders.clientInfos')}</h2>
          <div className="row">
            <FormInput
              name="showStepChoicePage"
              type="checkbox"
              label={t('sites.showStepChoicePage')}
            />

          </div>
          <div className="row" style={{ marginTop: 16 }}>
            <FormInput
              name="askCoordinates"
              type="checkbox"
              label={t('sites.askCoordinates')}
            />

            {values.askCoordinates ? (
              <>
                <FormInput
                  name="isMandatoryName"
                  type="checkbox"
                  label={t('sites.isMandatoryName')}
                />
                <FormInput
                  name="isMandatoryEmail"
                  type="checkbox"
                  label={t('sites.isMandatoryEmail')}
                />
                <FormInput
                  name="isMandatoryPhone"
                  type="checkbox"
                  label={t('sites.isMandatoryPhone')}
                />
              </>
            ) : (
              <>
                <span className="spacer" />
                <span className="spacer" />
                <span className="spacer" />
              </>
            )}

          </div>

          <h2 className="subtitle" style={{ marginTop: 16 }}>{t('orders.sms')}</h2>
          <div>
            <FormInput
              name="isSmsActive"
              type="checkbox"
              label={t('sites.isSmsActive')}
            />
            {values.isSmsActive && (
            <MultilingualInput
              name="smsMessage"
              label={t('sites.smsMessage')}
              type="textarea"
              value={values?.smsMessage}
              defaultLang={defaultLang}
            />
            )}
          </div>

          {/*
            <h2 className="subtitle">{t('sites.companyInformation')}</h2>

            <FormInput
              isHorizontal
              type="checkbox"
              name="isLinkedToPos"
              label={t('sites.isLinkedToPos')}
            />
            <FormInput
              name="posURL"
              label={t('sites.posURL')}
              disabled={!values.isLinkedToPos}
            />
        */}

        </section>
      )}

    </>
  );
};

SiteStoreForm.propTypes = {
  values: PropTypes.object,
  user: PropTypes.object,
};

SiteStoreForm.defaultProps = {
  values: {},
  user: {},
};

export default SiteStoreForm;
