import useBooking from 'bookings/contexts/bookings';
import BookingForm from 'bookings/pages/BookingFormPage/BookingForm';
import Button from 'common/components/Button/Button';
import DatePicker from 'common/components/DatePicker/DatePicker';
import Modal from 'common/components/Modal/Modal';
import AlertsContext from 'common/contexts/alerts';
import formatPrice from 'common/utils/formatPrice';
import moment from 'moment';
import React, {
  // eslint-disable-next-line comma-dangle
  useCallback, useContext, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import useSite from 'sites/contexts/sites';
import useBeachVisualizer from '../../contexts/beachVisualizer';

const SelectorPanel = () => {
  const { t } = useTranslation();
  const { currentSite, item: site } = useSite();

  const [showModal, setShowModal] = useState(false);
  const { setAlert } = useContext(AlertsContext);

  const { date, setDate } = useBeachVisualizer();

  const {
    save, error, setFilters: setBookingFilters,
  } = useBooking();

  const { selectedSeats, setSelectedSeats, removeSelectedSeat } = useBeachVisualizer();

  const openReservation = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleChangeDate = useCallback((date) => {
    setDate(moment(date).utc().startOf('day').format());
  }, [setDate]);

  const handleSubmitModal = useCallback(async (data) => {
    const booking = { ...data };
    const newDate = moment(booking.date).utc().startOf('day').format();

    await save({
      ...booking,
      date: newDate,
      active: true,
    });

    // By reset Filters, we force a refetch in BeachVisualizer
    setBookingFilters((filters) => ({ ...filters }));

    setSelectedSeats([]);
    setShowModal(false);

    if (!error) {
      setAlert(t('bookings.successAdd'), 'success');
    } else {
      setAlert(error, 'danger');
    }
  }, [error, save, setAlert, setBookingFilters, setSelectedSeats, t]);

  const booking = useMemo(() => ({
    date,
    seats: selectedSeats,
    site: site?.id,
  }), [date, selectedSeats, site]);

  return (
    <div className="beach-sidebar" style={{ padding: 16 }}>
      <label className="label">{t('bookings.dateBooking')}</label>
      <DatePicker
        value={date}
        onChange={handleChangeDate}
      />
      <div className="selectedSeats">
        { selectedSeats.map((seat, index) => {
          const priceAreaId = seat.price_area.id;
          const p = seat.furniture.prices.find((p) => p.price_area === priceAreaId);
          const price = p ? (
            <span className="seatPrice">
              {`${formatPrice(p.price, currentSite?.currency)}`}

            </span>
          ) : '';

          return (
            <div
              key={index}
              className="selectedSeat"
            >
              <img alt={seat.name} src={`${process.env.REACT_APP_API_URL}${seat.furniture.image.url}`} />
              {price}
              <div className="seatName" style={{ fontWeight: 700, marginLeft: 16 }}>
                #
                {seat.name}
              </div>
              <Button
                onClick={() => removeSelectedSeat(seat)}
                icon="fa-trash-alt"
                color="danger"
                transparent
              />
            </div>
          );
        }) }
        { selectedSeats.length > 0 && (
        <Button
          onClick={openReservation}
          icon="fa-book"
          color="primary"
          label={t('bookings.addBooking')}
          style={{
            marginTop: 16, display: 'block', marginLeft: 'auto', marginRight: 'auto',
          }}
        />
        )}
        { selectedSeats.length === 0 && (
        <span style={{ marginTop: 16, display: 'block' }}>{t('bookings.selectFurniture')}</span>
        ) }
      </div>
      { showModal && (
      <Modal isConfirm={false} confirmModal={() => {}} closeModal={handleCloseModal}>
        <BookingForm
          onSubmit={handleSubmitModal}
          booking={booking}
        />
      </Modal>
      )}
    </div>
  );
};

export default SelectorPanel;
