// import useBooking from 'bookings/contexts/bookings';
import useCall from 'calls/contexts/calls';
import DebounceInput from 'common/components/DebounceInput/DebounceInput';
import Modal from 'common/components/Modal/Modal';
// import useSeat from 'common/contexts/seats';
import useRouter from 'common/hooks/use-router';
import useMap from 'maps/contexts/maps';
import moment from 'moment';
import useOrder from 'orders/contexts/orders';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import useSite from 'sites/contexts/sites';
import useWaiterArea from 'waiterAreas/contexts/waiterAreas';
import Board from './components/Board/Board';
import SelectorPanel from './components/SelectorPanel/SelectorPanel';
import useBeachVisualizer from './contexts/beachVisualizer';

const BeachVisualizer = () => {
  const router = useRouter();
  const { id: mapId } = router.match.params;
  const { t } = useTranslation();
  const {
    currentSite,
  } = useSite();
  const {
    fetchItem: fetchMap, item: map, isMapFetching,
  } = useMap();
  /* const {
    fetchItems: fetchBookings,
    filters: bookingFilters,
    setFilters: setBookingFilters,
    items: bookings,
  } = useBooking(); */
  const {
    fetchItems: fetchOrders,
    filters: orderFilters,
    setFilters: setOrderFilters,
    items: orders,
  } = useOrder();
  const {
    fetchItems: fetchCalls,
    items: calls,
    setFilters: setCallFilters,
  } = useCall();
  const {
    fetchItems: fetchWaiterAreas,
    items: waiterAreas,
  } = useWaiterArea();

  // const {
  //   items: seatsTests,
  //   getSeatsInfo,
  //   setItems: setSeatsInfo,
  // } = useSeat();

  const {
    date,
    setSeats,
    seatModal,
    setSeatModal,
    searchSeat,
    setSearchSeat,
  } = useBeachVisualizer();

  // useEffect(() => {
  //   const getAndSetSeatsInfo = async () => {
  //     const res = await getSeatsInfo();

  //     setSeatsInfo(res);
  //   };

  //   getAndSetSeatsInfo();
  // }, [getSeatsInfo, setSeatsInfo]);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const areaRef = useCallback((node) => {
    if (node) {
      setDimensions({ width: node.getBoundingClientRect().width, height: node.getBoundingClientRect().height });
    }
  }, []);

  const handleCloseModal = useCallback(() => {
    setSeatModal(null);
  }, [setSeatModal]);

  const handleSearchChange = (value) => {
    setSearchSeat(value);
  };

  useEffect(() => {
    const getMap = async () => {
      const map = await fetchMap(mapId);

      if (map) {
        let { seats } = map;

        seats = seats.filter(({ active }) => active);

        if (map.seats.length && map.seats[0].x < 10) {
          seats = map.seats.map((seat) => ({ ...seat, x: seat.x * 90 + 50, y: seat.y * 90 + 200 }));
        }

        setSeats(seats);
      }
    };

    getMap();
  }, [fetchMap, mapId, setSeats]);

  useEffect(() => {
    setSeats((previousSeats) => previousSeats.map((seat) => ({
      ...seat,
      // bookings: bookings ? bookings.filter((b) => b.seats.filter((s) => s.id === seat.id).length > 0) : [],
      calls: calls ? calls.filter((c) => c.seat.id === seat.id) : [],
      orders: orders ? orders.filter((o) => o.seat?.id === seat.id) : [],
      waiterArea: waiterAreas ? waiterAreas.find((wa) => wa.site?.id === currentSite?.id) : [],
    })));
  }, [orders, waiterAreas, setSeats, currentSite, calls]); // bookings,

  // useEffect(() => {
  //   fetchBookings();
  // }, [bookingFilters, fetchBookings]);

  useEffect(() => {
    fetchOrders();
  }, [orderFilters, fetchOrders]);

  useEffect(() => {
    fetchCalls({ pageSize: 1000 });
  }, [setSeats, fetchCalls]);

  useEffect(() => {
    fetchWaiterAreas();
  }, [setSeats, fetchWaiterAreas]);

  useEffect(() => {
    const endDate = moment(date).endOf('day').format();

    // setBookingFilters({ date, active: true });
    setOrderFilters({ created_at_lte: endDate, created_at_gte: date });
    setCallFilters({ created_at_lte: endDate, created_at_gte: date });
  }, [date, setOrderFilters, mapId, setCallFilters]); // , setBookingFilters

  const backImage = map?.backgroundImage ? (
    process.env.REACT_APP_API_URL + map.backgroundImage.url)
    : '/assets/haut-de-plage.png';

  if (isMapFetching || !map) {
    return null;
  }

  let width = 800;

  let height = 800;

  let scale = 1;

  if (dimensions) {
    width = (map?.backgroundWidth ? map.backgroundWidth : 800) * map?.mapBackOfficeScale;
    height = (map?.backgroundHeight ? map.backgroundHeight : 800) * map?.mapBackOfficeScale;
    const scaleH = dimensions.height / height;
    const scaleW = dimensions.width / width;

    scale = scaleH < scaleW ? scaleH : scaleW;
  }

  return (
    <>
      <TransformWrapper
        pan={{ disableOnTarget: ['BoardItem'] }}
        doubleClick={{ disabled: true }}
        defaultScale={map.mapBackOfficeScale ? map.mapBackOfficeScale : 1}
        defaultPositionX={map.mapBackOfficePositionX ? map.mapBackOfficePositionX : 0}
        defaultPositionY={map.mapBackOfficePositionY ? map.mapBackOfficePositionY : 0}
        options={{ limitToBounds: false }}
      >
        {({
          zoomIn, zoomOut, positionX, positionY, previousScale,
        }) => (
          <div className="beach-organizer">
            <SelectorPanel />
            <div className="beach-editor" ref={areaRef}>
              <TransformComponent>
                <div style={dimensions}>
                  <Board
                    className="beach-visualizer"
                    style={{
                      backgroundImage: `url(${backImage})`,
                      width,
                      height,
                      transform: `scale(${scale})`,
                      transformOrigin: 'top left',
                    }}
                  />
                </div>
              </TransformComponent>
            </div>
            <div className="search-seat">
              <DebounceInput
                type="text"
                className="search-seat-input"
                name="searchSeat"
                onChange={(value) => handleSearchChange(value)}
                value={searchSeat}
                placeholder={t('bookings.searchPlace')}
              />
            </div>
            <div className="zoom">
              <button
                className="zoom-plus"
                onClick={zoomIn}
              >
                +
              </button>
              <button
                className="zoom-minus"
                onClick={zoomOut}
              >
                -
              </button>
            </div>
            <div className="mapInfo">
              <div>{`x : ${positionX}, y : ${positionY}, scale :${previousScale}`}</div>
            </div>
          </div>
        )}
      </TransformWrapper>
      {seatModal && (
        <Modal isConfirm={false} confirmModal={() => { }} closeModal={handleCloseModal}>
          {/* <SeatDetails seat={seatModal} seats={seats} seatsInfo={seatsTests} /> */}
        </Modal>
      )}
    </>
  );
};

export default BeachVisualizer;
