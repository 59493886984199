/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

function map(children, func) {
  let index = 0;

  return React.Children.map(children, (child) => React.isValidElement(child) ? func(child, index++) : child);
}

export const Tabs = ({ children, withIconsOnly }) => {
  const [selected, setSelected] = useState(0);

  const handleSelected = (i) => {
    setSelected(i);
  };

  return (
    <div className="tabsWithContent">
      <div
        className={`tabs ${withIconsOnly ? 'with-icons-only' : 'is-boxed'}`}

      >
        <ul style={{ justifyContent: withIconsOnly ? 'flex-end' : 'flex-start' }}>
          {map(children, (child, index) => (
            <li
              className={selected === index ? 'is-active' : ''}
            >
              <a className="tab" onClick={() => handleSelected(index)}>
                {child.props.icon && (
                  <span className={`${child.props.iconClassName} icon is-small`}>
                    {child.props.icon}
                    {child.props.required ? '*' : '' }
                  </span>
                )}
                {!withIconsOnly && child.props.label && (
                  <span>
                    {child.props.label}
                    {child.props.required ? '*' : '' }
                  </span>
                )}

              </a>
            </li>
          ))}
        </ul>
      </div>
      <div>
        {map(children, (child, index) => (
          <section
            key={`content-${index}`}
            className="tab-content"
            style={{ display: selected === index ? 'block' : 'none' }}
          >
            {child.props.children}
          </section>
        ))}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  withIconsOnly: PropTypes.bool,
};

Tabs.defaultProps = {
  withIconsOnly: false,
};

export const Tab = () => {
  throw new Error(
    'The `Tab` component is not meant to be rendered! '
      + "It's an abstract component that is only valid as a direct Child of the `Tabs` Component. ",
  );
};

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  required: PropTypes.bool,
};

export default Tabs;
