import React, {
  useState, useEffect, useContext,
} from 'react';
import PropTypes from 'prop-types';
import fetchJSON from 'common/utils/fetchJSON';
import AlertsContext from 'common/contexts/alerts';
import Select from '../Select/Select';

// filters permet de définir la liste des props qui seront passées dans le queryParam
// Si le queryParam a un nom différent de la props, on passe un objet avec queryName et propName
// filters: ['paramKey'] || filters: [{ 'queryName': '', 'propName': '' }]

export const RemoteSelect = ({
  onDataLoaded, url, filters, processOptions,
  additionnalOptions, ...rest
}) => {
  const [options, setOptions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [params, setParams] = useState({});
  const { setAlert } = useContext(AlertsContext);

  let paramsUpdated = false;
  const newParams = {};

  filters.forEach((f) => {
    if (typeof f === 'object') {
      newParams[f.queryName] = rest[f.propName];
      if (newParams[f.queryName] !== params[f.queryName]) {
        paramsUpdated = true;
      }
    } else {
      newParams[f] = rest[f];
      if (newParams[f] !== params[f]) {
        paramsUpdated = true;
      }
    }
  });

  if (paramsUpdated) {
    setParams(newParams);
  }

  useEffect(() => {
    const fetchItems = async () => {
      setIsFetching(true);
      try {
        const esc = encodeURIComponent;
        const queryParams = Object.keys(params)
          .filter((key) => params[key] != null)
          .map((key) => `${esc(key)}=${esc(params[key])}`).join('&');

        let res = await fetchJSON({
          url: `${url}${queryParams ? '?' : ''}${queryParams}`,
          method: 'GET',
        });

        onDataLoaded && onDataLoaded(res);

        if (additionnalOptions.length && Array.isArray(res)) {
          res = [...additionnalOptions, ...res];
        }

        const opts = processOptions ? processOptions(res) : res;

        setOptions(opts);
        return res;
      } catch (e) {
        setAlert(e.message, 'danger');
      } finally {
        setIsFetching(false);
      }
    };

    fetchItems();
  }, [setAlert, setIsFetching, setOptions, additionnalOptions,
    url, processOptions, params, onDataLoaded]);

  return (
    <Select
      options={options}
      isLoading={isFetching}
      {...rest}
    />
  );
};

RemoteSelect.propTypes = {
  url: PropTypes.string.isRequired,
  filters: PropTypes.array,
  processOptions: PropTypes.func,
  onDataLoaded: PropTypes.func,
  additionnalOptions: PropTypes.array,
};

RemoteSelect.defaultProps = {
  filters: [],
  processOptions: () => {},
  onDataLoaded: () => {},
  additionnalOptions: [],
};

export default RemoteSelect;
