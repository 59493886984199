import React, {
  useEffect, useContext, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';

import PageHeader from 'common/components/PageHeader/PageHeader';
import PageLoader from 'common/components/PageLoader/PageLoader';
import HourSlotModelsForm from './HourSlotModelsForm';
import useHourSlotModel from '../../contexts/hourSlot';

const HourSlotModelsFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, fetchItem, item: hourSlotModel, fetchItems, items: hourSlots, isFetching,
  } = useHourSlotModel();

  const { id: hourSlotModelId } = router.match.params;

  useEffect(() => { fetchItem(hourSlotModelId); fetchItems(); }, [fetchItem, fetchItems, hourSlotModelId]);

  const onSubmit = useCallback(async (hourSlotModel) => {
    const payload = { ...hourSlotModel };

    if (hourSlotModel.id) {
      if (hourSlotModel.active) {
        // eslint-disable-next-line no-restricted-syntax
        for (const hourItem of hourSlots) {
          update(hourItem.id, { ...hourItem, active: false });
        }
      }
      const res = await update(payload.id, payload);

      if (!res.error) {
        setAlert(t('hourSlotModel.successEdit'), 'success');
        router.push('/hour-slot-model');
      } else {
        setAlert(res.message, 'danger');
      }
    } else {
      if (hourSlotModel.active) {
        // eslint-disable-next-line no-restricted-syntax
        for (const hourItem of hourSlots) {
          update(hourItem.id, { ...hourItem, active: false });
        }
      }
      const res = await save(payload);

      if (!res.error) {
        setAlert(t('hourSlotModel.successAdd'), 'success');
        router.push('/hour-slot-model');
      } else {
        setAlert(res.message, 'danger');
      }
    }
  }, [update, hourSlots, setAlert, t, router, save]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`hourSlotModel.${hourSlotModel && hourSlotModel.id ? 'edit' : 'add'}`)}
        subLink={{ url: '/hour-slot-model', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section form-page">
        <HourSlotModelsForm
          onSubmit={onSubmit}
          hourSlotModel={hourSlotModel}
        />
      </section>
    </>
  );
};

export default HourSlotModelsFormPage;
