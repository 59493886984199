import React, {
  createContext,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import ContextProvider from 'common/contexts/crud';
import fetchJSON from 'common/utils/fetchJSON';
import AlertsContext from 'common/contexts/alerts';
import { useTranslation } from 'react-i18next';

const BookingContext = createContext();

export const BookingsProvider = ({ children }) => {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);

  const deactivateBooking = useCallback(async (bookingId) => {
    try {
      const res = await fetchJSON({
        url: `bookings/${bookingId}/deactivate`,
        method: 'PUT',
      });

      return res;
    } catch (e) {
      setAlert(e.message, 'danger');
    }
  }, [setAlert]);

  const payAll = useCallback(async (bookingId) => {
    try {
      const res = await fetchJSON({
        url: `bookings/${bookingId}/payAll`,
        method: 'GET',
      });

      setAlert(t('bookings.updatePaymentStatus'), 'success');
      return res;
    } catch (e) {
      setAlert(e.message, 'danger');
    }
  }, [setAlert, t]);

  const value = useMemo(() => ({
    deactivateBooking,
    payAll,
  }), [deactivateBooking, payAll]);

  return (
    <ContextProvider
      value={value}
      url="bookings"
      context={BookingContext}
    >
      {children}
    </ContextProvider>
  );
};

BookingsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useBooking = () => useContext(BookingContext);

export default useBooking;
