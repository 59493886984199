import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const PreparatorAreaContext = createContext();

export const PreparatorAreasProvider = ({ children }) => (
  <ContextProvider url="preparator-areas" context={PreparatorAreaContext}>{children}</ContextProvider>
);

PreparatorAreasProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const usePreparatorArea = () => useContext(PreparatorAreaContext);

export default usePreparatorArea;
