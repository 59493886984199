import React, {
  useState,
  createContext,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const AlertsContext = createContext();

export const AlertsProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);

  const setAlert = useCallback((message, type) => {
    setAlerts((prevAlerts) => [
      ...prevAlerts,
      {
        id: uuid(),
        message,
        type,
      },
    ]);
  }, [setAlerts]);

  const clearAlerts = useCallback(() => {
    setAlerts([]);
  }, []);

  const clearAlert = useCallback((id) => {
    setAlerts(alerts.filter((a) => a.id !== id));
  }, [alerts]);

  const value = useMemo(() => ({
    alerts,
    setAlert,
    clearAlerts,
    clearAlert,
  }), [
    alerts,
    setAlert,
    clearAlerts,
    clearAlert,
  ]);

  return (
    <AlertsContext.Provider value={value}>
      {children}
    </AlertsContext.Provider>
  );
};

AlertsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AlertsContext;
