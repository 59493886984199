import Button from 'common/components/Button/Button';
import FormInput from 'common/components/FormInput/FormInput';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';
import fetchJSON from 'common/utils/fetchJSON';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import SiteSelect from 'sites/components/SiteSelect';
import useSite from 'sites/contexts/sites';

const OptionForm = ({ onSubmit, option }) => {
  const { t, i18n } = useTranslation();
  const { currentSite } = useSite();

  /* const initialValues = useMemo(() => option?.id ? {
    ...option,
    site: option.site.id,
  } : { site: currentSite ? currentSite.id : null }, [currentSite, option]); */

  const handleTranslate = async (values, setFieldValue) => {
    let translated;
    const { name, option_variants } = values;

    const variantsNames = option_variants.map((ov) => ov.name);

    if (name && variantsNames) {
      translated = await fetchJSON({
        url: 'sites/translate',
        method: 'POST',
        payload: { items: { name, ...variantsNames }, site: currentSite.id },
      });

      const translatedVariants = Object.values(Object.keys(translated)
        .filter((key) => key !== 'name')
        .reduce((obj, key) => {
          obj[key] = translated[key];
          return obj;
        }, {}));

      setFieldValue({
        field: 'name',
        value: translated.name,
      });

      setFieldValue({
        field: 'option_variants',
        value: option_variants.map((ov, index) => ({ name: translatedVariants[index] })),
      });
    }
  };

  // eslint-disable-next-line camelcase
  const defaultLang = option ? option.site?.default_language : (
    currentSite ? currentSite.default_language : i18n.language);

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([field], state, utils) => {
          utils.changeValue(state, field.field, () => field.value);
        },
      }}
      initialValues={option?.id ? {
        ...option,
        site: option.site.id,
      } : { site: currentSite ? currentSite.id : null }}
      render={({
        values, handleSubmit, submitting, pristine, form,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormScrollError />
          <Button
            style={{ marginBottom: '2rem' }}
            label="Translate"
            icon="fa-language"
            color="primary"
            confirm
            confirmMessage={t('common.translateConfirm')}
            onClick={() => handleTranslate(values, form.mutators.setFieldValue)}
          />
          <MultilingualInput
            name="name"
            label={t('options.nameOption')}
            icon="heading"
            value={values.name}
            defaultLang={defaultLang}
            required
          />
          <FormInput
            isHorizontal
            type="checkbox"
            name="multiple"
            label={t('options.multiple')}
          />
          {!currentSite && (
          <FormInput
            type="custom"
            name="site"
            label={t('common.site')}
            required
          >
            <SiteSelect />
          </FormInput>
          )}

          <h2 className="subtitle" style={{ marginTop: 30 }}>{t('options.variantList')}</h2>

          <FieldArray name="option_variants">
            {({ fields }) => (
              <div>
                {fields && fields.map((name, index) => (
                  <div key={name} className="row">
                    <MultilingualInput
                      name={`${name}.name`}
                      label={`${index + 1} - ${t('options.nameVariant')}`}
                      icon="heading"
                      value={values.option_variants[index].name}
                      defaultLang={defaultLang}
                      required
                    />

                    <div style={{ paddingTop: 32, maxWidth: 60, textAlign: 'right' }}>
                      <Button
                        onClick={() => fields.remove(index)}
                        icon="fa-trash-alt"
                        color="danger"
                        style={{
                          borderRadius: '100%',
                          maxWidth: 30,
                          minWidth: 30,
                          height: 30,
                          fontSize: 12,
                          padding: 0,
                          top: 35,
                          position: 'relative',
                        }}
                      />
                    </div>

                  </div>
                ))}

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                  <Button
                    onClick={() => fields.push({})}
                    icon="fa-plus"
                    color="primary"
                    label={t('common.addVariant')}
                  />
                </div>

              </div>
            )}
          </FieldArray>
          <br />
          <br />
          <FormInput
            type="submit"
            label={t('common.save')}
            icon="cog"
            disabled={submitting || pristine}
          />
        </form>
      )}
    />
  );
};

OptionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  option: PropTypes.object,
};

OptionForm.defaultProps = {
  option: {},
};

export default OptionForm;
