import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import PageHeader from 'common/components/PageHeader/PageHeader';
import useSite from 'sites/contexts/sites';
import dayjs from 'dayjs';
import fetchJSON from 'common/utils/fetchJSON';
import PageLoader from 'common/components/PageLoader/PageLoader';
import Button from 'common/components/Button/Button';

const Stats = () => {
  const { t } = useTranslation();
  const { item: site } = useSite();
  const [startDate, setStartDate] = useState(dayjs().hour(0).minute(0).second(0));
  const [period, setPeriod] = useState('day'); // possible values : ['day', 'week', 'month']
  const [statsPerChoice, setStatsPerChoice] = useState(null);
  const [nbOrders, setNbOrders] = useState(0);
  const [smsAmount, setSmsAmount] = useState(0);

  const endDate = useMemo(() => startDate.add(1, period), [period, startDate]);

  const { statsFirstHourOfDay, statsFirstDayOfWeek } = useMemo(() => {
    if (site) {
      return site;
    }
    return { statsFirstHourOfDay: null, statsFirstDayOfWeek: null };
  }, [site]);

  useEffect(() => {
    if (!startDate.hour(statsFirstHourOfDay).isAfter(dayjs())) {
      const getStats = async () => {
        const params = {
          startDate: startDate.add(statsFirstHourOfDay, 'hour').format(),
          endDate: endDate.add(statsFirstHourOfDay, 'hour').format(),
        };
        const esc = encodeURIComponent;
        const statsQueryParams = Object.keys(params).map((key) => `${esc(key)}=${esc(params[key])}`).join('&');

        fetchJSON({
          url: `orders_stats?${statsQueryParams}`,
          method: 'GET',
        }).then((results) => {
          setStatsPerChoice(results.stats);
          setNbOrders(results.nbOrders);
        });

        const smsQueryParams = Object.keys(params).map((key) => {
          if (key === 'startDate' || key === 'endDate') {
            return `${esc(key)}=${esc(params[key].split('T')[0])}`;
          }
          return `${esc(key)}=${esc(params[key])}`;
        }).join('&');

        fetchJSON({
          url: `sms-records/stats?${smsQueryParams}`,
          method: 'GET',
        }).then((results) => {
          setSmsAmount(results.smsAmount);
        });
      };

      getStats();
    }
  }, [endDate, startDate, statsFirstHourOfDay, smsAmount]);

  useEffect(() => {
    if (startDate.hour(statsFirstHourOfDay).isAfter(dayjs())) {
      setStartDate(startDate.subtract(1, period));
    }
  }, [startDate, period, statsFirstHourOfDay]);

  const previous = useCallback(() => {
    setStartDate(startDate.subtract(1, period));
  }, [period, startDate]);

  const next = useCallback(() => {
    setStartDate(startDate.add(1, period));
  }, [period, startDate]);

  const formatTime = (nbSeconds) => (
    nbSeconds ? dayjs('1900-01-01T00:00:00').second(nbSeconds).format('HH:mm:ss') : ' -- : -- : -- '
  );

  return (
    <>
      <PageHeader title={t('menu.stats')} />
      <section className="section list-page">
        <div className="date-controller">
          <Button onClick={previous} icon="fa-chevron-left" additionnalClasses="date-nav" />
          <Button
            color={period === 'day' ? 'primary' : undefined}
            onClick={() => setPeriod('day')}
            label={t('date.day')}
          />
          <Button
            color={period === 'week' ? 'primary' : undefined}
            onClick={() => {
              setStartDate(startDate.day(statsFirstDayOfWeek % 7));
              setPeriod('week');
            }}
            label={t('date.week')}
          />
          <Button
            color={period === 'month' ? 'primary' : undefined}
            onClick={() => {
              setStartDate(startDate.date(1));
              setPeriod('month');
            }}
            label={t('date.month')}
          />
          <Button
            onClick={next}
            icon="fa-chevron-right"
            additionnalClasses="date-nav"
            disabled={startDate.add(1, period).hour(statsFirstHourOfDay).isAfter(dayjs())}
          />
        </div>

        <div className="date-title">
          <h1 className="is-size-4 has-text-grey-dark">
            {t('date.fromTo', {
              from: startDate.hour(statsFirstHourOfDay).format('DD/MM/YYYY'),
              to: endDate.hour(statsFirstHourOfDay).format('DD/MM/YYYY'),
            })}
          </h1>
        </div>
        {(site?.isSmsActive || (smsAmount !== 0 && smsAmount != null && smsAmount !== undefined)) && (
        <div className="pb-2">
          <span className="box is-inline">
            <strong className="is-size-5 has-text-black">{t('stats.nbSms', { nbSms: smsAmount })}</strong>
            <br />
          </span>
        </div>
        )}
        <div className="columns">
          <div key="titles" className="column">
            <div style={{ textAlign: 'center', padding: 0, marginBottom: '1.25rem' }}>
              <strong className="is-size-3 has-text-grey">&nbsp;</strong>
              <br />
              <strong className="is-size-5 has-text-grey">{t('stats.nbOrders', { nbOrders })}</strong>
            </div>
            {!!statsPerChoice && !!Object.values(statsPerChoice)[0]
            && Object.entries(Object.values(statsPerChoice)[0]).map(([field, durations]) => (field !== 'nbOrders' && (
              <div key={`field-${field}`} className="box" style={{ height: 140 }}>
                <article className="media">
                  <div className="media-content">
                    <div className="columns">
                      <div className="column is-flex-direction-column is-justify-content-flex-center">
                        <strong className="is-size-5 has-text-grey-dark">{t(`stats.${field}`)}</strong>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            )))}
          </div>
          {statsPerChoice ? Object.entries(statsPerChoice).map(([choice, stats]) => (
            <div key={`choice-${choice.replace(' ', '_')}`} className="column is-3">
              <div style={{ textAlign: 'center', padding: 0, marginBottom: '1.25rem' }}>
                <strong className="is-size-3 has-text-grey">{choice}</strong>
                <br />
                <strong className="is-size-5 has-text-grey">
                  {`${nbOrders ? Math.round(100 * (stats.nbOrders / nbOrders)) : 0}%`}
                </strong>
              </div>
              {!!stats && Object.entries(stats).map(([field, durations]) => (field !== 'nbOrders' && (
                <div key={`field-${field}`} className="box" style={{ height: 140 }}>
                  <article className="media">
                    <div className="media-content">
                      <div className="columns">
                        <div className="column">
                          {!!durations && (
                          <>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              }}
                            >
                              <strong className="is-size-5 has-text-grey">{`${t('stats.min')} :`}</strong>
                              <strong className="is-size-5 has-text-grey">{formatTime(durations.min)}</strong>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              }}
                            >
                              <strong className="is-size-5 has-text-grey">{`${t('stats.max')} :`}</strong>
                              <strong className="is-size-5 has-text-grey">{formatTime(durations.max)}</strong>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              }}
                            >
                              <strong className="is-size-5 has-text-grey">{`${t('stats.moy')} :`}</strong>
                              <strong className="is-size-5 has-text-grey">{formatTime(durations.moy)}</strong>
                            </div>
                          </>
                          )}
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              )
              ))}
            </div>
          ))
            : <PageLoader processing={!site} />}
        </div>
      </section>
    </>
  );
};

export default Stats;
