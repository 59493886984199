import React, {
  useState, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

export const DebounceInput = ({
  value, onChange, debounceTimeout, type, ...rest
}) => {
  const [localValue, setValue] = useState(value);

  const setDebouncedChange = useMemo(() => debounce(onChange, 300), [onChange]);

  const handleInputChange = useCallback((e) => {
    setValue(e.target.value);
    setDebouncedChange(e.target.value);
  }, [setDebouncedChange]);

  return (
    <input
      type={type}
      value={localValue || ''}
      onChange={handleInputChange}
      {...rest}
    />
  );
};

DebounceInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  debounceTimeout: PropTypes.number,
  type: PropTypes.string,
};

DebounceInput.defaultProps = {
  debounceTimeout: 300,
  type: 'text',
  value: '',
};

export default DebounceInput;
