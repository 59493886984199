import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import FormInput from 'common/components/FormInput/FormInput';
import useSite from 'sites/contexts/sites';
import SiteSelect from 'sites/components/SiteSelect';
import UserSelect from 'users/components/UserSelect';

const WaiterAreaForm = ({ onSubmit, waiterArea }) => {
  const { t } = useTranslation();
  const { currentSite } = useSite();
  const role = 'waiter';

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={waiterArea?.id ? {
        ...waiterArea,
        users: waiterArea.users?.map(({ id }) => id),
        site: waiterArea?.site?.id,
      } : { site: currentSite ? currentSite.id : null }}
      render={({
        form,
        handleSubmit,
        submitting,
        pristine,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormInput
            name="name"
            label={t('common.name')}
            icon="heading"
            required
          />
          <FormInput
            name="color"
            type="circle-color"
            required
          />
          <FormInput
            type="custom"
            name="users"
            label={t('common.waiters')}
          >
            <UserSelect
              multiple
              role={role}
            />
          </FormInput>
          {!currentSite && (
          <FormInput
            type="custom"
            name="site"
            label={t('common.site')}
            required
          >
            <SiteSelect />
          </FormInput>
          )}
          <FormInput
            type="submit"
            label={t('common.save')}
            icon="check"
            disabled={submitting || pristine}
          />
        </form>
      )}
    />
  );
};

WaiterAreaForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  waiterArea: PropTypes.object,
};

WaiterAreaForm.defaultProps = {
  waiterArea: {},
};

export default WaiterAreaForm;
