import FormInput from 'common/components/FormInput/FormInput';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SitePresentationForm = ({ values }) => {
  const { t } = useTranslation();

  return (
    <section className="section" style={{ marginTop: 20, marginBottom: 20 }}>
      <div className="row">
        <div style={{ flex: 1 }}>
          <FormInput
            label={t('sites.companyLogo')}
            name="company_logo"
            type="image"
            maxWidth={800}
            maxHeight={800}
          />
        </div>

        <div style={{ flex: 2 }}>
          <FormInput
            name="name"
            label={t('common.name')}
            icon="heading"
            required
          />

        </div>
      </div>

      <div className="row" style={{ marginTop: 32 }}>
        <div style={{ flex: 1 }}>
          <FormInput
            label={t('sites.imagesGalery')}
            name="images"
            type="image"
            multiple
            nbCols={5}
            maxWidth={800}
            maxHeight={1200}
          />
        </div>
        <div style={{ flex: 2, marginTop: -36 }}>
          <MultilingualInput
            name="description"
            label={t('sites.description')}
            type="htmlEditor"
            value={values.description}
            defaultLang={values.default_language}
            required
          />
        </div>
      </div>

      <div className="row">
        <div style={{ marginTop: 16 }}>
          <FormInput
            name="company_description"
            label={t('sites.moreInformation')}
            type="textarea"
          />
        </div>
      </div>

      {/*
          <div className="row">

              <FormInput
                type="number"
                name="min_price"
                label={t('sites.minPrice')}
                icon="money-bill-wave"
                required
              />

              <FormInput
                type="custom"
                name="bookingVat"
                label={t('vat.bookingPercentage')}
                required
              >
                <VatSelect />
              </FormInput>

            </div>

            <h2 className="subtitle">{t('sites.settings')}</h2>
            <div className="row">
              <FormInput
                isHorizontal
                type="checkbox"
                name="allowChangeSeat"
                label={t('sites.allowChangeSeat')}
              />
              <FormInput
                isHorizontal
                type="checkbox"
                name="active"
                label={t('common.active')}
              />
              <FormInput
                isHorizontal
                type="checkbox"
                name="is_open"
                label={t('sites.isOpen')}
              />
            </div>

            <div className="row" style={{ marginTop: 10 }}>
              <FormInput type="date" icon="calendar" name="dateBegin" label={t('sites.dateBegin')} />
              <FormInput type="date" icon="calendar" name="dateEnd" label={t('sites.dateEnd')} />
              <span className="spacer" />
            </div>

            <div className="columns" style={{ marginTop: 10 }}>
              <div className="column">
                {t('sites.day')}
              </div>
              <div className="column">
                {t('sites.openingTime')}
              </div>
              <div className="column">
                {t('sites.closingTime')}
              </div>
              <div className="column">
                {t('sites.isClosed')}
              </div>
            </div>
            {weekdays.map((day) => (
              <div key={day.key} className="columns">
                <div className="column">
                  {day.label}
                </div>
                <>
                  <div className="column">
                    <FormInput
                      type="time"
                      name={`schedule.${day.key}.startHour`}
                      disabled={values.schedule?.[day.key]?.isClosed}
                    />
                  </div>
                  <div className="column">
                    <FormInput
                      type="time"
                      name={`schedule.${day.key}.endHour`}
                      disabled={values.schedule?.[day.key]?.isClosed}
                    />
                  </div>
                </>
                <div className="column">
                  <FormInput
                    name={`schedule.${day.key}.isClosed`}
                    type="checkbox"
                  />
                </div>
              </div>
            ))}
        */}

    </section>
  );
};

SitePresentationForm.propTypes = {
  values: PropTypes.object,

};

SitePresentationForm.defaultProps = {
  values: {},
};

export default SitePresentationForm;
