import React from 'react';
import Select from 'common/components/Select/Select';
import { useTranslation } from 'react-i18next';

const OrderOfflineMethodSelect = (props) => {
  const { t } = useTranslation();

  const options = [
    { label: t('bookings.CASH'), value: 'CASH' },
    { label: t('bookings.CB'), value: 'CB' },
  ];

  /* if (currentSite?.hotel) {
    options.push({ value: 'ROOM', label: t('bookings.ROOM') });
  } */

  return (
    <Select
      options={options}
      isFilter
      placeholder={t('orders.paymentMethod')}
      {...props}
    />
  );
};

export default OrderOfflineMethodSelect;
