import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';
import FormInput from 'common/components/FormInput/FormInput';
import { padStart } from 'lodash';

const SiteStatsForm = ({ site, values }) => {
  const { t, i18n } = useTranslation();

  // eslint-disable-next-line camelcase
  const defaultLang = values ? values.default_language : i18n.language;

  return (
    <>
      <section className="section" style={{ marginTop: 20 }}>
        <h2 className="subtitle">{t('menu.stats')}</h2>

        <div className="row">
          <FormInput
            type="select"
            name="statsFirstDayOfWeek"
            label={t('stats.firstDayOfWeek')}
            options={t('common.daysOfWeek')?.split(',')?.map((dayTxt, index) => (
              { value: index + 1, label: dayTxt }
            ))}
          />
          <span className="spacer" />
        </div>

        <div className="row">
          <FormInput
            type="select"
            name="statsFirstHourOfDay"
            label={t('stats.firstHourOfDay')}
            options={Array(25).fill(null).map((notUsed, i) => (
              { value: i, label: `${String(i).padStart(2, '0')}:00` }
            ))}
          />
          <span className="spacer" />
        </div>

      </section>
    </>
  );
};

SiteStatsForm.propTypes = {
  site: PropTypes.object,
  values: PropTypes.object,
};

SiteStatsForm.defaultProps = {
  site: {},
  values: {},

};

export default SiteStatsForm;
