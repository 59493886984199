import React, {
  useState,
  useRef,
  // useEffect,
} from 'react';
import PropTypes from 'prop-types';
import './Collapsible.scss';

const Collapsible = ({
  children, title, count, isInitiallyOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);
  // const [height, setHeight] = useState(0);

  const ref = useRef(null);

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  /*
  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [isOpen]);
  */

  return (
    <div className="card-content" style={{ padding: 8 }}>
      <div>
        <div
          className="card-header"
          style={
          isOpen ? { paddingBottom: 8, marginBottom: 8, borderBottom: '1px solid #dddddd' } : {}
          }
        >
          <h6 className="font-weight-bold">
            {title}
            {count !== null && (
            <span>
              &nbsp;
              (
              {count}
              )
            </span>
            )}
          </h6>
          <button type="button" onClick={handleFilterOpening}>
            {!isOpen ? (
              <span>
                <i className="fas fa-chevron-down" />
              </span>
            ) : (
              <span>
                <i className="fas fa-chevron-up" />
              </span>
            )}
          </button>
        </div>
      </div>

      <div className="my-collapse" style={{ height: isOpen ? 250 : 0, overflowY: 'auto' }}>
        <div ref={ref}>
          <div>{isOpen && <div className="card-children">{children}</div>}</div>
        </div>
      </div>
    </div>
  );
};

Collapsible.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  count: PropTypes.number,
};

Collapsible.defaultProps = {
  count: null,
};

export default Collapsible;
