import React, {
  useMemo, useCallback, useEffect, useState, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'common/components/Table/Table';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Button from 'common/components/Button/Button';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';

import useProduct from 'products/contexts/products';
import CategorySelect from 'categories/components/CategorySelect';
import useCategory from 'categories/contexts/categories';
import AlertsContext from 'common/contexts/alerts';

const ImportProducts = () => {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const {
    setItems: setProducts,
    items: products,
    isFetching,
    pageCount,
    filters,
    setFilter: setProductsFilter,
    importProducts,
    isImportFetching,
    getRef: getReferenceProducts,
    itemsCount,
  } = useProduct();
  const {
    getRef: getReferenceCategories,
    setItems: setCategories,
  } = useCategory();

  useEffect(() => {
    const fetchAndSetRefCategories = async () => {
      const refCategories = await getReferenceCategories();

      setCategories(refCategories);
    };

    fetchAndSetRefCategories();
  }, [getReferenceCategories, setCategories]);

  const fetchAndSetReferenceProducts = useCallback(async () => {
    const refProducts = await getReferenceProducts(filters);

    setProducts(refProducts);
  }, [filters, getReferenceProducts, setProducts]);

  const handleMinus = useCallback(async (row) => {
    setSelectedProducts((sProducts) => sProducts.filter((p) => p.id !== row.original.id));
  }, []);

  const handlePlus = useCallback(async (row) => {
    const alreadySelected = selectedProducts.find((p) => p.id === row.original.id);

    if (!alreadySelected) {
      const product = products && products.find((p) => p.id === row.original.id);

      setSelectedProducts([...selectedProducts, product]);
    }
  }, [selectedProducts, products]);

  const handleImport = useCallback(async () => {
    try {
      await importProducts(selectedProducts.map(({ id }) => id), selectedCategory);
      setSelectedProducts([]);
      setSelectedCategory(null);
      setAlert(t('products.successImport'), 'success');
    } catch {
      setAlert('oulahla il y à eu un pb', 'danger');
    }
  }, [setAlert, t, importProducts, selectedProducts, selectedCategory]);

  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      filterName: 'name_contains',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { name } } }) => (<TranslatedText value={name} />),
    },
    {
      Header: t('products.category'),
      filterName: 'category.id',
      filterComponent: (props) => <CategorySelect reference isFilter {...props} />,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { category } } }) => (<TranslatedText value={category?.name} />),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handlePlus(row)}
            icon="fa-plus"
            color="primary"
            transparent
          />
        </>
      ),
    },
  ], [t, handlePlus]);

  const rightColumns = useMemo(() => [
    {
      Header: t('common.name'),
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { name } } }) => (<TranslatedText value={name} />),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleMinus(row)}
            icon="fa-minus"
            color="primary"
            transparent
          />
        </>
      ),
    },
  ], [t, handleMinus]);

  return (
    <>
      <PageHeader
        title={t('menu.importProducts')}
        subLink={{ url: '/products', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section list-page">
        <div className="columns">
          <div className="column">
            <Table
              data={products}
              columns={columns}
              fetchData={fetchAndSetReferenceProducts}
              isFetching={isFetching}
              pageCount={pageCount}
              filters={filters}
              setFilter={setProductsFilter}
              itemsCount={itemsCount}
            />
          </div>
          <div className="column">
            <Table
              data={selectedProducts}
              columns={rightColumns}
            />
            <div className="alignLine">
              <CategorySelect
                value={selectedCategory}
                onChange={(val) => setSelectedCategory(val)}
              />
              <Button
                icon="fa-file-import"
                color="primary"
                disabled={isImportFetching || !selectedCategory || selectedProducts.length === 0}
                onClick={handleImport}
                label={t('common.import')}
              />
            </div>
          </div>
        </div>

      </section>
    </>
  );
};

export default ImportProducts;
