import React, {
  useEffect, useCallback,
} from 'react';

import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import PageHeader from 'common/components/PageHeader/PageHeader';
import fetchJSON from 'common/utils/fetchJSON';

import useSite from 'sites/contexts/sites';

import PageLoader from 'common/components/PageLoader/PageLoader';

const StripeRefreshPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { id: siteId } = router.match.params;

  const redirectToStripe = useCallback(
    async () => {
      const accountLink = await fetchJSON({
        url: `sites/${siteId}/stripe`,
        method: 'GET',
      });

      window.open(accountLink.url, '_self');
    },
    [siteId],
  );

  useEffect(() => { redirectToStripe(); }, [redirectToStripe]);

  return (
    <>
      <PageHeader title={t('sites.stripeRefresh')} />
      <PageLoader />
    </>
  );
};

export default StripeRefreshPage;
