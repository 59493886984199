import React, {
  createContext, useContext, useState, useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import io from 'socket.io-client';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

// import useBooking from 'bookings/contexts/bookings';
import useCall from 'calls/contexts/calls';
import AlertsContext from 'common/contexts/alerts';
import useAuth from 'common/contexts/auth';
import useOrder from 'orders/contexts/orders';
import useSite from 'sites/contexts/sites';

import notificationMp3 from 'assets/sounds/juntos.mp3';

const notificationSound = new Audio(notificationMp3);

const apiUrl = process.env.REACT_APP_API_URL;
const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const location = useLocation();
  const [socket, setSocket] = useState();
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);
  const { user } = useAuth();
  const { currentSite } = useSite();
  const { fetchItems: fetchCalls } = useCall();
  const {
    fetchItems: fetchOrders,
    fetchActiveOrders,
    setItems: setOrders,
  } = useOrder();
  // const { fetchItems: fetchBookings } = useBooking();

  const authenticate = useCallback(() => {
    const token = window.localStorage.getItem('jwt');

    if (socket) {
      socket.emit('authenticate', { token, siteId: user.role.type === 'superadmin' ? currentSite?.id : null });
    }
  }, [socket, user, currentSite]);

  const getActivesAndSetOrders = useCallback(async () => {
    const activeOrders = await fetchActiveOrders();

    setOrders(activeOrders);
  }, [fetchActiveOrders, setOrders]);

  // init socket
  useEffect(() => {
    setSocket(io(apiUrl));
  }, []);

  // setAlert not available the first time, so we have to reset events callback
  useEffect(() => {
    if (socket) {
      socket.off('newCall');
      socket.off('callDeleted');
      socket.off('orderConfirmed');
      socket.off('newOrder');
      socket.off('orderStateChanged');
      // socket.off('newBooking');
      // socket.off('updateBooking');
      socket.off('seatChanged');
      // socket.off('deactivateBooking');
      socket.off('paymentError');

      socket.on('paymentError', () => {
        setAlert(t('socket.paymentError'), 'danger');
        // fetchBookings();
      });
      socket.on('newCall', () => {
        setAlert(t('socket.call'), 'success');
        notificationSound.play();
        fetchCalls();
      });
      socket.on('callDeleted', () => {
        fetchCalls();
      });
      socket.on('orderConfirmed', () => {
        setAlert(t('socket.orderConfirmed'), 'success');
      });
      socket.on('newOrder', () => {
        setAlert(t('socket.newOrder'), 'success');
        notificationSound.play();
        // fetchBookings();
        if (location.pathname === '/active-orders') {
          getActivesAndSetOrders();
        } else {
          fetchOrders();
        }
      });
      socket.on('orderStateChanged', () => {
        setAlert(t('socket.orderStateChanged'), 'success');
        if (location.pathname === '/active-orders') {
          getActivesAndSetOrders();
        } else {
          fetchOrders();
        }
      });

      // socket.on('newBooking', () => {
      //   setAlert(t('socket.newBooking'), 'success');
      //   notificationSound.play();
      //   // fetchBookings();
      // });

      // socket.on('deactivateBooking', () => {
      //   setAlert(t('socket.deactivateBooking', 'success'));
      //   // fetchBookings();
      // });

      // socket.on('updateBooking', () => {
      //   // fetchBookings();
      // });
      // socket.on('seatChanged', () => {
      //   // fetchBookings();
      //   setAlert(t('socket.seatChanged'), 'success');
      // });
    }
  }, [setAlert, fetchCalls, fetchOrders, socket, t, location.pathname, getActivesAndSetOrders]); // , fetchBookings

  // if user is logged in, authenticate socket
  useEffect(() => {
    if (user && user.role) {
      authenticate();
    }
  }, [user, authenticate]);

  return (
    <SocketContext.Provider>
      {children}
    </SocketContext.Provider>
  );
};

SocketProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useSocket = () => useContext(SocketContext);

export default useSocket;
