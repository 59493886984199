import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useLang from 'common/hooks/use-lang';
import FormInput from 'common/components/FormInput/FormInput';
import { Tabs, Tab } from 'common/components/Tabs/Tabs';
import French from 'assets/france.svg';
import English from 'assets/united-kingdom.svg';
import Spanish from 'assets/spain.svg';
import Portuguese from 'assets/portugal.svg';
import Italian from 'assets/italy.svg';
import German from 'assets/germany.svg';
import Chinese from 'assets/china.svg';
import Arabic from 'assets/saudi-arabia.svg';
import Russian from 'assets/russia.svg';

const MultilingualInput = ({
  name, label, icon, required, defaultLang, type, value,
}) => {
  const { t } = useTranslation();
  const { langsFront } = useLang(defaultLang);

  const tabs = {
    'fr-FR': { key: 'fr', icon: <img src={French} alt={t('common.fr-FR')} />, label: t('common.fr-FR') },
    'en-GB': { key: 'en', icon: <img src={English} alt={t('common.en-GB')} />, label: t('common.en-GB') },
    'de-DE': { key: 'de', icon: <img src={German} alt={t('common.de-DE')} />, label: t('common.de-DE') },
    'es-ES': { key: 'es', icon: <img src={Spanish} alt={t('common.es-ES')} />, label: t('common.es-ES') },
    'pt-PT': { key: 'pt', icon: <img src={Portuguese} alt={t('common.pt-PT')} />, label: t('common.pt-PT') },
    'it-IT': { key: 'it', icon: <img src={Italian} alt={t('common.it-IT')} />, label: t('common.it-IT') },
    'ru-RU': { key: 'ru', icon: <img src={Russian} alt={t('common.ru-RU')} />, label: t('common.ru-RU') },
    ar: { key: 'ar', icon: <img src={Arabic} alt={t('common.ar')} />, label: t('common.ar') },
    zh: { key: 'zh', icon: <img src={Chinese} alt={t('common.zh')} />, label: t('common.zh') },
  };

  Object.keys(tabs).forEach((lang) => {
    tabs[lang].iconClassName = value?.[lang] ? '' : 'empty';
  });

  return (
    <Tabs withIconsOnly>
      {langsFront.map((lang) => (
        <Tab {...tabs[lang]} required={required && defaultLang === lang}>
          <FormInput
            name={`${name}.${lang}`}
            label={label}
            icon={icon}
            required={required && defaultLang === lang}
            key={lang}
            type={type}
          />
        </Tab>
      ))}
    </Tabs>
  );
};

MultilingualInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.any,
  required: PropTypes.bool,
  type: PropTypes.string,
  defaultLang: PropTypes.string,
};

MultilingualInput.defaultProps = {
  name: '',
  label: '',
  icon: '',
  value: '',
  defaultLang: 'en-GB',
  required: false,
  type: 'text',
};

export default MultilingualInput;
