import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import useBeachEditor from 'sites/pages/BeachEditor/contexts/beachEditor';
import ItemTypes from 'sites/pages/BeachEditor/constants';
import Item from '../Item/Item';

let currentNewIndex = 0;

const Board = ({
  siteId,
  mapId,
  scale,
  previousScale,
  ...rest
}) => {
  const {
    mode, seats, setSeats, selectedSelector,
  } = useBeachEditor();
  const areaRef = useRef(null);

  const [, drop] = useDrop({
    accept: ItemTypes.ITEM,
    drop(item, monitor) {
      const delta = monitor.getDifferenceFromInitialOffset();
      const left = Math.round(item.left + delta.x / (scale * previousScale));
      const top = Math.round(item.top + delta.y / (scale * previousScale));

      moveBox(item.id, left, top);
      return undefined;
    },
  });

  const handleClick = useCallback((event) => {
    if (event?.target?.className
      && ((event.target.className.indexOf('itemElement') !== -1)
      || (event.target.className.indexOf('BoardItem') !== -1))) {
      return;
    }
    if (mode === 'plan' || mode === 'furniture') {
      const containerPosition = areaRef.current.getBoundingClientRect();
      const left = Math.round((event.clientX - containerPosition.x) / (scale * previousScale)) - 30;
      const top = Math.round((event.clientY - containerPosition.y) / (scale * previousScale)) - 30;

      setSeats((seats) => {
        const currentSeat = {
          id: `new_${currentNewIndex++}`,
          x: left,
          y: top,
          active: true,
          site: siteId,
          map: mapId,
          price_area: null,
          furniture: mode === 'furniture' ? selectedSelector.furniture : null,
          scale: 1,
          waiter_area: null,
        };

        seats.push(currentSeat);
        return [...seats];
      });
    }
  }, [setSeats, scale, mode, siteId, previousScale, mapId, selectedSelector]);

  const moveBox = (seatId, left, top) => {
    setSeats((seats) => {
      let currentSeat = seats.find(({ id }) => seatId === id);

      if (!currentSeat) {
        currentSeat = { id: `new_${currentNewIndex++}`, x: left, y: top };
        seats.push(currentSeat);
      } else {
        currentSeat.x = left;
        currentSeat.y = top;
      }
      return [...seats];
    });
  };

  return (
    <div ref={areaRef} onDoubleClick={handleClick}>
      <div
        ref={drop}
        {...rest}
      >
        { seats.map((seat, key) => {
          const {
            x, y, id, title = 'seat',
          } = seat;

          return (
            <Item
              key={key}
              id={id}
              item={seat}
              left={x}
              top={y}
              hideSourceOnDrag
            >
              {title}
            </Item>
          );
        })}
      </div>
    </div>
  );
};

Board.propTypes = {
  scale: PropTypes.number,
  siteId: PropTypes.number.isRequired,
  mapId: PropTypes.number.isRequired,
  previousScale: PropTypes.number,
};
Board.defaultProps = {
  scale: 1,
  previousScale: 1,
};

export default Board;
