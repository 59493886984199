import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import FormInput from 'common/components/FormInput/FormInput';
import useSite from 'sites/contexts/sites';
import SiteSelect from 'sites/components/SiteSelect';

const PriceAreaForm = ({ onSubmit, priceArea, site }) => {
  const { t } = useTranslation();
  const { currentSite } = useSite();

  if (currentSite && priceArea && priceArea.id && priceArea.site?.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={priceArea?.id ? {
        ...priceArea,
        site: priceArea?.site?.id,
      } : { site }}
      render={({
        form,
        handleSubmit,
        submitting,
        pristine,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormInput
            name="name"
            label={t('common.name')}
            icon="heading"
            required
          />
          <FormInput
            name="color"
            type="circle-color"
            required
          />
          {!currentSite && (
          <FormInput
            type="custom"
            name="site"
            label={t('common.site')}
            required
          >
            <SiteSelect />
          </FormInput>
          )}
          <FormInput
            type="submit"
            label={t('common.save')}
            icon="check"
            disabled={submitting || pristine}
          />
        </form>
      )}
    />
  );
};

PriceAreaForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  priceArea: PropTypes.object,
  site: PropTypes.number,
};

PriceAreaForm.defaultProps = {
  priceArea: {},
  site: null,
};

export default PriceAreaForm;
