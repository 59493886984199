/* eslint-disable react/prop-types */
import React, { useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import Table from 'common/components/Table/Table';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Button from 'common/components/Button/Button';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';

import useCategoryItem from 'categoryItems/contexts/categoryItems';
import SiteSelect from 'sites/components/SiteSelect';
import useSite from 'sites/contexts/sites';

const CategoryItemList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { currentSite } = useSite();
  const {
    fetchItems: fetchCategoryItems, items: categoryItems, isFetching, pageCount, remove, filters, setFilter,
    setSort, itemsCount,
  } = useCategoryItem();

  const handleDelete = useCallback(async (row) => {
    await remove(row.original.id);
    await fetchCategoryItems();
  }, [remove, fetchCategoryItems]);

  const handleEdit = useCallback((row) => router.push(`/category-items/edit/${row.original.id}`), [router]);

  useEffect(() => {
    setSort('ObjectNumber:ASC');
  }, [setSort]);
  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      filterName: 'name_contains',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { id, name } } }) => (
        <span className="table-link" onClick={() => router.push(`/category-items/edit/${id}`)}>
          <TranslatedText value={name} />
        </span>
      ),
    },
    {
      Header: t('common.site'),
      filterName: 'site.id',
      hidden: !!currentSite,
      filterComponent: (props) => <SiteSelect isFilter {...props} />,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { site } } }) => (
        <span>
          {site?.name}
        </span>
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDelete(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('categoryItems.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, currentSite, handleEdit, handleDelete, router]);

  return (
    <>
      <PageHeader
        title={t('menu.listCategoryItems')}
        link={{ url: '/category-items/add', icon: 'pizza-slice', label: t('categoryItems.addCategoryItem') }}
      />
      <section className="section list-page">
        <Table
          data={categoryItems}
          columns={columns}
          fetchData={fetchCategoryItems}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          itemsCount={itemsCount}
        />
      </section>
    </>
  );
};

export default CategoryItemList;
