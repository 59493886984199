import React from 'react';
import Select from 'common/components/Select/Select';
import { useTranslation } from 'react-i18next';

const OrderStateSelect = (props) => {
  const { t } = useTranslation();

  const stateOptions = [
    // { label: t('orders.at_arrival'), value: 'at_arrival' },
    { label: t('orders.created'), value: 'created' },
    { label: t('orders.in_preparation'), value: 'in_preparation' },
    { label: t('orders.ready'), value: 'ready' },
    { label: t('orders.done'), value: 'done' },
  ];

  return (
    <Select
      options={stateOptions}
      isFilter
      placeholder={t('orders.state')}
      {...props}
    />
  );
};

export default OrderStateSelect;
