/* eslint-disable max-len */
import React, {
  useEffect, useCallback, useState, useContext,
} from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FormInput from 'common/components/FormInput/FormInput';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';
import CategoryServiceSelect from 'categoryServices/components/CategoryServiceSelect';
import OptionSelect from 'products/components/OptionSelect';
import useSite from 'sites/contexts/sites';
import useFetch from 'common/hooks/use-fetch';
import SiteSelect from 'sites/components/SiteSelect';
import VatSelect from 'vat/components/VatSelect';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import Button from 'common/components/Button/Button';
import fetchJSON from 'common/utils/fetchJSON';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import AlertsContext from 'common/contexts/alerts';

const ServiceForm = ({ onSubmit, service }) => {
  const { t, i18n } = useTranslation();
  const { fetchData } = useFetch();
  const { currentSite } = useSite();
  const [initialValues, setInitialValues] = useState({});
  const [options, setOptions] = useState([]);
  const { setAlert } = useContext(AlertsContext);

  useEffect(() => {
    const fetchOpts = async () => {
      try {
        const fetchedOption = await fetchData(
          'options',
        );

        setOptions(fetchedOption);
        return fetchedOption;
      } catch (e) {
        setAlert(e.message, 'danger');
      }
    };

    fetchOpts();
  }, [setAlert, setOptions, fetchData, currentSite]);

  const computePrices = useCallback((variants) => {
    const prices = [];

    if (variants) {
      variants.forEach((v) => {
        let price = service.variant_prices?.find((s) => s.option_variant?.id === v.id);

        if (!price) {
          price = {
            option_variant: v.id,
            price: null,
          };
        }

        prices.push(price);
      });
    }
    return prices;
  }, [service]);

  useEffect(() => {
    const initWithVariantPrices = async () => {
      const variants = await fetchData(
        `option-variants?option=${service.option.id}${service.option.site === null
          ? ''
          : `&option.site=${service.option.site}`}`,
      );

      setInitialValues({
        ...service,
        site: service.site?.id,
        category_service: service.category_service.id,
        option: service.option.id,
        variant_prices: computePrices(variants),
      });
    };

    if (service?.id) {
      if (service?.option) {
        initWithVariantPrices();
      } else {
        setInitialValues({
          ...service,
          site: service.site?.id,
          category_service: service.category_service?.id,
        });
      }
    } else {
      setInitialValues({
        variant_prices: [],
        active: true,
        option: null,
        site: currentSite?.id,
        vat: currentSite?.vat?.id,
      });
    }
  }, [service, computePrices, currentSite, fetchData]);

  const handleServiceOptionChange = useCallback(async (option, index, setFieldValue) => {
    if (option) {
      setFieldValue({
        field: `options[${index}]`,
        value: {
          option: option.id,
          variant_prices: option.option_variants.map((ov) => ({ option_variant: ov, price: '' })),
        },
      });
    } else {
      setFieldValue({
        field: `options[${index}]`,
        value: {
          option: null,
          variant_prices: [],
        },
      });
    }
  }, []);

  if (currentSite && service && service.id && service.site?.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  const handleTranslate = async (values, setFieldValue) => {
    let translated;
    const { name, description } = values;

    if (name && description) {
      translated = await fetchJSON({
        url: 'sites/translate',
        method: 'POST',
        payload: { items: { name, description }, site: currentSite.id },
      });

      setFieldValue({
        field: 'name',
        value: translated.name,
      });
      setFieldValue({
        field: 'description',
        value: translated.description,
      });
    }
  };

  const handleAddVariant = (async (index, option, variant, setFieldValue) => {
    setFieldValue({
      field: `options[${index}]`,
      value: {
        option: option.option,
        variant_prices: [{ price: 0, option_variant: variant }, ...option.variant_prices],
      },
    });
  });

  const renderAvailableVariants = (values, optionIndex, setFieldValue) => {
    const opt = options.find((o) => o.id === values.options[optionIndex].option?.id);

    if (opt) {
      const variants = opt.option_variants.filter((ov) => values.options[optionIndex].variant_prices.map(((vp) => vp.option_variant.id)).indexOf(ov.id) < 0);

      return variants.map((variant) => (
        <div onClick={() => handleAddVariant(optionIndex, values.options[optionIndex], variant, setFieldValue)}>
          <TranslatedText value={variant.name} />
        </div>
      ));
    }
    return (<></>);
  };

  // eslint-disable-next-line camelcase
  const defaultLang = service ? service.site?.default_language : (
    currentSite ? currentSite.default_language : i18n.language);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{
        setFieldValue: ([field], state, utils) => {
          utils.changeValue(state, field.field, () => field.value);
        },
        ...arrayMutators,
      }}
      render={({
        values, handleSubmit, submitting, pristine, form,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormScrollError />
          <Button
            style={{ marginBottom: '2rem' }}
            label="Translate"
            icon="fa-language"
            color="primary"
            confirm
            confirmMessage={t('common.translateConfirm')}
            onClick={() => handleTranslate(values, form.mutators.setFieldValue)}
          />
          <div className="columns">
            <div className="column">
              <MultilingualInput
                name="name"
                label={t('common.name')}
                icon="heading"
                value={values.name}
                defaultLang={defaultLang}
                required
              />
              {!currentSite && (
                <FormInput
                  type="custom"
                  name="site"
                  label={t('common.site')}
                  required
                >
                  <SiteSelect />
                </FormInput>
              )}
              <FormInput
                type="custom"
                name="category_service"
                label={t('services.category')}
                required
              >
                <CategoryServiceSelect
                  site={values.site}
                />
              </FormInput>
              <MultilingualInput
                name="description"
                label={t('common.description')}
                value={values.description}
                type="htmlEditor"
                defaultLang={defaultLang}
                required
              />
              <FormInput
                name="reference"
                label={t('common.reference')}
                icon="pencil-alt"
              />

            </div>
            <div className="column">
              <FormInput
                name="images"
                type="image"
                multiple
                nbCols={5}
                maxWidth={800}
                maxHeight={1200}
              />
              <div className="is-divider" data-content={t('common.price')} />
              <FormInput
                type="number"
                name="price"
                label={`${t('common.price')} (${currentSite?.currency})`}
                icon="euro-sign"
                required
              />
              <FormInput
                type="custom"
                name="vat"
                label={t('vat.percentage')}
                required
              >
                <VatSelect />
              </FormInput>
              <FieldArray name="options">
                {({ fields: optionFields }) => (
                  <div>
                    {optionFields && optionFields.map((nameOption, optionIndex) => (
                      <>
                        <div key={nameOption} className="row">
                          <FormInput
                            type="custom"
                            name={`options[${optionIndex}].option`}
                            onChange={(_val, value) => handleServiceOptionChange(value.option, optionIndex, form.mutators.setFieldValue)}
                            label={t('common.option')}
                            required
                          >
                            <OptionSelect site={values.site} />
                          </FormInput>
                          <Button
                            onClick={() => optionFields.remove(optionIndex)}
                            icon="fa-trash-alt"
                            color="danger"
                            style={{
                              borderRadius: '100%',
                              maxWidth: 30,
                              minWidth: 30,
                              height: 30,
                              fontSize: 12,
                              padding: 0,
                              top: 35,
                              position: 'relative',
                            }}
                          />
                        </div>
                        <FieldArray name={`options[${optionIndex}].variant_prices`}>
                          {({ fields: variantPriceFields }) => (
                            <div style={{ margin: '15px' }}>
                              {variantPriceFields && variantPriceFields.map((nameVariant, variantPriceIndex) => (
                                <>
                                  <div key={nameVariant} className="row">
                                    <TranslatedText value={variantPriceFields.value[variantPriceIndex].option_variant.name} />
                                    <FormInput
                                      name={`options[${optionIndex}].variant_prices[${variantPriceIndex}].price`}
                                      label={t('common.option_price')}
                                      icon="euro-sign"
                                      required
                                    />
                                    <Button
                                      onClick={() => variantPriceFields.remove(variantPriceIndex)}
                                      icon="fa-trash-alt"
                                      color="danger"
                                      style={{
                                        borderRadius: '100%',
                                        maxWidth: 30,
                                        minWidth: 30,
                                        height: 30,
                                        fontSize: 12,
                                        padding: 0,
                                        top: 35,
                                        position: 'relative',
                                      }}
                                    />
                                  </div>
                                </>
                              ))}
                              {renderAvailableVariants(values, optionIndex, form.mutators.setFieldValue)}
                            </div>
                          )}
                        </FieldArray>
                      </>
                    ))}
                    <Button
                      onClick={() => optionFields.push({})}
                      icon="fa-plus"
                      color="primary"
                      label={t('common.addVariant')}
                    />
                  </div>
                )}
              </FieldArray>
            </div>
          </div>
          <FormInput
            isHorizontal
            name="callWaiter"
            type="checkbox"
            label={t('common.callWaiter')}
          />
          {service && (
          <FormInput
            isHorizontal
            type="checkbox"
            name="active"
            label={t('common.isOnSale')}
          />
          )}
          <FormInput
            type="submit"
            label={t('common.save')}
            icon="pizza-slice"
            disabled={submitting || pristine}
          />
        </form>
      )}
    />
  );
};

ServiceForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  service: PropTypes.object,
};

ServiceForm.defaultProps = {
  service: {},
};

export default ServiceForm;
