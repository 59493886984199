import formatPrice from 'common/utils/formatPrice';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import useSite from 'sites/contexts/sites';
import ItemTypes from 'sites/pages/BeachEditor/constants';
import useBeachEditor from 'sites/pages/BeachEditor/contexts/beachEditor';

const style = {
  position: 'absolute',
  padding: 0,
};

const Item = ({ item }) => {
  const { t } = useTranslation();
  const { currentSite } = useSite();

  const {
    mode, changeSeatName, handleClickonSquare, selectedSelector,
    updateSeat,
  } = useBeachEditor();

  const handleRotate = useCallback((id, actual, angle) => {
    updateSeat(id, { rotate: actual ? actual + angle : angle });
  }, [updateSeat]);

  const handleScale = useCallback((id, actual, amount) => {
    if (actual > 0.1) {
      updateSeat(id, { scale: actual ? actual + amount : amount });
    }
  }, [updateSeat]);

  const [{ isDragging }, drag] = useDrag({
    item: {
      id: item.id, type: ItemTypes.ITEM, left: item.x, top: item.y,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (isDragging) {
    return <div ref={drag} className={`BoardItem ${isDragging ? 'BoardItem-dragging' : ''}`} />;
  }

  const component = item?.furniture?.image ? (
    <>
      {item.name && (
        <span className="seatNumber">{item.name}</span>
      )}
      <img alt={item.name} src={`${process.env.REACT_APP_API_URL}${item.furniture.image.url}`} />
    </>
  ) : null;

  let price = null;

  // eslint-disable-next-line camelcase
  if (item?.furniture?.image && item?.price_area) {
    const priceAreaId = item.price_area.id;

    const p = item.furniture.prices.find((p) => Number.isInteger(p.price_area)
      ? p.price_area === priceAreaId : p.price_area.id === priceAreaId);

    if (p) {
      price = (
        <span className="seatPrice">
          {`${formatPrice(p.price, currentSite?.currency)}`}
        </span>
      );
    }
  }

  let color = 'white';

  switch (mode) {
    case 'waiter':
      if (item.waiter_area) {
        color = item.waiter_area.color;
      }
      break;
    default:
      if (item.price_area) {
        color = item.price_area.color;
      }
  }

  return (
    <div
      ref={mode === 'plan' ? drag : null}
      style={{
        ...style,
        width: (item?.furniture?.width || 100) * item.scale,
        height: (item?.furniture?.height || 100) * item.scale,
        left: item.x,
        top: item.y,
        transform: item.rotate ? `rotate(${item.rotate}deg)` : null,
        backgroundColor: color,
        borderWidth: 4,
        borderStyle: 'solid',
        borderColor: color,
        cursor: mode === 'plan' ? (selectedSelector?.id === 'delete' ? '' : 'grab') : 'pointer',
      }}
      onClick={(e) => { e.stopPropagation(); handleClickonSquare(item.id); }}
      className={`BoardItem ${isDragging ? 'BoardItem-dragging' : ''}`}
    >
      { mode !== 'plan' ? (
        <>
          { component }
          {price}
        </>
      ) : (
        <>
          <input
            type="text"
            value={item.name}
            className="itemElement input seatName"
            onChange={(e) => changeSeatName(item, e.target.value)}
            placeholder={t('common.seatName')}
          />
          <button
            className="itemElement beach-editor-menu--button"
            style={{
              position: 'absolute', top: '-8px', right: '-8px', border: 0,
            }}
            onClick={(e) => { e.stopPropagation(); handleRotate(item.id, item.rotate, 5); }}
          >
            <i className="itemElement fas fa-redo" />
          </button>
          <button
            className="itemElement beach-editor-menu--button"
            style={{
              position: 'absolute', top: '-8px', left: '-8px', border: 0,
            }}
            onClick={(e) => { e.stopPropagation(); handleRotate(item.id, item.rotate, -5); }}
          >
            <i className="itemElement fas fa-undo" />
          </button>
          <button
            className="itemElement beach-editor-menu--button"
            style={{
              position: 'absolute', bottom: '-8px', right: '-8px', border: 0,
            }}
            onClick={(e) => { e.stopPropagation(); handleScale(item.id, item.scale, 0.1); }}
          >
            <i className="itemElement fas fa-plus" />
          </button>
          <button
            className="itemElement beach-editor-menu--button"
            style={{
              position: 'absolute', bottom: '-8px', left: '-8px', border: 0,
            }}
            onClick={(e) => { e.stopPropagation(); handleScale(item.id, item.scale, -0.1); }}
          >
            <i className="itemElement fas fa-minus" />
          </button>
        </>
      )}
    </div>
  );
};

Item.propTypes = {
  item: PropTypes.object,
};

Item.defaultProps = {
  item: null,
};

export default Item;
