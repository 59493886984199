import React, {
  useState, useCallback, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useOrders from 'orders/contexts/orders';
import AlertsContext from 'common/contexts/alerts';
import OrderItem from 'orders/components/OrderItem/OrderItem';
import OrderItemService from 'orders/components/OrderItemService/OrderItemService';
import Button from 'common/components/Button/Button';
import RelativeDate from 'common/components/RelativeDate/RelativeDate';
import fetchJSON from 'common/utils/fetchJSON';
import zeroPad from 'common/utils/zeroPad';
import formatPrice from 'common/utils/formatPrice';
import useSite from 'sites/contexts/sites';
import OrderStateSelect from '../OrderStateSelect/OrderStateSelect';

const OrderDetails = ({ order, handleDelete, handleStateChange }) => {
  const { t } = useTranslation();
  const { currentSite } = useSite();

  const { save: saveOrder, fetchItems: fetchOrders } = useOrders();
  const { setAlert } = useContext(AlertsContext);
  const [orderState, setOrderState] = useState(order.state);
  const [user, setUser] = useState({});

  const handleChange = (value) => {
    setOrderState(value);
    handleStateChange(order.id, value);
  };

  // Get User
  useEffect(() => {
    const getUser = async (uid) => {
      const user = await fetchJSON({
        url: `users/${uid}`,
        method: 'GET',
      });

      setUser(user);
    };

    if (order.user) {
      getUser(order.user);
    }
  });

  const reOrder = useCallback(async () => {
    const payload = { ...order };

    delete payload.id;
    delete payload.payment.id;
    const newPayload = {
      ...payload,
      // booking: order.booking.id,
      state: 'created',
      site: (typeof order.site === 'object') ? order.site.id : order.site,
      payment: {
        status: 'PENDING',
        amount: payload.payment.amount,
        offline_method: 'CASH',
        site: order.site.id,
        transactionFees: payload.payment.transactionFees,
        user: payload.payment.user,
      },

    };

    try {
      await saveOrder(newPayload);
      setAlert(t('common.success'), 'success');
      fetchOrders();
    } catch (e) {
      setAlert(t('common.error'), 'danger');
    }
  }, [order, saveOrder, fetchOrders, setAlert, t]);

  return (
    <div className="order-details">
      <div className="order-details--header">
        <div className="order-details--meta">
          <h2 className="order-details--id">
            {`${t('orders.orderNumber')}${zeroPad(order.displayedId, 3)}`}
          </h2>
          <div><b>{order.deliveryMethod === 'deliver' ? t('orders.delivery') : t('orders.takeAway')}</b></div>
          <div>{`${t('common.code')} : ${order.code}`}</div>
          <div>{`${t('common.customer')} : ${user?.username || order.userInfo?.name}`}</div>
          <div>{`Phone : +${order.userInfo?.phone.replace(':', ' ')}`}</div>
          <div>{`Email : ${order.userInfo?.email}`}</div>

        </div>
        {/* { order.booking?.roomID && (
            <span>
              {t('orders.room', { roomId: order.booking.roomID })}
            </span>
            )} */}
        <div className="order-details--state" style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
          <OrderStateSelect
            value={orderState}
            onChange={handleChange}
            clearable={false}
          />
          <div className="order-details--date">
            <i className="far fa-clock mr" />
            <RelativeDate value={order.created_at} />
          </div>
        </div>
      </div>
      <div className="order-details--items">
        {order.order_items.filter((i) => i.product !== null).map((i) => <OrderItem key={i.id} item={i} />)}
        {order.order_items.filter((i) => i.service !== null).map((i) => <OrderItemService key={i.id} item={i} />)}
      </div>
      <div className="order-details--total">
        <span>{`${t('orders.total')} :`}</span>
        <span className="order-details--total-price">{`${formatPrice(order.price, currentSite?.currency)}`}</span>
      </div>

      {currentSite?.pleaseMyCar && (
      <div className="order-details--total">
        <span>{`${t('orders.tip')} :`}</span>
        <span className="order-details--total-price">{`${formatPrice(order?.tip || 0, currentSite?.currency)}`}</span>
      </div>
      )}

      <div className="row">
        <div>
          { /* Waiter Area */}
          <div className="order-details--comments row">
            <b>
              {`${t('menu.waiterAreas')}:`}
            </b>
            <span>
              {order.waiterAreas?.name || '-'}
            </span>
          </div>
          { /* Preparator Area */}
          <div className="order-details--comments row">
            <b>
              {`${t('menu.preparatorAreas')}:`}
            </b>
            <span>
              {order.preparatorAreas?.name || '-'}
            </span>
          </div>
          { /* Preparator Name */}
          <div className="order-details--comments row">
            <b>
              {`${t('common.preparator')}:`}
            </b>
            <span>
              {order.barman?.name || '-'}
            </span>
          </div>
          { /* Waiter Name */}
          <div className="order-details--comments row">
            <b>
              {`${t('common.waiter')}:`}
            </b>
            <span>
              {order.waiter?.name || '-'}
            </span>
          </div>
        </div>

        <div>
          <b>
            {t('orders.localization')}
            {' '}
            :
          </b>
          {order.localization ? (
            <p>
              {Object.keys(order.localization).map((key, index) => (
                <span key={`loc-field-${index}`}>
                  {index > 0 && ', '}
                  {(!order.localization[key].required || order.localization[key].value === '') && key}
                  {order.localization[key].value ? `${order.localization[key].value}` : ''}
                </span>
              ))}
            </p>
          ) : (
            <p>-</p>
          )}

        </div>

        <div className="order-details--comments">
          <b>
            {`${t('orders.comments')}:`}
          </b>
          <p>{order.comments || '-'}</p>
        </div>

      </div>

      <div className="order-details--footer">
        <div>
          <Button
            style={{ marginRight: 20 }}
            onClick={reOrder}
            icon="fa-plus"
            label={t('orders.reorder')}
            color="primary"
            outlined
            confirm
          />
          <Button
            onClick={() => handleDelete(order.id)}
            icon="fa-trash-alt"
            label={t('common.delete')}
            color="danger"
            outlined
            confirm
          />
        </div>
      </div>
    </div>
  );
};

OrderDetails.propTypes = {
  order: PropTypes.shape.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleStateChange: PropTypes.func.isRequired,
};

export default OrderDetails;
