import React from 'react';
import RemoteSelect from 'common/components/RemoteSelect/RemoteSelect';

const processOptions = (options) => options.map(({ id, name }) => ({ value: id, label: name }));

const PreparatorAreaSelect = (props) => (
  <RemoteSelect
    filters={['site']}
    url="preparator-areas"
    processOptions={processOptions}
    {...props}
  />
);

export default PreparatorAreaSelect;
