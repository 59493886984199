/* eslint-disable max-len */
import React, {
  useEffect, useCallback, useState, useContext,
} from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FormInput from 'common/components/FormInput/FormInput';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';
import CategorySelect from 'categories/components/CategorySelect';
import OptionSelect from 'products/components/OptionSelect';
import useFetch from 'common/hooks/use-fetch';
import useSite from 'sites/contexts/sites';
import SiteSelect from 'sites/components/SiteSelect';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import VatSelect from 'vat/components/VatSelect';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import fetchJSON from 'common/utils/fetchJSON';
import { FieldArray } from 'react-final-form-arrays';
import Button from 'common/components/Button/Button';
import arrayMutators from 'final-form-arrays';
import AlertsContext from 'common/contexts/alerts';

const ProductForm = ({ onSubmit, product }) => {
  const { t, i18n } = useTranslation();
  const { fetchData } = useFetch();
  const { currentSite } = useSite();
  const [initialValues, setInitialValues] = useState({});
  const [options, setOptions] = useState([]);
  const { setAlert } = useContext(AlertsContext);

  useEffect(() => {
    const fetchOpts = async () => {
      try {
        const fetchedOption = await fetchData(
          'options',
        );

        setOptions(fetchedOption);
        return fetchedOption;
      } catch (e) {
        setAlert(e.message, 'danger');
      }
    };

    fetchOpts();
  }, [setAlert, setOptions, fetchData, currentSite]);

  useEffect(() => {
    const computePrices = (variants, variant_prices) => {
      const prices = [];

      if (variants) {
        variants.forEach((v) => {
          let price = variant_prices?.find((p) => p.option_variant?.id === v.id);

          if (!price) {
            price = {
              option_variant: v.id,
              price: null,
            };
          }

          prices.push(price);
        });
      }

      return prices;
    };

    const initWithVariantPrices = async () => {
      const formValues = {
        ...product,
        site: product.site?.id,
        category: product.category.id,
        vat: product.vat?.id,
      };

      const options = product.options.map(async (opt) => {
        const variants = await fetchData(
          `option-variants?option=${opt.option.id}${opt.option.site === null
            ? ''
            : `&option.site=${opt.option.site}`}`,
        );

        return {
          id: opt.id,
          option: opt.option.id,
          variant_prices: computePrices(variants, opt.variant_prices),
        };
      });

      formValues.options = await Promise.all(options);
      setInitialValues(formValues);
    };

    if (product?.id) {
      if (product?.options?.length) {
        initWithVariantPrices();
      } else {
        setInitialValues({
          ...product,
          site: product.site?.id,
          category: product.category?.id,
          vat: product.vat?.id,
        });
      }
    } else {
      setInitialValues({
        variant_prices: [],
        active: true,
        option: null,
        site: currentSite?.id,
        vat: currentSite?.vat?.id,
        description: {},
      });
    }
  }, [product, fetchData, currentSite]);

  const handleProductOptionChange = useCallback(async (option, index, setFieldValue) => {
    if (option) {
      setFieldValue({
        field: `options[${index}]`,
        value: {
          option: option.id,
          variant_prices: option.option_variants.map((ov) => ({ option_variant: ov, price: '' })),
        },
      });
    } else {
      setFieldValue({
        field: `options[${index}]`,
        value: {
          option: null,
          variant_prices: [],
        },
      });
    }
  }, []);

  if (currentSite && product && product.id && product.site?.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  // eslint-disable-next-line camelcase
  const defaultLang = product ? product.site?.default_language : (
    currentSite ? currentSite.default_language : i18n.language);

  const handleTranslate = async (values, setFieldValue) => {
    let translated;
    const { name, description } = values;

    if (name || description) {
      translated = await fetchJSON({
        url: 'sites/translate',
        method: 'POST',
        payload: { items: { name, description }, site: currentSite.id },
      });

      setFieldValue({
        field: 'name',
        value: translated.name,
      });
      setFieldValue({
        field: 'description',
        value: translated.description,
      });
    }
  };

  const handleAddVariant = (async (index, option, variant, setFieldValue) => {
    setFieldValue({
      field: `options[${index}]`,
      value: {
        option: option.option,
        variant_prices: [{ price: 0, option_variant: variant }, ...option.variant_prices],
      },
    });
  });

  const renderAvailableVariants = (values, optionIndex, setFieldValue) => {
    const opt = options.find((o) => o.id === values.options[optionIndex].option?.id);

    if (opt) {
      const variants = opt.option_variants
        .filter((ov) => values.options[optionIndex].variant_prices
          .map(((vp) => vp.option_variant.id)).indexOf(ov.id) < 0);

      return variants.map((variant) => (
        <div onClick={() => handleAddVariant(optionIndex, values.options[optionIndex], variant, setFieldValue)}>
          <TranslatedText value={variant.name} />
        </div>
      ));
    }
    return (<></>);
  };

  return (
    <>
      {initialValues.reference_product && (
        <p style={{ marginBottom: 10, fontStyle: 'italic' }}>
          {`${t('products.initialReference')} : `}
          <TranslatedText value={initialValues.reference_product.name} />
        </p>
      )}
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{
          setFieldValue: ([field], state, utils) => {
            utils.changeValue(state, field.field, () => field.value);
          },
          ...arrayMutators,
        }}
        render={({
          values, handleSubmit, submitting, pristine, form,
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <FormScrollError />
            <Button
              style={{ marginBottom: '2rem' }}
              label="Translate"
              icon="fa-language"
              color="primary"
              confirm
              confirmMessage={t('common.translateConfirm')}
              onClick={() => handleTranslate(values, form.mutators.setFieldValue)}
            />
            <div className="columns">
              <div className="column is-two-thirds">
                <MultilingualInput
                  name="name"
                  label={t('common.name')}
                  icon="heading"
                  value={values.name}
                  defaultLang={defaultLang}
                  required
                />
                {!currentSite && (
                  <FormInput
                    type="custom"
                    name="site"
                    label={t('common.site')}
                    required
                  >
                    <SiteSelect />
                  </FormInput>
                )}
                <FormInput
                  type="custom"
                  name="category"
                  label={t('products.category')}
                  required
                >
                  <CategorySelect
                    site_null={0}
                    site={values.site}
                  />
                </FormInput>
                <MultilingualInput
                  name="description"
                  label={t('common.description')}
                  value={values.description}
                  defaultLang={defaultLang}
                  type="htmlEditor"
                />
                <FormInput
                  name="reference"
                  label={t('common.reference')}
                  icon="pencil-alt"
                />
                <FormInput
                  isHorizontal
                  name="callWaiter"
                  type="checkbox"
                  label={t('common.callWaiter')}
                />
                {product && (
                  <FormInput
                    isHorizontal
                    type="checkbox"
                    name="active"
                    label={t('common.isOnSale')}
                  />
                )}
                <FormInput
                  isHorizontal
                  type="checkbox"
                  name="complementary"
                  label={t('products.isComplementary')}
                />
              </div>
              <div className="column">
                <FormInput
                  name="images"
                  type="image"
                  multiple
                  nbCols={5}
                  maxWidth={800}
                  maxHeight={1200}
                />
                <div className="is-divider" data-content={t('common.price')} />
                <FormInput
                  type="number"
                  name="price"
                  label={`${t('common.price')} (${currentSite?.currency})`}
                  icon="money-bill"
                  required
                />
                <FormInput
                  type="custom"
                  name="vat"
                  label={t('vat.percentage')}
                  required
                >
                  <VatSelect />
                </FormInput>

                {currentSite?.all_inclusive && (
                  <FormInput
                    name="all_inclusive"
                    type="checkbox"
                    label={t('allInclusive.productAllInclusive')}
                  />
                )}

                <FieldArray name="options">
                  {({ fields: optionFields }) => (
                    <div>
                      {optionFields && optionFields.map((nameOption, optionIndex) => (
                        <React.Fragment key={nameOption}>
                          <div className="row">
                            <FormInput
                              type="custom"
                              name={`options[${optionIndex}].option`}
                              onChange={(_val, value) => {
                                handleProductOptionChange(value.option, optionIndex, form.mutators.setFieldValue);
                              }}
                              label={t('common.option')}
                              required
                            >
                              <OptionSelect site={values.site} />
                            </FormInput>
                            <Button
                              onClick={() => optionFields.remove(optionIndex)}
                              icon="fa-trash-alt"
                              color="danger"
                              style={{
                                borderRadius: '100%',
                                maxWidth: 30,
                                minWidth: 30,
                                height: 30,
                                fontSize: 12,
                                padding: 0,
                                top: 35,
                                position: 'relative',
                              }}
                            />
                          </div>
                          <FieldArray name={`options[${optionIndex}].variant_prices`}>
                            {({ fields: variantPriceFields }) => (
                              <div style={{ margin: '15px' }}>
                                {variantPriceFields && variantPriceFields.map((nameVariant, variantPriceIndex) => (
                                  <React.Fragment key={nameVariant}>
                                    <div className="row">
                                      <TranslatedText value={variantPriceFields.value[variantPriceIndex].option_variant.name} />
                                      <FormInput
                                        name={`options[${optionIndex}].variant_prices[${variantPriceIndex}].price`}
                                        label={t('common.option_price')}
                                        icon="money-bill"
                                        required
                                      />
                                      <Button
                                        onClick={() => variantPriceFields.remove(variantPriceIndex)}
                                        icon="fa-trash-alt"
                                        color="danger"
                                        style={{
                                          borderRadius: '100%',
                                          maxWidth: 30,
                                          minWidth: 30,
                                          height: 30,
                                          fontSize: 12,
                                          padding: 0,
                                          top: 35,
                                          position: 'relative',
                                        }}
                                      />
                                    </div>
                                  </React.Fragment>
                                ))}
                                {renderAvailableVariants(values, optionIndex, form.mutators.setFieldValue)}
                              </div>
                            )}
                          </FieldArray>
                        </React.Fragment>
                      ))}
                      <Button
                        onClick={() => optionFields.push({})}
                        icon="fa-plus"
                        color="primary"
                        label={t('common.addVariant')}
                      />
                    </div>
                  )}
                </FieldArray>
              </div>
            </div>
            <FormInput
              type="submit"
              label={t('common.save')}
              icon="pizza-slice"
              disabled={submitting || pristine}
            />
          </form>
        )}
      />
    </>
  );
};

ProductForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  product: PropTypes.object,
};

ProductForm.defaultProps = {
  product: {},
};

export default ProductForm;
