import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const TemplateContext = createContext();

export const TemplateProvider = ({ children }) => (
  <ContextProvider url="templates" context={TemplateContext}>{children}</ContextProvider>
);

TemplateProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useTemplate = () => useContext(TemplateContext);

export default useTemplate;
