import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const LocalizationModelContext = createContext();

export const LocalizationModelsProvider = ({ children }) => (
  <ContextProvider url="localization-models" context={LocalizationModelContext}>{children}</ContextProvider>
);

LocalizationModelsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useLocalizationModel = () => useContext(LocalizationModelContext);

export default useLocalizationModel;
