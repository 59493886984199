/* eslint-disable react/prop-types */
import React, {
  // eslint-disable-next-line comma-dangle
  useCallback, useEffect, useMemo, useState
} from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Button from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Table from 'common/components/Table/Table';
import { createUploadUrl } from 'common/utils/createUrl';
import getUserDisplayName from 'common/utils/getUserDisplayName';
import OrderDetails from 'orders/components/OrderDetails/OrderDetails';
import useOrder from 'orders/contexts/orders';
import useSite from 'sites/contexts/sites';
import SiteSelect from 'sites/components/SiteSelect';
import OrderOfflineMethodSelect from 'orders/components/OrderOfflineMethodSelect/OrderOfflineMethodSelect';
import zeroPad from 'common/utils/zeroPad';
import formatPrice from 'common/utils/formatPrice';

const ArchivedOrdersList = () => {
  const { t, i18n: { language } } = useTranslation();
  const { currentSite } = useSite();
  const {
    fetchItem: fetchOrder,
    fetchItems: fetchOrders,
    items: orders,
    isFetching,
    pageCount, remove,
    filters: orderFilters,
    setFilters,
    setFilter: setOrderFilter,
    setSort,
    updateOrderState,
    itemsCount,
  } = useOrder();

  const [modalActive, setModalActive] = useState(false);
  const [detailedOrder, setDetailedOrder] = useState(null);

  const openModal = useCallback(async (row) => {
    const order = await fetchOrder(row.original.id);

    setDetailedOrder(order);
    setModalActive(true);
  }, [fetchOrder]);

  const closeModal = () => {
    setModalActive(false);
  };

  const handleStateChange = async (id, value) => {
    const order = orders && orders.find((p) => p.id === id);

    if (order) {
      await updateOrderState(order.id, { state: value });
      await fetchOrders();
    }
  };

  const handleDelete = useCallback(async (id) => {
    await remove(id);
    await fetchOrders();
    closeModal();
  }, [fetchOrders, remove]);

  const columns = useMemo(() => [
    {
      Header: t('orders.id'),
      filterName: 'id_contains',
      Cell: ({ row: { original: { id } } }) => (
        <span>
          {id}
        </span>
      ),
    },
    {
      Header: '#',
      Cell: ({ row: { original: { displayedId } } }) => (
        <span>
          {zeroPad(displayedId, 3)}
        </span>
      ),
    },
    {
      Header: t('orders.user'),
      filterName: 'user.username_contains',
      Cell: ({ row: { original: { user, userInfo } } }) => (
        <span>
          {user?.name || userInfo?.name || (user ? getUserDisplayName(user) : t('users.unregistered'))}
        </span>
      ),
    },
    {
      Header: t('orders.created'),
      // filterName: 'created_at',
      // filterComponent: (props) => <DatePicker isFilter {...props} />,
      customFormat: (value) => (moment(value).utc().startOf('day').format()),
      Cell: ({ row: { original: { created_at: createdAt } } }) => (
        <>
          {moment(createdAt).locale(language).format('LL hh:mm')}
        </>
      ),
    },
    {
      Header: t('menu.preparatorAreas'),
      filterName: 'preparatorAreas_contains',
      Cell: ({ row: { original: { preparatorAreas } } }) => (
        preparatorAreas ? (
          <span>{preparatorAreas.name}</span>
        ) : (
          <></>
        )
      ),
    },
    {
      Header: t('common.code'),
      filterName: 'code_contains',
      Cell: ({ row: { original: { code } } }) => (
        <>
          {/* <span className={`order-state-tag ${state === 'done' ? 'is-done' : ''}`}>
            <i className={`is-size-7 fas fa-${state === 'done' ? 'check' : 'hourglass'}`} />
            <span>
              {t(`orders.${state}`)}
            </span>
          </span>
      &nbsp; */}
          <b>
            {code}
          </b>
        </>
      ),
    },
    {
      Header: t('orders.paymentMethod'),
      filterName: 'payment.offline_method_contains',
      filterComponent: (props) => <OrderOfflineMethodSelect isFilter {...props} />,
      Cell: ({ row: { original: { payment, price, tip } } }) => {
        const pay = payment || {};
        const { offline_method: offlineMethod } = pay;

        let icon = null;

        let label = null;

        switch (offlineMethod) {
          case 'CASH': icon = 'money-bill'; label = t('orders.cash'); break;
          case 'CARD': icon = 'credit-card'; label = t('orders.creditCard'); break;
          default: break;
        }

        return icon ? (
          <span>
            <i className={`has-text-primary fas fa-${icon}`} />
            &nbsp;
            <b>
              {`${formatPrice(price, currentSite?.currency)}`}
            </b>
            &nbsp;
            (
            {label}
            )
            {currentSite?.pleaseMyCar && (
              <>
                <br />
                <span>
                  {`${t('orders.tip')} : ${`${formatPrice(tip || 0, currentSite?.currency)}`}`}
                </span>
              </>
            )}
          </span>

        ) : (
          <></>
        );
      },
    },
    /* {
      Header: t('orders.seatNumber'),
      filterName: 'seat.name',
      Cell: ({ row: { original: { seat, booking } } }) => {
        const s = seat || {};
        const b = booking || {};

        return (
          <>
            <span>
              {t('orders.seat', { seatNumber: s.name })}
            </span>
            <br />
            { b.roomID && (
            <span>
              {t('orders.room', { roomId: b.roomID })}
            </span>
            )}
          </>
        );
      },
    }, */
    {
      Header: t('invoices.invoiceNumber'),
      filterName: 'payment.invoiceNumber_contains',
      Cell: ({ row: { original: { payment } } }) => {
        const pay = payment || {};
        const { invoiceNumber } = pay;

        return (
          <span>
            {pay?.invoicePath
              ? (
                <Button
                  onClick={() => window.open(createUploadUrl(pay.invoicePath))}
                  label={invoiceNumber}
                  color="primary"
                />
              ) : (
                invoiceNumber
              )}
          </span>
        );
      },
    },

    /* {
      Header: t('menu.waiterAreas'),
      filterName: 'waiterAreas.name',
      Cell: ({ row: { original: { waiterAreas } } }) => (
        waiterAreas && (
          <span>{waiterAreas.name}</span>
        )
      ),
    }, */
    /* {
      Header: t('orders.state'),
      filterName: 'state',
      filterComponent: OrderStateSelect,
      Cell: ({ row: { original: { state } } }) => (
        <span className={`order-state-tag ${state === 'done' ? 'is-done' : ''}`}>
          <i className={`is-size-7 fas fa-${state === 'done' ? 'check' : 'hourglass'}`} />
          <span>
            {t(`orders.${state}`)}
          </span>
        </span>
      ),
    }, */
    {
      Header: t('common.site'),
      hidden: !!currentSite,
      filterName: 'site.id',
      filterComponent: (props) => <SiteSelect isFilter {...props} />,
      Cell: ({ row: { original: { site } } }) => (
        <span>
          {site?.name}
        </span>
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => openModal(row)}
            icon="fa-eye"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDelete(row.original.id)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('orders.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, currentSite, openModal, handleDelete, language]);

  useEffect(() => {
    setFilters({ state_eq: 'done' });
    setSort('created_at:DESC');
  }, [setFilters, setSort]);

  return (
    <>
      <PageHeader title={t('menu.archivedListOrders')} />
      <section className="section list-page has-overflow-x-table">
        <Table
          data={orders}
          columns={columns}
          fetchData={fetchOrders}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={orderFilters}
          setFilter={setOrderFilter}
          itemsCount={itemsCount}
        />
      </section>
      {modalActive && (
        <Modal closeModal={closeModal}>
          <OrderDetails handleDelete={handleDelete} handleStateChange={handleStateChange} order={detailedOrder} />
        </Modal>
      )}
    </>
  );
};

export default ArchivedOrdersList;
