import React, {
  useEffect, useContext, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import usePreparatorArea from 'preparatorAreas/contexts/preparatorAreas';

import PageHeader from 'common/components/PageHeader/PageHeader';
import PageLoader from 'common/components/PageLoader/PageLoader';
import PreparatorAreaForm from './PreparatorAreaForm';

const PreparatorAreaFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, fetchItem, item: preparatorArea, isFetching,
  } = usePreparatorArea();

  const { id: preparatorAreaId } = router.match.params;

  useEffect(() => { fetchItem(preparatorAreaId); }, [fetchItem, preparatorAreaId]);

  const onSubmit = useCallback(async (preparatorArea) => {
    const payload = { ...preparatorArea };

    if (preparatorArea.id) {
      const res = await update(payload.id, payload);

      if (!res.error) {
        setAlert(t('preparatorAreas.successEdit'), 'success');
        router.push('/preparator-areas');
      } else {
        setAlert(res.message, 'danger');
      }
    } else {
      const res = await save(payload);

      if (!res.error) {
        setAlert(t('preparatorAreas.successAdd'), 'success');
        router.push('/preparator-areas');
      } else {
        setAlert(res.message, 'danger');
      }
    }
  }, [update, setAlert, t, router, save]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`preparatorAreas.${preparatorArea && preparatorArea.id ? 'edit' : 'add'}PreparatorArea`)}
        subLink={{ url: '/preparator-areas', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section form-page">
        <PreparatorAreaForm
          onSubmit={onSubmit}
          preparatorArea={preparatorArea}
        />
      </section>
    </>
  );
};

export default PreparatorAreaFormPage;
