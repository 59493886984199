import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import FormInput from 'common/components/FormInput/FormInput';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';
import useSite from 'sites/contexts/sites';
import SiteSelect from 'sites/components/SiteSelect';

import CategoryItemSelect from 'categoryItems/components/CategoryItemSelect';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import fetchJSON from 'common/utils/fetchJSON';
import Button from 'common/components/Button/Button';

const AroundMeForm = ({ onCategoryItemsLoaded, onSubmit, location }) => {
  const { t, i18n } = useTranslation();
  const { currentSite } = useSite();

  if (currentSite && location && location.id && location.site?.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  const handleTranslate = async (values, setFieldValue) => {
    let translated;
    const { name, description } = values;

    if (name && description) {
      translated = await fetchJSON({
        url: 'sites/translate',
        method: 'POST',
        payload: { items: { name, description }, site: currentSite.id },
      });

      setFieldValue({
        field: 'name',
        value: translated.name,
      });
      setFieldValue({
        field: 'description',
        value: translated.description,
      });
    }
  };

  // eslint-disable-next-line camelcase
  const defaultLang = location ? location.site?.default_language : (
    currentSite ? currentSite.default_language : i18n.language);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={location?.id ? {
        ...location,
        site: location?.site?.id,
        category_item: location.category_item.id,
      } : { site: currentSite ? currentSite.id : null }}
      mutators={{
        // eslint-disable-next-line no-unused-vars
        setGeolocation: ([_, geo], state, utils) => {
          utils.changeValue(state, 'lat_lon', () => geo.location);
        },
        setFieldValue: ([field], state, utils) => {
          utils.changeValue(state, field.field, () => field.value);
        },
      }}
      render={({
        values,
        form,
        handleSubmit,
        submitting,
        pristine,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormScrollError />
          <Button
            style={{ marginBottom: '2rem' }}
            label="Translate"
            icon="fa-language"
            color="primary"
            confirm
            confirmMessage={t('common.translateConfirm')}
            onClick={() => handleTranslate(values, form.mutators.setFieldValue)}
          />
          <div className="columns">
            <div className="column">
              <MultilingualInput
                name="name"
                label={t('common.name')}
                icon="heading"
                value={values.name}
                defaultLang={defaultLang}
                required
              />
              {!currentSite && (
                <FormInput
                  type="custom"
                  name="site"
                  label={t('common.site')}
                  required
                >
                  <SiteSelect />
                </FormInput>
              )}
              <FormInput
                type="custom"
                name="category_item"
                label={t('locations.categoryItem')}
                required
              >
                <CategoryItemSelect
                  onDataLoaded={onCategoryItemsLoaded}
                  site={values.site}
                  isFilter={false}
                  required
                />
              </FormInput>

              <MultilingualInput
                name="description"
                label={t('common.description')}
                value={values.description}
                type="htmlEditor"
                defaultLang={defaultLang}
                required
              />
            </div>
            <div className="column">
              <FormInput
                name="images"
                type="image"
                multiple
                nbCols={5}
                maxWidth={800}
                maxHeight={1200}
              />

              <FormInput
                name="address"
                type="geolocation"
                onChange={form.mutators.setGeolocation}
                label={t('common.address')}
                required
              />
              <FormInput
                type="number"
                name="distance"
                label={t('locations.distance')}
              />
              <FormInput
                name="phone"
                label={t('locations.phoneNumber')}
                icon="phone"
              />

            </div>
          </div>
          <FormInput type="date" icon="calendar" name="publication_start" label={t('common.from')} />
          <FormInput type="date" icon="calendar" name="publication_end" label={t('common.to')} />
          <FormInput
            type="submit"
            label={t('common.save')}
            icon="map-marker-alt"
            disabled={submitting || pristine}
          />
        </form>
      )}
    />
  );
};

AroundMeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  location: PropTypes.object,
  onCategoryItemsLoaded: PropTypes.func.isRequired,
};

AroundMeForm.defaultProps = {
  location: {},
};

export default AroundMeForm;
