import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const TextArea = ({
  rows,
  value,
  className,
  ...props
}) => {
  const textAreaRef = useRef({});
  const [count, setCount] = useState(rows);
  const [height, setHeight] = useState(5);

  if (textAreaRef.current && textAreaRef.current.scrollHeight !== height) {
    setHeight(textAreaRef.current.scrollHeight);
  }

  useEffect(() => {
    setCount(value.split('\n').length + 1);
  }, [value]);

  return (
    <textarea
      ref={textAreaRef}
      className={`input ${className}`}
      value={value}
      rows={count + 1}
      style={{ minHeight: 100, height, overflow: 'hidden' }}
      {...props}
    />
  );
};

TextArea.propTypes = {
  rows: PropTypes.number,
  className: PropTypes.string,
  value: PropTypes.string,
};

TextArea.defaultProps = {
  className: '',
  rows: null,
  value: '',
};

export default TextArea;
