import Button from 'common/components/Button/Button';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Table from 'common/components/Table/Table';
import useRouter from 'common/hooks/use-router';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useSite from 'sites/contexts/sites';

const SitesList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    fetchItems: fetchSites, items: sites, isFetching, pageCount, remove, filters, setFilter,
    itemsCount,
  } = useSite();

  const handleDelete = useCallback(async (row) => {
    await remove(row.original.id);
    fetchSites();
  }, [remove, fetchSites]);

  const handleEdit = useCallback((row) => router.push(`/sites/edit/${row.original.id}`), [router]);

  const columns = useMemo(() => [
    {
      Header: t('common.code'),
      accessor: 'code',
      filterName: 'code_contains',
    },
    {
      Header: t('common.name'),
      accessor: 'name',
      filterName: 'name_contains',
    },
    {
      Header: t('common.address'),
      accessor: 'address',
      filterName: 'address_contains',
    },
    {
      Header: t('common.isActive'),
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { active } } }) => (
        <span>
          {active ? <i className="fas fa-check has-text-success" /> : <i className="fas fa-times has-text-danger" />}
        </span>
      ),
    },
    {
      Header: t('sites.isOpen'),
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { isOpen } } }) => (
        <span>
          {isOpen ? <i className="fas fa-check has-text-success" /> : <i className="fas fa-times has-text-danger" />}
        </span>
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDelete(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('sites.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, handleEdit, handleDelete]);

  return (
    <>
      <PageHeader
        title={t('menu.listSites')}
        link={{ url: '/sites/add', icon: 'map-pin', label: t('sites.addSite') }}
      />
      <section className="section list-page">
        <Table
          data={sites}
          columns={columns}
          fetchData={fetchSites}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          itemsCount={itemsCount}
        />
      </section>
    </>
  );
};

export default SitesList;
