import React from 'react';
import PropTypes from 'prop-types';

const Overlay = ({ color, ...rest }) => (
  <div
    className={`SquareOverlay overlay-${color}`}
    {...rest}
  />
);

Overlay.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Overlay;
