import React, { useEffect, useState, useCallback } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import FormInput from 'common/components/FormInput/FormInput';

import useSite from 'sites/contexts/sites';
import SiteSelect from 'sites/components/SiteSelect';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import Button from 'common/components/Button/Button';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';

const HourSlotModelsForm = ({ onSubmit, hourSlotModel }) => {
  const { t, i18n } = useTranslation();
  const { currentSite } = useSite();

  const [timeConfig, setTimeConfig] = useState([]);

  const defaultLang = currentSite ? currentSite.default_language : i18n.language;

  useEffect(() => {
    setTimeConfig(hourSlotModel?.orderHours.map((field) => field.isTime));
  }, [hourSlotModel, setTimeConfig]);

  const updateTimeConfig = useCallback((index) => {
    setTimeConfig((currentTimeConfig) => {
      const newTimeConfig = currentTimeConfig || [];

      newTimeConfig[index] = !newTimeConfig[index];
      return newTimeConfig;
    });
  }, []);

  if (currentSite && hourSlotModel && hourSlotModel.id && hourSlotModel.site?.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={hourSlotModel?.id ? {
        ...hourSlotModel,
        site: hourSlotModel?.site?.id,
        orderHours: hourSlotModel?.orderHours,
      } : { site: currentSite ? currentSite.id : null }}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([field], state, utils) => {
          utils.changeValue(state, field.field, () => field.value);
        },
      }}
      render={({
        handleSubmit,
        submitting,
        pristine,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormScrollError />

          <div className="row" style={{ alignItems: 'flex-end' }}>
            <FormInput
              type="text"
              name="name"
              label={t('common.name')}
              required
            />

            <MultilingualInput
              name="tname"
              label={t('vocabulary.tname')}
              icon="heading"
              defaultLang={defaultLang}
            />

            <div style={{ paddingBottom: 16 }}>
              <FormInput
                type="checkbox"
                name="active"
                label={t('common.active')}
              />
            </div>

          </div>

          {!currentSite && (
            <FormInput
              type="custom"
              name="site"
              label={t('common.site')}
              required
            >
              <SiteSelect />
            </FormInput>
          )}

          <br />

          <FieldArray
            name="orderHours"
          >
            {({ fields }) => (
              <div>
                {fields && fields.map((field, index) => (
                  <div
                    key={field}
                    style={{
                      backgroundColor: '#eeeeee',
                      borderWidth: 1,
                      borderColor: '#dddddd',
                      borderStyle: 'solid',
                      padding: 16,
                      marginBottom: 16,
                      borderRadius: 8,
                    }}
                  >
                    <div className="row" style={{ alignItems: 'flex-end', marginBottom: 16 }}>

                      <FormInput
                        type={timeConfig && timeConfig[index] ? 'time' : 'text'}
                        name={`orderHours[${index}].Subtitle`}
                        label={timeConfig && timeConfig[index]
                          ? `${index + 1} - ${t('hourSlotModel.isTime')}`
                          : `${index + 1} - ${t('hourSlotModel.isText')}`}
                        required
                      />
                      {(!timeConfig || !timeConfig[index]) && (
                      <div>
                        <MultilingualInput
                          name={`orderHours[${index}].tSubtitle`}
                          label={t('vocabulary.toptionSubtitle')}
                          icon="heading"
                          defaultLang={defaultLang}
                        />
                      </div>
                      )}

                      <div style={{ paddingBottom: 16 }}>
                        <FormInput
                          type="checkbox"
                          name={`orderHours[${index}].isTime`}
                          label={t('hourSlotModel.convertToTime')}
                          onChange={() => updateTimeConfig(index)}
                        />
                      </div>

                    </div>
                    <div className="row" style={{ alignItems: 'flex-end' }}>
                      <div style={{ flex: 1, paddingBottom: 12 }}>
                        <FormInput
                          type="text"
                          name={`orderHours[${index}].Title`}
                          label={t('hourSlotModel.timeSlotTitle')}
                          required
                        />
                      </div>

                      <div style={{ flex: 1 }}>
                        <MultilingualInput
                          name={`orderHours[${index}].tTitle`}
                          label={t('vocabulary.toptionTitle')}
                          icon="heading"
                          defaultLang={defaultLang}
                        />
                      </div>
                      <div style={{ paddingBottom: 16 }}>
                        <Button
                          onClick={() => fields.remove(index)}
                          icon="fa-trash-alt"
                          color="danger"
                          style={{
                            borderRadius: '100%',
                            maxWidth: 30,
                            minWidth: 30,
                            height: 30,
                            fontSize: 12,
                            padding: 0,
                            position: 'relative',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}

                <div style={{ display: 'flex', justifyContent: 'center', margin: '16px 0 30px 0' }}>
                  <Button
                    onClick={() => fields.push({})}
                    icon="fa-plus"
                    color="primary"
                    label={t('hourSlotModel.add')}
                  />
                </div>

              </div>
            )}
          </FieldArray>

          <FormInput
            type="submit"
            label={t('common.save')}
            icon="map-marker-alt"
            disabled={submitting || pristine}
          />
        </form>
      )}
    />
  );
};

HourSlotModelsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  hourSlotModel: PropTypes.object,
};

HourSlotModelsForm.defaultProps = {
  hourSlotModel: {},
};

export default HourSlotModelsForm;
