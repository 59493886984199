import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';

const SiteVocabularyForm = ({ site, values }) => {
  const { t, i18n } = useTranslation();

  // eslint-disable-next-line camelcase
  const defaultLang = values ? values.default_language : i18n.language;

  return (
    <>
      <section className="section" style={{ marginTop: 20, marginBottom: 20 }}>
        <div className="row">
          <div>
            <h2 className="subtitle">{t('vocabulary.separators.products')}</h2>
            <MultilingualInput
              name="vocabulary.productOptions"
              label={t('vocabulary.keys.productOptions')}
              icon="heading"
              value={values?.vocabulary?.productOptions}
              defaultLang={defaultLang}
            />
          </div>

          <div style={{ width: 100, maxWidth: 100 }} />

          <div>
            <h2 className="subtitle">{t('vocabulary.separators.process')}</h2>

            <MultilingualInput
              name="vocabulary.processCustomForm"
              label={t('vocabulary.keys.processCustomForm')}
              icon="heading"
              value={values?.vocabulary?.processCustomForm}
              defaultLang={defaultLang}
            />

            <MultilingualInput
              name="vocabulary.processChoiceForm"
              label={t('vocabulary.keys.processChoiceForm')}
              icon="heading"
              value={values?.vocabulary?.processChoiceForm}
              defaultLang={defaultLang}
            />
          </div>

        </div>

      </section>
    </>
  );
};

SiteVocabularyForm.propTypes = {
  site: PropTypes.object,
  values: PropTypes.object,
};

SiteVocabularyForm.defaultProps = {
  site: {},
  values: {},

};

export default SiteVocabularyForm;
