/* eslint-disable react/prop-types */
import React, { useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from 'common/hooks/use-router';

import Table from 'common/components/Table/Table';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Button from 'common/components/Button/Button';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';

import useMap from 'maps/contexts/maps';
import SiteSelect from 'sites/components/SiteSelect';
import useSite from 'sites/contexts/sites';

const MapsList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { currentSite, setCurrentSite } = useSite();
  const {
    fetchItems: fetchMaps,
    items: maps,
    isFetching,
    pageCount,
    remove,
    update,
    filters,
    setFilter: setMapFilter,
    itemsCount,
  } = useMap();

  useEffect(() => {
    setMapFilter('site_null', 0);
  }, [setMapFilter]);

  const handleDelete = useCallback(async (row) => {
    await remove(row.original.id);
    await fetchMaps();
    const newCurrentSite = { ...currentSite };

    newCurrentSite.maps = newCurrentSite.maps.filter((iMap) => iMap.id !== row.original.id);
    setCurrentSite(newCurrentSite);
  }, [remove, fetchMaps, currentSite, setCurrentSite]);

  const handleEdit = useCallback((row) => router.push(`/maps/edit/${row.original.id}`), [router]);

  const handleToggle = useCallback(async (id) => {
    const specialOffer = maps && maps.find((s) => s.id === id);

    await update(specialOffer.id, { ...specialOffer, active: !specialOffer.active });
    await fetchMaps();
  }, [update, fetchMaps, maps]);

  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      filterName: 'name_contains',
      Cell: ({ row: { original: { id, name } } }) => (
        <span className="table-link" onClick={() => router.push(`/maps/edit/${id}`)}>
          <TranslatedText value={name} />
        </span>
      ),
    },
    {
      Header: t('common.site'),
      filterName: 'site.id',
      hidden: !!currentSite,
      filterComponent: (props) => <SiteSelect isFilter {...props} />,
      Cell: ({ row: { original: { site } } }) => (
        <span>
          {site?.name}
        </span>
      ),
    },
    {
      Header: t('common.status'),
      accessor: 'active',
      Cell: ({ row: { original: { active, id } } }) => (
        <Button
          onClick={() => handleToggle(id)}
          icon={active ? 'fa-check' : 'fa-times'}
          color={active ? 'success' : 'danger'}
          transparent
        />
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDelete(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('maps.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, currentSite, handleToggle, handleEdit, handleDelete, router]);

  return (
    <>
      <PageHeader
        title={t('menu.listMaps')}
        link={{ url: '/maps/add', icon: 'map', label: t('maps.addMap') }}
      />
      <section className="section list-page">
        <Table
          data={maps}
          columns={columns}
          fetchData={fetchMaps}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setMapFilter}
          itemsCount={itemsCount}
        />
      </section>
    </>
  );
};

export default MapsList;
