import React, {
  useEffect, useContext, useCallback, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';

import PageHeader from 'common/components/PageHeader/PageHeader';
import PageLoader from 'common/components/PageLoader/PageLoader';
import AssignWaiterAreasForm from './AssignWaiterAreasForm';
import useLocalizationModel from '../../localizationModels/contexts/localization';
import useWaiterArea from '../../waiterAreas/contexts/waiterAreas';
import usePreparatorArea from '../../preparatorAreas/contexts/preparatorAreas';

/* eslint-disable  no-unused-expressions */

const AssignWaiterAreasFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const [finalModel, setFinalModel] = useState({});
  const {
    save, update, fetchItem: fetchLocalizationModel, item: localizationModel, isFetching: isLocalizationModelFetching,
  } = useLocalizationModel();
  const {
    items: waiterAreas, fetchItems: fetchWaiterAreas, isFetching: isWaiterAreasFetching,
  } = useWaiterArea();
  const {
    items: preparatorAreas, fetchItems: fetchPreparatorAreas, isFetching: isPreparatorAreasFetching,
  } = usePreparatorArea();

  const { id: localizationModelId } = router.match.params;

  useEffect(() => {
    fetchLocalizationModel(localizationModelId);
    fetchWaiterAreas();
    fetchPreparatorAreas();
  }, [fetchLocalizationModel, fetchPreparatorAreas, fetchWaiterAreas, localizationModelId]);

  const onSubmit = useCallback(async (assignatedAreas) => {
    const payload = { ...assignatedAreas };

    const localizationModelFieldNames = localizationModel.localizationModelField.map((field) => field.label);

    if (!payload.waiterAreas || !payload.waiterAreas.length || !payload.waiterAreas.find((wa) => wa.active)
      || !payload.preparatorAreas || !payload.preparatorAreas.length || !payload.preparatorAreas.find((pa) => pa.active)
    ) {
      setAlert(t('localizationModels.warningFillAtLeastOne'), 'danger');
      return;
    }

    // Add waiter id and preparator id to respective objects
    payload.waiterAreas?.forEach((item, index) => {
      if (waiterAreas[index] && payload.waiterAreas[index]) {
        payload.waiterAreas[index].waiterId = waiterAreas[index].id;
        payload.waiterAreas[index].waiterName = waiterAreas[index].name;

        // Clean up the fieldnames that might remain if one field is renamed
        payload.waiterAreas[index].localizationModelField?.forEach(
          (field, index) => {
            if (field) {
              Object.keys(field).forEach((fieldName) => {
                if (!localizationModelFieldNames.includes(fieldName)) {
                  delete field[fieldName];
                }
              });
            } else {
              payload.waiterAreas[index].localizationModelField.splice(index, 1);
            }
          },
        );
      } else {
        payload.waiterAreas.splice(index, 1);
      }
    });

    payload.preparatorAreas?.forEach((item, index) => {
      if (preparatorAreas[index]) {
        payload.preparatorAreas[index].preparatorId = preparatorAreas[index].id;
        payload.preparatorAreas[index].preparatorName = preparatorAreas[index].name;

        // Clean up the fieldnames that might remain if one field is renamed
        payload.preparatorAreas[index].localizationModelField?.forEach(
          (field, index) => {
            if (field) {
              Object.keys(field).forEach((fieldName) => {
                if (!localizationModelFieldNames.includes(fieldName)) {
                  delete field[fieldName];
                }
              });
            } else {
              payload.preparatorAreas[index].localizationModelField.splice(index, 1);
            }
          },
        );
      } else {
        payload.preparatorAreas.splice(index, 1);
      }
    });

    // build inclusion area JSON, while removing the null (deleted) fields
    const inclusionAreas = {
      waiterAreas: payload.waiterAreas.map((wa) => ({
        ...wa,
        localizationModelField: wa.localizationModelField.filter((field) => !!field),
      })),
      preparatorAreas: payload.preparatorAreas.map((pa) => ({
        ...pa,
        localizationModelField: (pa.localizationModelField?.filter((field) => !!field) ?? []),
      })),
    };

    payload.inclusionAreas = JSON.stringify(inclusionAreas);

    if (localizationModelId) {
      const res = await update(payload.id, payload);

      if (!res.error) {
        setAlert(t('localizationModels.successEdit'), 'success');
        router.push('/localizationmodels');
      } else {
        setAlert(res.message, 'danger');
      }
    } else {
      const res = await save(payload);

      if (!res.error) {
        setAlert(t('localizationModels.successAdd'), 'success');
        router.push('/localizationmodels');
      } else {
        setAlert(res.message, 'danger');
      }
    }
  }, [localizationModel, localizationModelId,
    preparatorAreas, router, save, setAlert, t, update, waiterAreas]);

  // Format a new localizationModel object with waiterAreas and preparatorAreas values in object
  useEffect(() => {
    if (localizationModel) {
      let jsonContent = {};

      try {
        jsonContent = JSON.parse(localizationModel.inclusionAreas);
      } catch (error) {
        console.log("Erreur d'interprétation du JSON");
        console.error(error);
      }
      setFinalModel({
        ...localizationModel,
        waiterAreas: jsonContent?.waiterAreas || [],
        preparatorAreas: jsonContent?.preparatorAreas || [],
      });
    }
  }, [localizationModel]);

  return (
    <>
      <PageLoader processing={isLocalizationModelFetching && isWaiterAreasFetching && isPreparatorAreasFetching} />
      <PageHeader
        title={t('localizationModels.assignAreas')}
        subLink={{
          url: `/localizationmodels/edit/${localizationModelId}`,
          icon: 'arrow-left',
          label: t('common.back'),
        }}
      />
      <section className="section form-page">
        <AssignWaiterAreasForm
          onSubmit={onSubmit}
          localizationModel={finalModel}
          waiterAreas={waiterAreas}
          preparatorAreas={preparatorAreas}
        />
      </section>
    </>
  );
};

export default AssignWaiterAreasFormPage;
