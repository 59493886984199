import Button from 'common/components/Button/Button';
import FormInput from 'common/components/FormInput/FormInput';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';
import fetchJSON from 'common/utils/fetchJSON';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import SiteSelect from 'sites/components/SiteSelect';
import useSite from 'sites/contexts/sites';

const MapForm = ({ onSubmit, map }) => {
  const { t, i18n } = useTranslation();
  const { currentSite } = useSite();

  const handleImageLoaded = useCallback(async (upload, setFieldValue) => {
    if (upload.length > 0) {
      setFieldValue({ field: 'backgroundWidth', value: upload[0].width });
      setFieldValue({ field: 'backgroundHeight', value: upload[0].height });
    }
  }, []);

  const initialValues = useMemo(() => map?.id
    ? {
      ...map,
      site: map.site.id,
    }
    : {
      ...map,
      site: currentSite ? currentSite.id : null,
      mapBackOfficeScale: 1,
      mapBackOfficePositionX: 0,
      mapBackOfficePositionY: 0,
    }, [map, currentSite]);

  if (currentSite && map && map.id && map.site?.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  const handleTranslate = async (values, setFieldValue) => {
    let translated;
    const { name } = values;

    if (name) {
      translated = await fetchJSON({
        url: 'sites/translate',
        method: 'POST',
        payload: { items: { name }, site: currentSite.id },
      });

      setFieldValue({
        field: 'name',
        value: translated.name,
      });
    }
  };

  // eslint-disable-next-line camelcase
  const defaultLang = map ? map.site?.default_language : (
    currentSite ? currentSite.default_language : i18n.language);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{
        setFieldValue: ([field], state, utils) => {
          utils.changeValue(state, field.field, () => field.value);
        },
      }}
      render={({
        values, handleSubmit, submitting, pristine, form,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormScrollError />
          <Button
            style={{ marginBottom: '2rem' }}
            label="Translate"
            icon="fa-language"
            color="primary"
            confirm
            confirmMessage={t('common.translateConfirm')}
            onClick={() => handleTranslate(values, form.mutators.setFieldValue)}
          />
          <MultilingualInput
            name="name"
            label={t('common.name')}
            icon="heading"
            value={values.name}
            defaultLang={defaultLang}
            required
          />
          {!currentSite && (
          <FormInput
            type="custom"
            name="site"
            label={t('common.site')}
            required
          >
            <SiteSelect />
          </FormInput>
          )}
          <FormInput
            label={t('maps.mapBackground')}
            name="backgroundImage"
            type="image"
            keepRatio={false}
            multiple={false}
            onUpload={(upload) => handleImageLoaded(upload, form.mutators.setFieldValue)}
          />

          {(values.backgroundWidth && values.backgroundHeight) && (
            <p className="map-dimensions">
              {values.backgroundWidth}
              {' '}
              px /
              {' '}
              {values.backgroundHeight}
              {' '}
              px
            </p>
          )}

          <div className="row" style={{ display: 'none' }}>
            <FormInput
              type="number"
              name="backgroundWidth"
              label={t('sites.backgroundWidth')}
              disabled
              required
              style={{ color: '#1d1c26' }}
            />
            <FormInput
              type="number"
              name="backgroundHeight"
              label={t('sites.backgroundHeight')}
              style={{ color: '#1d1c26' }}
              disabled
              required
            />
          </div>

          <div className="row">
            <FormInput
              type="number"
              name="mapBackOfficeScale"
              legend={t('sites.mapScaleLegend')}
              label={t('sites.mapScale')}
              required
            />
            <FormInput
              type="number"
              name="mapBackOfficePositionX"
              label={t('sites.mapPositionX')}
              required
            />
            <FormInput
              type="number"
              name="mapBackOfficePositionY"
              label={t('sites.mapPositionY')}
              required
            />
          </div>

          {map && (
          <FormInput
            isHorizontal
            type="checkbox"
            name="active"
            label={t('common.active')}
          />
          )}
          <FormInput
            type="submit"
            label={t('common.save')}
            icon="gift"
            disabled={submitting || pristine}
          />
        </form>
      )}
    />
  );
};

MapForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  map: PropTypes.object,
};

MapForm.defaultProps = {
  map: {},
};

export default MapForm;
