import React from 'react';
import { FormSpy } from 'react-final-form';

const FormScrollError = () => (
  <FormSpy
    subscription={{ submitFailed: true }}
    onChange={() => {
      const el = document.querySelector('.fieldError');

      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    }}
  />
);

export default FormScrollError;
