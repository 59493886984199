import React, {
  useEffect, useState, Fragment, useCallback, useContext,
} from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import FormInput from 'common/components/FormInput/FormInput';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';
import CategorySelect from 'categories/components/CategorySelect';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import useFetch from 'common/hooks/use-fetch';
import OptionSelect from 'products/components/OptionSelect';
import Button from 'common/components/Button/Button';
import fetchJSON from 'common/utils/fetchJSON';
import { FieldArray } from 'react-final-form-arrays';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import AlertsContext from 'common/contexts/alerts';
import arrayMutators from 'final-form-arrays';

const CatalogProductForm = ({ onSubmit, product }) => {
  const { t } = useTranslation();
  const { fetchData: fetchVariants } = useFetch();
  const [initialValues, setInitialValues] = useState({});
  const [options, setOptions] = useState([]);
  const { setAlert } = useContext(AlertsContext);

  useEffect(() => {
    const fetchOpts = async () => {
      try {
        const fetchedOption = await fetchVariants(
          'options',
        );

        setOptions(fetchedOption);
        return fetchedOption;
      } catch (e) {
        setAlert(e.message, 'danger');
      }
    };

    fetchOpts();
  }, [setAlert, setOptions, fetchVariants]);

  const computePrices = useCallback((variants, variant_prices) => {
    const prices = [];

    if (variants) {
      variants.forEach((v) => {
        let price = variant_prices?.find((p) => p.option_variant?.id === v.id);

        if (!price) {
          price = {
            option_variant: v.id,
            price: null,
          };
        }

        prices.push(price);
      });
    }

    return prices;
  }, []);

  const handleProductOptionChange = useCallback(async (option, index, setFieldValue) => {
    if (option) {
      setFieldValue({
        field: `options[${index}]`,
        value: {
          option: option.id,
          variant_prices: option.option_variants.map((ov) => ({ option_variant: ov, price: '' })),
        },
      });
    } else {
      setFieldValue({
        field: `options[${index}]`,
        value: {
          option: null,
          variant_prices: [],
        },
      });
    }
  }, []);

  useEffect(() => {
    const initWithVariantPrices = async () => {
      const formValues = {
        ...product,
        site: product.site?.id,
        category: product.category.id,
        vat: product.vat?.id,
      };

      const options = product.options.map(async (opt) => {
        const variants = await fetchVariants(
          `option-variants?option=${opt.option.id}${opt.option.site === null
            ? ''
            : `&option.site=${opt.option.site}`}`,
        );

        return {
          id: opt.id,
          option: opt.option.id,
          variant_prices: computePrices(variants, opt.variant_prices),
        };
      });

      formValues.options = await Promise.all(options);
      setInitialValues(formValues);
    };

    if (product?.id) {
      if (product?.option) {
        initWithVariantPrices();
      } else {
        setInitialValues({
          ...product,
          site: null,
          category: product.category.id,
        });
      }
    } else {
      setInitialValues({
        variant_prices: [],
        active: true,
        option: null,
        site: null,
        description: {},
      });
    }
  }, [product, fetchVariants, computePrices]);

  const handleTranslate = async (values, setFieldValue) => {
    let translated;
    const { name, description } = values;

    if (name || description) {
      translated = await fetchJSON({
        url: 'sites/translate',
        method: 'POST',
        payload: { items: { name, description }, site: null },
      });

      setFieldValue({
        field: 'name',
        value: translated.name,
      });
      setFieldValue({
        field: 'description',
        value: translated.description,
      });
    }
  };

  const handleAddVariant = (async (index, option, variant, setFieldValue) => {
    setFieldValue({
      field: `options[${index}]`,
      value: {
        option: option.option,
        variant_prices: [{ price: 0, option_variant: variant }, ...option.variant_prices],
      },
    });
  });

  const renderAvailableVariants = (values, optionIndex, setFieldValue) => {
    const opt = options.find((o) => o.id === values.options[optionIndex].option?.id);

    if (opt) {
      const variants = opt.option_variants
        .filter((ov) => values.options[optionIndex].variant_prices
          .map(((vp) => vp.option_variant.id)).indexOf(ov.id) < 0);

      return variants.map((variant) => (
        <div onClick={() => handleAddVariant(optionIndex, values.options[optionIndex], variant, setFieldValue)}>
          <TranslatedText value={variant.name} />
        </div>
      ));
    }
    return (<></>);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{
        setFieldValue: ([field], state, utils) => {
          utils.changeValue(state, field.field, () => field.value);
        },
        ...arrayMutators,
      }}
      render={({
        values, handleSubmit, submitting, pristine, form,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormScrollError />
          <Button
            style={{ marginBottom: '2rem' }}
            label="Translate"
            icon="fa-language"
            color="primary"
            confirm
            confirmMessage={t('common.translateConfirm')}
            onClick={() => handleTranslate(values, form.mutators.setFieldValue)}
          />
          <div className="columns">
            <div className="column">
              <MultilingualInput
                name="name"
                label={t('common.name')}
                value={values.name}
                icon="heading"
                defaultLang="fr-FR"
                required
              />
              <FormInput
                type="custom"
                name="category"
                label={t('products.category')}
                required
              >
                <CategorySelect
                  site_null={1}
                  reference
                />
              </FormInput>
              <MultilingualInput
                name="description"
                label={t('common.description')}
                value={values.description}
                type="htmlEditor"
                defaultLang="fr-FR"
              />
              <FormInput
                name="reference"
                label={t('common.reference')}
                icon="pencil-alt"
              />
            </div>
            <div className="column">
              <FormInput
                name="images"
                type="image"
                multiple
                nbCols={5}
                maxWidth={800}
                maxHeight={1200}
              />
              <FormInput
                type="number"
                name="price"
                label={t('common.price')}
                icon="euro-sign"
                required
              />

              <FieldArray name="options">
                {({ fields: optionFields }) => (
                  <div>
                    {optionFields && optionFields.map((nameOption, optionIndex) => (
                      <React.Fragment key={nameOption}>
                        <div className="row">
                          <FormInput
                            type="custom"
                            name={`options[${optionIndex}].option`}
                            onChange={(_val, value) => {
                              handleProductOptionChange(value.option, optionIndex, form.mutators.setFieldValue);
                            }}
                            label={t('common.option')}
                            required
                          >
                            <OptionSelect site={values.site} />
                          </FormInput>
                          <Button
                            onClick={() => optionFields.remove(optionIndex)}
                            icon="fa-trash-alt"
                            color="danger"
                            style={{
                              borderRadius: '100%',
                              maxWidth: 30,
                              minWidth: 30,
                              height: 30,
                              fontSize: 12,
                              padding: 0,
                              top: 35,
                              position: 'relative',
                            }}
                          />
                        </div>
                        <FieldArray name={`options[${optionIndex}].variant_prices`}>
                          {({ fields: variantPriceFields }) => (
                            <div style={{ margin: '15px' }}>
                              {variantPriceFields && variantPriceFields.map((nameVariant, variantPriceIndex) => (
                                <React.Fragment key={nameVariant}>
                                  <div className="row">
                                    <TranslatedText
                                      value={variantPriceFields.value[variantPriceIndex].option_variant.name}
                                    />
                                    <FormInput
                                      name={`options[${optionIndex}].variant_prices[${variantPriceIndex}].price`}
                                      label={t('common.option_price')}
                                      icon="money-bill"
                                      required
                                    />
                                    <Button
                                      onClick={() => variantPriceFields.remove(variantPriceIndex)}
                                      icon="fa-trash-alt"
                                      color="danger"
                                      style={{
                                        borderRadius: '100%',
                                        maxWidth: 30,
                                        minWidth: 30,
                                        height: 30,
                                        fontSize: 12,
                                        padding: 0,
                                        top: 35,
                                        position: 'relative',
                                      }}
                                    />
                                  </div>
                                </React.Fragment>
                              ))}
                              {renderAvailableVariants(values, optionIndex, form.mutators.setFieldValue)}
                            </div>
                          )}
                        </FieldArray>
                      </React.Fragment>
                    ))}
                    <Button
                      onClick={() => optionFields.push({})}
                      icon="fa-plus"
                      color="primary"
                      label={t('common.addVariant')}
                    />
                  </div>
                )}
              </FieldArray>
            </div>
          </div>
          <FormInput
            type="submit"
            label={t('common.save')}
            icon="pizza-slice"
            disabled={submitting || pristine}
          />
        </form>
      )}
    />
  );
};

CatalogProductForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  product: PropTypes.object,
};

CatalogProductForm.defaultProps = {
  product: {},
};

export default CatalogProductForm;
