import React from 'react';
import RemoteSelect from 'common/components/RemoteSelect/RemoteSelect';

const processOptions = (options) => options.map(({ id, username }) => (
  { value: id, label: username }
));

const UserSelect = (props) => (
  <RemoteSelect
    filters={[{ queryName: 'role.type', propName: 'role' }]}
    url="users"
    processOptions={processOptions}
    {...props}
  />
);

export default UserSelect;
