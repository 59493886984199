import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import useBeachVisualizer from '../../contexts/beachVisualizer';
import Overlay from './Overlay';

const BoardSquare = ({
  item,
}) => {
  const {
    handleClickonSquare, selectedSeats, date, searchSeat,
  } = useBeachVisualizer();

  const color = '';
  const today = moment().utc().startOf('day').format();

  const isFullyPaid = useMemo(() => {
    let returnValue = true;

    (item.bookings || []).forEach((booking) => {
      if (booking.payment?.status !== 'PAID') {
        returnValue = false;
      }
      (booking.orders || []).forEach((order) => {
        if (order.payment?.status !== 'PAID') {
          returnValue = false;
        }
      });
    });

    return returnValue;
  }, [item.bookings]);

  const getOverlay = useMemo(() => {
    let returnValue = '';

    if (searchSeat !== '') {
      if (item.name?.toLowerCase().includes(searchSeat.toLowerCase())) {
        returnValue = 'yellow';
      } else {
        (item.bookings || []).forEach((booking) => {
          if (booking.clientName.toLowerCase().includes(searchSeat.toLowerCase())
          || booking.code.toLowerCase().includes(searchSeat.toLowerCase())
          || booking.user?.email.includes(searchSeat.toLowerCase())
          || (booking.roomID && booking.roomID.includes(searchSeat.toLowerCase()))) {
            returnValue = 'yellow';
          }
        });
      }
    }

    if (returnValue === '' && date === today && item.calls && item.calls.length > 0) {
      returnValue = 'orange';
    }
    if (returnValue === '') {
      (item.bookings || []).forEach((booking) => {
        if (booking.orders
          && booking.orders.length > 0) {
          (booking.orders.forEach((order) => {
            if (order.state !== 'done') {
              returnValue = 'blue';
            }
          }));
        }
      });
    }
    if (returnValue === '' && item.bookings.length > 0 && isFullyPaid) {
      returnValue = 'green';
    } else if (returnValue === '' && item.bookings.length > 0 && !isFullyPaid) {
      returnValue = 'red';
    }

    return returnValue;
  }, [item, date, today, isFullyPaid, searchSeat]);

  const isCall = useMemo(() => {
    let returnValue = false;

    if (item.bookings && date === today && item.calls && item.calls.length > 0) {
      returnValue = true;
    }

    return returnValue;
  }, [item.bookings, date, today, item.calls]);

  const getOrders = useMemo(() => {
    let returnValue = false;

    (item.bookings || []).forEach((booking) => {
      if (booking.orders
        && booking.orders.length > 0) {
        (booking.orders.forEach((order) => {
          if (order.state !== 'done') {
            if (!returnValue) {
              returnValue = order.created_at;
            } else if (returnValue > order.created_at) {
              returnValue = order.created_at;
            }
          }
        }));
      }
    });

    return returnValue;
  }, [item.bookings]);

  return (
    <div
      style={{
        backgroundColor: color,
        transform: item.rotate ? `rotate(${item.rotate}deg)` : null,
      }}
      className="BoardSquare mouse-select"
    >
      <div
        onClick={() => handleClickonSquare(item)}
        className="Square"
      >
        { item?.furniture?.image && (
        <>
          <span className="seatNumber">
            {item.name}
          </span>
          <img alt={item.name} src={`${process.env.REACT_APP_API_URL}${item.furniture.image.url}`} />
        </>
      ) }
      </div>
      { selectedSeats.find(({ id }) => id === item.id) && (
        <Overlay color="blue" onClick={() => handleClickonSquare(item)} />
      ) }
      { (getOverlay || item.bookings?.length > 0) && (
        <>
          <Overlay
            color={getOverlay}
            onClick={() => handleClickonSquare(item)}
          />
        </>
      ) }
      { (date === today && item.calls && item.calls.length > 0) && (
        <div
          onClick={() => handleClickonSquare(item)}
          className={moment().diff(item.calls[0].created_at, 'minutes') > 10 ? 'callInfo late' : 'callInfo'}
        >
          <div className="icon-bell">
            <img src="/assets/bell.png" alt="Call" />
          </div>
        </div>
      ) }

      { (!isCall && getOrders && item.bookings?.map((booking) => (
        <Fragment key={booking.id}>
          <div
            onClick={() => handleClickonSquare(item)}
            className={
                // eslint-disable-next-line camelcase
                moment().diff(getOrders, 'minutes') > 10 ? 'orderInfo late' : 'orderInfo'
              }
          >

            <div className="icon-utensils">
              <img src="/assets/utensils.png" alt="Waiting Orders" />
            </div>
          </div>
        </Fragment>
      )))}
    </div>
  );
};

BoardSquare.propTypes = {
  item: PropTypes.object.isRequired,
};

export default BoardSquare;
