import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import Table from 'common/components/Table/Table';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Button from 'common/components/Button/Button';

import SiteSelect from 'sites/components/SiteSelect';
import useSite from 'sites/contexts/sites';
import useTemplate from '../../context/template';

const TemplateList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    fetchItems: fetchTemplates, items: templates, isFetching, pageCount, remove, filters, setFilter, update,
    itemsCount,
  } = useTemplate();
  const { currentSite } = useSite();

  const handleDelete = useCallback(async (row) => {
    await remove(row.original.id);
    await fetchTemplates();
  }, [remove, fetchTemplates]);

  const handleEdit = useCallback((row) => router.push(`/templates/edit/${row.original.id}`), [router]);

  const handleToggle = useCallback(async (id) => {
    const template = templates && templates.find((p) => p.id === id);

    // eslint-disable-next-line no-restricted-syntax
    for (const item of templates) {
      if (template.id !== item.id && item.active === true) {
        update(item.id, { ...item, active: false });
      }
    }
    await update(template.id, { ...template, active: true });
    await fetchTemplates();
  }, [update, fetchTemplates, templates]);

  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      filterName: 'name_contains',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { id, name } } }) => (
        <span className="table-link" onClick={() => router.push(`/templates/edit/${id}`)}>
          {name}
        </span>
      ),
    },
    {
      Header: t('common.site'),
      filterName: 'category_item.site.id',
      hidden: !!currentSite,
      filterComponent: (props) => <SiteSelect isFilter {...props} />,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { category_item: category } } }) => (
        <span>
          {category?.site?.name}
        </span>
      ),
    },
    {
      Header: t('common.active'),
      accessor: 'active',
      Cell: ({ row: { original: { active, id } } }) => (
        <Button
          onClick={() => { if (!active) { handleToggle(id); } }}
          icon={active ? 'fa-check' : 'fa-times'}
          color={active ? 'success' : 'danger'}
          transparent
        />
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDelete(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('common.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, currentSite, router, handleToggle, handleEdit, handleDelete]);

  return (
    <>
      <PageHeader
        title={t('menu.handleTemplate')}
        link={{
          url: '/templates/add',
          icon: 'plus',
          label: t('template.addNewTemplate'), // TODO: TRAD
        }}
      />
      <section className="section list-page">
        <Table
          data={templates}
          columns={columns}
          fetchData={fetchTemplates}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          itemsCount={itemsCount}
        />
      </section>
    </>
  );
};

export default TemplateList;
