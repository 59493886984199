import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import { AlertsProvider } from 'common/contexts/alerts';
import { MenuProvider } from 'common/contexts/menu';
import { AuthProvider } from 'common/contexts/auth';
import { UsersProvider } from 'users/contexts/users';
import { SitesProvider } from 'sites/contexts/sites';
import { CategoriesProvider } from 'categories/contexts/categories';
import { SocketProvider } from 'realtime/contexts/socket';
import { ProductsProvider } from 'products/contexts/products';
import { OptionsProvider } from 'products/contexts/options';
import { CategoryServicesProvider } from 'categoryServices/contexts/categoryServices';
import { ServicesProvider } from 'services/contexts/services';
import { LocationsProvider } from 'aroundMe/contexts/locations';
import { CategoryItemsProvider } from 'categoryItems/contexts/categoryItems';
import { FurnituresProvider } from 'furnitures/contexts/furnitures';
import { OrdersProvider } from 'orders/contexts/orders';
import { BeachEditorProvider } from 'sites/pages/BeachEditor/contexts/beachEditor';
import { BeachVisualizerProvider } from 'sites/pages/BeachVisualizer/contexts/beachVisualizer';
// import { BookingsProvider } from 'bookings/contexts/bookings';
import { CallsProvider } from 'calls/contexts/calls';
import { SeatsProvider } from 'common/contexts/seats';
import { WaiterAreasProvider } from 'waiterAreas/contexts/waiterAreas';
import { PreparatorAreasProvider } from 'preparatorAreas/contexts/preparatorAreas';
import { PriceAreasProvider } from 'priceAreas/contexts/priceAreas';
import { SpecialOffersProvider } from 'specialOffers/contexts/specialOffers';
import { MapsProvider } from 'maps/contexts/maps';
import { VatProvider } from 'vat/contexts/vat';
import { ReportsProvider } from 'reports/contexts/reports';
import { loader } from 'common/components/PageLoader/PageLoader';
import { LocalizationModelsProvider } from 'localizationModels/contexts/localization';
import { WatchesProvider } from 'watches/contexts/watches';
import { HourslotModelsProvider } from './hourSlotModels/contexts/hourSlot';
import { TemplateProvider } from './template/context/template';

const AppProvider = ({ children }) => (
  <Suspense fallback={loader()}>
    <AlertsProvider>
      <VatProvider>
        <SitesProvider>
          <AuthProvider>
            <MenuProvider>
              <WatchesProvider>
                <UsersProvider>
                  <CategoriesProvider>
                    <OptionsProvider>
                      <ProductsProvider>
                        <CategoryServicesProvider>
                          <ServicesProvider>
                            {/* <BookingsProvider> */}
                            <CallsProvider>
                              <OrdersProvider>
                                <MapsProvider>
                                  <CategoryItemsProvider>
                                    <SeatsProvider>
                                      <FurnituresProvider>
                                        <LocationsProvider>
                                          <BeachEditorProvider>
                                            <BeachVisualizerProvider>
                                              <WaiterAreasProvider>
                                                <PreparatorAreasProvider>
                                                  <PriceAreasProvider>
                                                    <ReportsProvider>
                                                      <SocketProvider>
                                                        <SpecialOffersProvider>
                                                          <LocalizationModelsProvider>
                                                            <HourslotModelsProvider>
                                                              <TemplateProvider>
                                                                {children}
                                                              </TemplateProvider>
                                                            </HourslotModelsProvider>
                                                          </LocalizationModelsProvider>
                                                        </SpecialOffersProvider>
                                                      </SocketProvider>
                                                    </ReportsProvider>
                                                  </PriceAreasProvider>
                                                </PreparatorAreasProvider>
                                              </WaiterAreasProvider>
                                            </BeachVisualizerProvider>
                                          </BeachEditorProvider>
                                        </LocationsProvider>
                                      </FurnituresProvider>
                                    </SeatsProvider>
                                  </CategoryItemsProvider>
                                </MapsProvider>
                              </OrdersProvider>
                            </CallsProvider>
                            {/* </BookingsProvider> */}
                          </ServicesProvider>
                        </CategoryServicesProvider>
                      </ProductsProvider>
                    </OptionsProvider>
                  </CategoriesProvider>
                </UsersProvider>
              </WatchesProvider>
            </MenuProvider>
          </AuthProvider>
        </SitesProvider>
      </VatProvider>
    </AlertsProvider>
  </Suspense>
);

AppProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppProvider;
