import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import FormInput from 'common/components/FormInput/FormInput';

import useSite from 'sites/contexts/sites';
import SiteSelect from 'sites/components/SiteSelect';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import Button from 'common/components/Button/Button';
import Collapsible from 'common/components/Collapsible/Collapsible';
import { Link } from 'react-router-dom';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';

const LocalizationModelsForm = ({ onSubmit, localizationModel, assignmentId }) => {
  const { t, i18n } = useTranslation();
  const { currentSite } = useSite();

  if (currentSite && localizationModel && localizationModel.id && localizationModel.site?.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  const defaultLang = currentSite ? currentSite.default_language : i18n.language;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={localizationModel?.id ? {
        ...localizationModel,
        site: localizationModel?.site?.id,
        localizationModelField: localizationModel?.localizationModelField,
      } : { site: currentSite ? currentSite.id : null }}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([field], state, utils) => {
          utils.changeValue(state, field.field, () => field.value);
        },
        setFieldItemsValue: ([fieldItems], state, utils) => {
          utils.changeValue(state, fieldItems.field, () => fieldItems.value);
        },
      }}
      render={({
        handleSubmit,
        values,
        submitting,
        pristine,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormScrollError />

          <h2 className="subtitle">{t('template.general')}</h2>

          <div className="row">

            <FormInput
              type="text"
              name="name"
              label={t('localizationModels.name')}
              required
            />
            <FormInput
              type="checkbox"
              name="active"
              label={t('localizationModels.active')}
            />
          </div>

          <div className="assignLink">
            {assignmentId && values.localizationModelField?.length && values.localizationModelField?.find(
              (field) => field.required && field.type === 'select' && field.label,
            )
              ? (
                <Link as="button" to={`/assign-waiter-areas/${assignmentId}`}>
                  <span className="page-header--link-label" style={{ color: '#444' }}>
                    {t('localizationModels.assignAreas')}
                  </span>
                  <span className="icon page-header--link-button button is-light">
                    <i className="fas fa-map-marker" />
                  </span>
                </Link>
              )
              : (
                <p>
                  {t('localizationModels.assignLegende')}
                </p>
              )}
          </div>

          {!currentSite && (
            <FormInput
              type="custom"
              name="site"
              label={t('common.site')}
              required
            >
              <SiteSelect />
            </FormInput>
          )}

          <h2 className="subtitle">{t('localizationModels.list')}</h2>

          <FieldArray
            name="localizationModelField"
          >
            {({ fields }) => (
              <div>
                {fields && fields.map((field, index) => (
                  <div key={index + field}>
                    <div className="row" style={{ alignItems: 'flex-end' }}>

                      <MultilingualInput
                        name={`localizationModelField[${index}].tlabel`}
                        label={t('vocabulary.tlabel')}
                        icon="heading"
                        defaultLang={defaultLang}
                      />

                      {/* keep it to use as a informatic key */}
                      <FormInput
                        type="text"
                        name={`localizationModelField[${index}].label`}
                        label={t('localizationModels.label')}
                        required
                      />

                      <FormInput
                        type="select"
                        name={`localizationModelField[${index}].size`}
                        label={t('localizationModels.size')}
                        required
                        options={
                          [
                            { value: 'full', label: t('localizationModels.full') },
                            { value: 'half', label: t('localizationModels.half') },
                            { value: 'oneThird', label: t('localizationModels.oneThird') },
                            { value: 'twoThirds', label: t('localizationModels.twoThirds') },
                          ]
                        }
                      />

                      <FormInput
                        type="select"
                        name={t(`localizationModelField[${index}].type`)}
                        label={t('localizationModels.type')}
                        required
                        options={
                          [
                            { value: 'text', label: t('localizationModels.text') },
                            { value: 'select', label: t('localizationModels.select') },
                          ]
                        }
                      />
                      <FormInput
                        type="checkbox"
                        name={`localizationModelField[${index}].required`}
                        label={t('localizationModels.required')}
                        isVertical
                      />

                      <Button
                        onClick={() => fields.remove(index)}
                        icon="fa-trash-alt"
                        color="danger"
                        style={{
                          borderRadius: '100%',
                          maxWidth: 30,
                          minWidth: 30,
                          height: 30,
                          fontSize: 12,
                          padding: 0,
                          top: 30,
                          position: 'relative',
                        }}
                      />
                    </div>

                    <div>
                      {
                        values?.localizationModelField && values.localizationModelField[index]?.type === 'select'
                        && (
                          <Collapsible
                            title={t('localizationModels.fieldsItemNames')}
                            count={values.localizationModelField[index].SubItems?.length || 0}
                            isInitiallyOpen
                          >
                            <FieldArray
                              name={`localizationModelField[${index}].SubItems`}
                            >
                              {({ fields }) => (
                                <div>
                                  {fields && fields.map((field, itemIndex) => (
                                    <div>
                                      <div
                                        key={field + itemIndex}
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'flex-end',
                                        }}
                                      >
                                        <MultilingualInput
                                          name={`localizationModelField[${index}].SubItems[${itemIndex}].toption`}
                                          label={t('vocabulary.toption')}
                                          icon="heading"
                                          defaultLang={defaultLang}
                                        />

                                        {/* keep it to use as a informatic value */}
                                        <FormInput
                                          type="text"
                                          name={`localizationModelField[${index}].SubItems[${itemIndex}].name`}
                                          label={`${t('localizationModels.fieldsItemName', { number: itemIndex })}`}
                                        />
                                        <Button
                                          onClick={() => fields.remove(itemIndex)}
                                          icon="fa-trash-alt"
                                          color="danger"
                                          style={{
                                            borderRadius: '100%',
                                            maxWidth: 30,
                                            minWidth: 30,
                                            height: 30,
                                            fontSize: 12,
                                            padding: 0,
                                            top: 30,
                                            position: 'relative',
                                          }}
                                        />
                                      </div>

                                    </div>
                                  ))}

                                  <Button
                                    onClick={() => fields.push({})}
                                    icon="fa-plus"
                                    color="primary"
                                    label={t('localizationModels.addValue')}
                                    style={{
                                      fontSize: 12,
                                      marginTop: 10,
                                    }}
                                  />
                                </div>
                              )}
                            </FieldArray>
                          </Collapsible>
                        )
                      }
                    </div>
                  </div>

                ))}
                <Button
                  onClick={() => fields.push({})}
                  icon="fa-plus"
                  color="primary"
                  label={t('localizationModels.addField')}
                />
              </div>
            )}
          </FieldArray>

          <div className="is-size-5 my-2">
            {t('localizationModels.fieldsExplanation')}
          </div>

          <FormInput
            type="submit"
            label={t('common.save')}
            icon="map-marker-alt"
            disabled={submitting || pristine}
          />
        </form>
      )}
    />
  );
};

LocalizationModelsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  localizationModel: PropTypes.object,
  assignmentId: PropTypes.number,
};

LocalizationModelsForm.defaultProps = {
  localizationModel: {},
  assignmentId: null,
};

export default LocalizationModelsForm;
