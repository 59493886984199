import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import Table from 'common/components/Table/Table';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Button from 'common/components/Button/Button';

import useLocalizationModel from 'localizationModels/contexts/localization';
import SiteSelect from 'sites/components/SiteSelect';
import useSite from 'sites/contexts/sites';

const LocalizationModelsList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    fetchItems: fetchLocations, items: localizationModel, isFetching, pageCount,
    remove, filters, setFilter, update, itemsCount,
  } = useLocalizationModel();
  const { currentSite } = useSite();

  const handleDelete = useCallback(async (row) => {
    await remove(row.original.id);
    await fetchLocations();
  }, [remove, fetchLocations]);

  const handleToggle = useCallback(async (id) => {
    const template = localizationModel && localizationModel.find((p) => p.id === id);

    // eslint-disable-next-line no-restricted-syntax
    for (const item of localizationModel) {
      if (template.id !== item.id && item.active === true) {
        update(item.id, { ...item, active: false });
      }
    }
    await update(template.id, { ...template, active: true });
    await fetchLocations();
  }, [update, fetchLocations, localizationModel]);

  const handleEdit = useCallback((row) => router.push(`/localizationmodels/edit/${row.original.id}`), [router]);
  const handleAssign = useCallback((row) => router.push(`/assign-waiter-areas/${row.original.id}`), [router]);

  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      filterName: 'name_contains',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { id, name } } }) => (
        <span className="table-link" onClick={() => router.push(`/localizationmodels/edit/${id}`)}>
          {name}
        </span>
      ),
    },
    {
      Header: t('common.site'),
      filterName: 'category_item.site.id',
      hidden: !!currentSite,
      filterComponent: (props) => <SiteSelect isFilter {...props} />,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { category_item: category } } }) => (
        <span>
          {category?.site?.name}
        </span>
      ),
    },
    {
      Header: t('common.active'),
      accessor: 'active',
      Cell: ({ row: { original: { active, id } } }) => (
        <Button
          onClick={() => { if (!active) { handleToggle(id); } }}
          icon={active ? 'fa-check' : 'fa-times'}
          color={active ? 'success' : 'danger'}
          transparent
        />
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row, row: { original: { localizationModelField } } }) => (
        <>
          {localizationModelField?.length && localizationModelField?.find(
            (field) => field.required && field.type === 'select',
          ) && (
            <Button
              onClick={() => handleAssign(row)}
              icon="fa-map-marker"
              color="primary"
              transparent
            />
            )}
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDelete(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('localizationModels.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, handleDelete, handleEdit, router, currentSite, handleToggle, handleAssign]);

  return (
    <>
      <PageHeader
        title={t('menu.listLocalizationModels')}
        link={{
          url: '/localizationmodels/add',
          icon: 'plus',
          label: t('localizationModels.addLocalization'),
        }}
      />
      <section className="section list-page">
        <Table
          data={localizationModel}
          columns={columns}
          fetchData={fetchLocations}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          itemsCount={itemsCount}
        />
      </section>
    </>
  );
};

export default LocalizationModelsList;
