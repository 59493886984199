import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import useSite from 'sites/contexts/sites';
import SiteSelect from 'sites/components/SiteSelect';
import SeatSelect from 'sites/components/SeatSelect/SeatSelect';
import FormInput from 'common/components/FormInput/FormInput';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';

const BookingForm = ({ onSubmit, booking }) => {
  const { t } = useTranslation();
  const { currentSite } = useSite();

  const initialValues = useMemo(() => booking?.id
    ? {
      ...booking,
      seats: booking.seats.map(({ id }) => id),
      site: booking.site.id,
    }
    : {
      ...booking,
      site: currentSite ? currentSite.id : null,
      seats: booking?.seats ? booking.seats.map(({ id }) => id) : [],
      active: true,
      valid: true,
      payment: {
        online: false,
        offline_method: 'CASH',
        site: currentSite ? currentSite.id : null,
        status: 'PENDING',
      },
    }, [booking, currentSite]);

  if (currentSite && booking && booking.id && booking.site?.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  const paymentMethods = [{ value: 'CASH', label: t('bookings.CASH') }, { value: 'CB', label: t('bookings.CB') }];

  if (currentSite?.hotel) {
    paymentMethods.push({ value: 'ROOM', label: t('bookings.ROOM') });
  }

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      render={({
        handleSubmit, submitting, pristine, values,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormScrollError />
          <FormInput
            name="clientName"
            label={t('bookings.clientName')}
            icon="user"
            required
          />
          <FormInput
            name="description"
            label={t('common.description')}
            type="textarea"
          />
          { currentSite?.hotel && (
          <FormInput
            name="hotel"
            label={t('bookings.hotelCustomer')}
            type="checkbox"
          />
          )}

          { currentSite?.hotel && values.hotel && (
          <FormInput
            name="roomID"
            label={t('bookings.roomID')}
            icon="user"
            type="text"
          />
          )}

          <FormInput
            name="checkin"
            label={t('bookings.checkInTime')}
            type="datetime"
          />
          <FormInput
            name="phoneNumber"
            label={t('bookings.phoneNumber')}
            icon="phone"
            type="text"
          />
          {!currentSite && (
          <FormInput
            type="custom"
            name="site"
            label={t('common.site')}
            required
          >
            <SiteSelect />
          </FormInput>
          )}
          <FormInput type="date" icon="calendar" name="date" label={t('bookings.date')} required />
          <FormInput
            type="select"
            name="payment.offline_method"
            label={t('bookings.paymentMethod')}
            options={paymentMethods}
            required
          />
          <FormInput
            type="select"
            name="payment.status"
            data-cy="payment.status"
            label={t('bookings.paymentStatus')}
            options={[
              {
                value: 'PENDING',
                label: t('bookings.paymentPending'),
              }, {
                value: 'PAID',
                label: t('bookings.paymentPaid'),
              }]}
            required
          />
          {values.site && values.date ? (
            <FormInput
              label={t('bookings.seatsSelect')}
              type="custom"
              name="seats"
              multiple
              required
            >
              <SeatSelect
                date={moment(values.date).utc().startOf('day').unix()}
                site={values.site}
                additionnalOptions={booking?.id != null ? booking?.seats : []}
                multiple
              />
            </FormInput>
          ) : null}
          { booking?.id && (
          <>
            <FormInput
              type="checkbox"
              name="active"
              label={t('bookings.activate')}
            />
            <FormInput
              type="checkbox"
              name="valid"
              label={t('bookings.validate')}
            />
          </>
          ) }

          <FormInput
            type="submit"
            label={t('common.save')}
            icon="book-open"
            disabled={submitting || pristine}
          />

        </form>
      )}
    />
  );
};

BookingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  booking: PropTypes.object,
};

BookingForm.defaultProps = {
  booking: {},
};

export default BookingForm;
