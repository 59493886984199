import React, {
  useEffect, useCallback,
} from 'react';

import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import PageHeader from 'common/components/PageHeader/PageHeader';

import PageLoader from 'common/components/PageLoader/PageLoader';

const StripeReturnPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { id: siteId } = router.match.params;

  const redirectToEdit = useCallback(
    () => {
      router.push(`/sites/edit/${siteId}`);
    },
    [siteId, router],
  );

  useEffect(() => { redirectToEdit(); }, [redirectToEdit]);

  return (
    <>
      <PageHeader title={t('sites.stripeReturn')} />
      <PageLoader />
    </>
  );
};

export default StripeReturnPage;
