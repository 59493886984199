/* eslint-disable object-curly-newline */
import Button from 'common/components/Button/Button';
import FormInput from 'common/components/FormInput/FormInput';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';
import AlertsContext from 'common/contexts/alerts';
import fetchJSON from 'common/utils/fetchJSON';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import SiteSelect from 'sites/components/SiteSelect';
import useSite from 'sites/contexts/sites';

const FurnitureForm = ({ onSubmit, furniture, site }) => {
  const { t, i18n } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);
  const [priceAreas, setPriceAreas] = useState([]);
  const { setAlert } = useContext(AlertsContext);
  const { currentSite } = useSite();

  const initialValues = useMemo(() => {
    const values = furniture?.id ? {
      ...furniture,
      site: furniture?.site?.id,
    } : { prices: [], site, width: 50, height: 50 };

    if (priceAreas) {
      const prices = [];

      priceAreas.forEach((area) => {
        let price = values.prices.find((p) => p.price_area?.id === area.id);

        if (price) {
          price = {
            ...price,
            price_area: price.price_area.id,
          };
        } else {
          price = {
            price_area: area.id,
            price: null,
          };
        }
        prices.push(price);
      });
      values.prices = prices;
    }
    return values;
  }, [furniture, site, priceAreas]);

  useEffect(() => {
    const fetchItems = async () => {
      setIsFetching(true);
      try {
        const res = await fetchJSON({
          url: 'price-areas',
          method: 'GET',
        });

        setPriceAreas(res);
      } catch (e) {
        setAlert(e.message, 'danger');
      } finally {
        setIsFetching(false);
      }
    };

    fetchItems();
  }, [setAlert, setIsFetching, setPriceAreas]);

  // eslint-disable-next-line camelcase
  const defaultLang = currentSite ? currentSite.default_language : i18n.language;

  const handleTranslate = async (values, setFieldValue) => {
    let translated;
    const { name, description } = values;

    if (name && description) {
      translated = await fetchJSON({
        url: 'sites/translate',
        method: 'POST',
        payload: { items: { name, description }, site: currentSite.id },
      });

      setFieldValue({
        field: 'name',
        value: translated.name,
      });
      setFieldValue({
        field: 'description',
        value: translated.description,
      });
    }
  };

  if (currentSite && furniture && furniture.id && furniture.site?.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  if (isFetching) { return null; }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{
        setFieldValue: ([field], state, utils) => {
          utils.changeValue(state, field.field, () => field.value);
        },
      }}
      render={({
        values, handleSubmit, submitting, pristine, form,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Button
            style={{ marginBottom: '2rem' }}
            label="Translate"
            icon="fa-language"
            color="primary"
            confirm
            confirmMessage={t('common.translateConfirm')}
            onClick={() => handleTranslate(values, form.mutators.setFieldValue)}
          />
          <MultilingualInput
            name="name"
            label={t('common.name')}
            icon="heading"
            value={values.name}
            defaultLang={defaultLang}
            required
          />
          <MultilingualInput
            name="description"
            label={t('common.description')}
            icon="heading"
            defaultLang={defaultLang}
            required
            type="textarea"
          />
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '1rem' }}>
            { priceAreas.map((area, index) => (
              <div className="price-area mb-1" key={area.id}>
                <h2 className="label">
                  {area.name}
                </h2>

                <div className="row">
                  <FormInput
                    type="number"
                    name={`prices[${index}].price`}
                    icon="money-bill"
                    label={`${t('common.priceExterne')} (${currentSite?.currency})`}
                    required
                  />
                  { currentSite?.hotel && (
                  <FormInput
                    type="number"
                    name={`prices[${index}].hotelPrice`}
                    label={`${t('common.priceInterne')} (${currentSite?.currency})`}
                    icon="money-bill"
                    required
                  />
                )}
                </div>

              </div>
            ))}
          </div>
          <FormInput
            name="image"
            type="image"
            nbCols={5}
            maxWidth={500}
            maxHeight={500}
            required
          />

          <div className="row">
            <FormInput
              name="width"
              label={t('common.width')}
              required
              type="number"
            />
            <FormInput
              name="height"
              label={t('common.height')}
              required
              type="number"
            />
          </div>

          {/* double emploi avec le champs "image", semble pas utilisé
          <FormInput
            name="picture"
            type="image"
            keepRatio={false}
            nbCols={5}
            maxWidth={500}
            maxHeight={500}
                  /> */}
          {!currentSite && (
          <FormInput
            type="custom"
            name="site"
            label={t('common.site')}
            required
          >
            <SiteSelect />
          </FormInput>
          )}
          <FormInput
            type="submit"
            label={t(`furnitures.furniture${furniture && furniture.id ? 'Edit' : 'Add'}`)}
            icon="chair"
            disabled={submitting || pristine}
          />
        </form>
      )}
    />
  );
};

FurnitureForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  furniture: PropTypes.object,
  site: PropTypes.number,
};

FurnitureForm.defaultProps = {
  furniture: {},
  site: null,
};

export default FurnitureForm;
