import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import FormInput from 'common/components/FormInput/FormInput';

import useSite from 'sites/contexts/sites';
import SiteSelect from 'sites/components/SiteSelect';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';

import './AssignWaiterAreasForm.scss';

const AssignWaiterAreasForm = ({
  onSubmit, localizationModel, waiterAreas, preparatorAreas,
}) => {
  const { t } = useTranslation();
  const { currentSite } = useSite();

  if (currentSite
    && localizationModel
    && localizationModel.id
    && localizationModel.site?.id !== currentSite.id
    && waiterAreas
    && preparatorAreas) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        ...localizationModel,
      }}
      render={({
        handleSubmit,
        submitting,
        pristine,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormScrollError />
          <div>
            {waiterAreas && waiterAreas.length > 0 && (
              <h6 className="font-weight-bold">{t('localizationModels.assignWaiterAreas')}</h6>
            )}
            {waiterAreas && waiterAreas.length > 0 && waiterAreas.map((area, index) => (
              <div key={area.id + index} className="row area-section-row">
                <FormInput
                  type="checkbox"
                  name={`waiterAreas[${index}].active`}
                  label={area.name}
                />
                {localizationModel
                  && localizationModel.localizationModelField !== undefined
                  && localizationModel.localizationModelField.length > 0
                  && localizationModel.localizationModelField.map((field, indexField) => {
                    if (field.required) {
                      return (
                        <div key={field.label + field.id + indexField}>
                          <FormInput
                            type="select"
                            name={`waiterAreas[${index}].localizationModelField[${indexField}].${field.label}`}
                            label={field.label}
                            multiple
                            options={field?.SubItems.map((item) => (
                              { value: item.name, label: item.name }
                            ))}
                            placeholder={t('common.applyAll')}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
              </div>
            ))}
          </div>

          <div className="preparator-area-section">
            {preparatorAreas && preparatorAreas.length > 0 && (
              <h6 className="font-weight-bold">{t('localizationModels.assignPreparatorAreas')}</h6>
            )}
            {preparatorAreas && preparatorAreas.length > 0 && preparatorAreas.map((area, index) => (
              <div key={area.id + index} className="row area-section-row">
                <FormInput
                  type="checkbox"
                  name={`preparatorAreas[${index}].active`}
                  label={area.name}
                />
                {localizationModel
                  && localizationModel.localizationModelField !== undefined
                  && localizationModel.localizationModelField.length > 0
                  && localizationModel.localizationModelField.map((field, indexField) => {
                    if (field.required) {
                      return (
                        <div key={field.label + field.id + indexField}>
                          <FormInput
                            type="select"
                            name={`preparatorAreas[${index}].localizationModelField[${indexField}].${field.label}`}
                            label={field.label}
                            multiple
                            options={field?.SubItems.map((item) => (
                              { value: item.name, label: item.name }
                            ))}
                            placeholder={t('common.applyAll')}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
              </div>
            ))}
          </div>

          <div className="is-size-5 my-2">
            {t('localizationModels.assignationExplanation')}
          </div>

          {!currentSite && (
            <FormInput
              type="custom"
              name="site"
              label={t('common.site')}
              required
            >
              <SiteSelect />
            </FormInput>
          )}
          <FormInput
            type="submit"
            label={t('common.save')}
            icon="map-marker-alt"
            disabled={submitting || pristine}
          />
        </form>
      )}
    />
  );
};

AssignWaiterAreasForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  localizationModel: PropTypes.object,
  waiterAreas: PropTypes.array,
  preparatorAreas: PropTypes.array,
};

AssignWaiterAreasForm.defaultProps = {
  localizationModel: {},
  waiterAreas: [],
  preparatorAreas: [],
};

export default AssignWaiterAreasForm;
