/* eslint-disable react/prop-types */
import React, { useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from 'common/hooks/use-router';
import Table from 'common/components/Table/Table';
import Button from 'common/components/Button/Button';
import PageHeader from 'common/components/PageHeader/PageHeader';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';

import useCategory from 'categories/contexts/categories';
import CategorySelect from 'categories/components/CategorySelect';
import SiteSelect from 'sites/components/SiteSelect';
import useSite from 'sites/contexts/sites';

const CategoriesList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { currentSite } = useSite();
  const {
    fetchItems: fetchCategories, items: categories, isFetching, pageCount, remove, filters, setFilter,
    itemsCount, setSort,
  } = useCategory();

  const handleDelete = useCallback(async (row) => {
    await remove(row.original.id);
    fetchCategories();
  }, [remove, fetchCategories]);

  const handleEdit = useCallback((row) => router.push(`/categories/edit/${row.original.id}`), [router]);

  useEffect(() => {
    setSort('ordering:ASC');
  }, [setSort]);
  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      filterName: 'name_contains',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { id, name, ordering } } }) => (
        <span className="table-link" onClick={() => router.push(`/categories/edit/${id}`)}>
          {ordering && (
            <strong style={{ marginRight: 4 }}>
              { ordering }
              {' '}
              -
            </strong>
          ) }
          <TranslatedText value={name} />
        </span>
      ),
    },
    {
      Header: t('common.reference'),
      accessor: 'reference',
      filterName: 'reference_contains',
    },
    {
      Header: t('common.parent'),
      filterName: 'parent.id',
      filterComponent: (props) => <CategorySelect isFilter {...props} />,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { parent } } }) => (
        <span>
          {parent && <TranslatedText value={parent?.name} />}
        </span>
      ),
    },
    {
      Header: t('common.site'),
      filterName: 'site.id',
      hidden: !!currentSite,
      filterComponent: (props) => <SiteSelect isFilter {...props} />,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { site } } }) => (
        <span>
          {site?.name}
        </span>
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <>
          {/* eslint-disable-next-line react/prop-types */}
          {/* row?.original?.ObjectNumber && (
          <Tooltip content={translateText('common.categoryLinkedToPos')}>
            <span className="icon">
              <i className="fas fa-desktop" />
            </span>
          </Tooltip>
          ) */}
          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleDelete(row)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('categories.confirmDelete')}
          />
        </>
      ),
    },
  ], [t, currentSite, router, handleEdit, handleDelete]);

  const extraLink = currentSite
    ? { url: '/import-categories', icon: 'file-import', label: t('categories.importCategories') }
    : null;

  return (
    <>
      <PageHeader
        title={t('menu.listCategories')}
        link={{ url: '/categories/add', icon: 'tag', label: t('categories.addCategory') }}
        extraLink={extraLink}
      />
      <section className="section list-page">
        <Table
          data={categories}
          columns={columns}
          fetchData={fetchCategories}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          itemsCount={itemsCount}
        />
      </section>
    </>
  );
};

export default CategoriesList;
