import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const PageHeader = ({
  title, link, subLink, extraLink,
}) => (
  <header className="page-header">
    <div className="page-header--title-area">
      <h2 className="page-header--title title is-size-5">{title}</h2>
      { subLink && (
      <span className="sublink">
        <Link to={subLink.url}>
          <span className="icon">
            <i className={`fas fa-${subLink.icon}`} />
          </span>
          <span>{subLink.label}</span>
        </Link>
      </span>
      ) }
    </div>
    {extraLink && (
      <div className="page-header--link">
        <Link as="button" to={extraLink.url}>
          <span className="page-header--link-label">{extraLink.label}</span>
          <span className="icon page-header--link-button button is-light">
            <i className={`fas fa-${extraLink.icon}`} />
          </span>
        </Link>
      </div>
    )}
    {link && (
      <div className="page-header--link">
        <Link as="button" to={link.url}>
          <span className="page-header--link-label">{link.label}</span>
          <span className="icon page-header--link-button button is-light">
            <i className={`fas fa-${link.icon}`} />
          </span>
        </Link>
      </div>
    )}
  </header>
);

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.any,
  subLink: PropTypes.object,
  extraLink: PropTypes.any,
};

PageHeader.defaultProps = {
  link: null,
  subLink: null,
  extraLink: null,
};

export default PageHeader;
