import Button from 'common/components/Button/Button';
import FormInput from 'common/components/FormInput/FormInput';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import Tabs, { Tab } from 'common/components/Tabs/Tabs';
import useAuth from 'common/contexts/auth';
import fetchJSON from 'common/utils/fetchJSON';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import useSite from 'sites/contexts/sites';
import SiteCompanyForm from './SiteCompanyForm';
import SitePresentationForm from './SitePresentationForm';
import SiteStoreForm from './SiteStoreForm';
import SiteVocabularyForm from './SiteVocabularyForm';
import SiteStatsForm from './SiteStatsForm';

const SiteForm = ({ onSubmit, site }) => {
  const { t/* , i18n: { language } */ } = useTranslation();
  const { currentSite } = useSite();
  const { user } = useAuth();

  /* const weekdays = useMemo(() => (
    Array.from({ length: 7 }, (_, i) => ({
      key: `${moment(i, 'e').locale('en').format('dddd')}`.toLowerCase(),
      label: `${moment(i, 'e').locale(language).format('dddd')}`,
    }))
  ), [language]); */

  const handleTranslate = async (values, setFieldValue) => {
    let translated;
    const { description } = values;

    if (description) {
      translated = await fetchJSON({
        url: 'sites/translate',
        method: 'POST',
        payload: { items: { description }, site: currentSite.id },
      });

      setFieldValue({
        field: 'description',
        value: translated.description,
      });
    }
  };

  if (currentSite && site && site.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  // FIX : au 1er lancement, site.vat est un ID, mais si on enregistre sans toucher "values.vat", site.vat devient un {}
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={site?.id ? {
        ...site,
        vat: site.vat.id || site.vat || null,
        pleaseMyCar: true,
      } : {
        instagramShow: true,
        pleaseMyCar: true,
      }}
      mutators={{
        // eslint-disable-next-line no-unused-vars
        // ne semble plus utilisé
        /* setGeolocation: ([_, geo], state, utils) => {
          utils.changeValue(state, 'lat', () => geo && geo.location?.lat());
          utils.changeValue(state, 'lng', () => geo && geo.location?.lng());
        }, */
        setFieldValue: ([field], state, utils) => {
          utils.changeValue(state, field.field, () => field.value);
        },
      }}
      render={({
        form,
        handleSubmit,
        submitting,
        pristine,
        values,
      }) => {
        const tabs = [
          {
            key: 'company',
            label: t('sites.companyInformation'),
            children: <SiteCompanyForm
              onSubmit={onSubmit}
              site={site}
              // changeSetGeolocation={form.mutators.setGeolocation}
            />,
          },
          {
            key: 'site',
            label: t('sites.siteInformation'),
            children: <SitePresentationForm
              onSubmit={onSubmit}
              site={site}
              values={values}
            />,
          },
          {
            key: 'store',
            label: t('sites.storeInformation'),
            children: <SiteStoreForm onSubmit={onSubmit} site={site} values={values} user={user} />,
          },
          {
            key: 'vocabulary',
            label: t('vocabulary.siteVocabulary'),
            children: <SiteVocabularyForm onSubmit={onSubmit} site={site} values={values} user={user} />,
          },
          {
            key: 'stats',
            label: t('menu.stats'),
            children: <SiteStatsForm onSubmit={onSubmit} site={site} values={values} user={user} />,
          },
        ];

        return (
          <form onSubmit={handleSubmit} noValidate>
            <FormScrollError />
            <Button
              style={{ marginBottom: '2rem' }}
              label="Translate"
              icon="fa-language"
              color="primary"
              confirm
              confirmMessage={t('common.translateConfirm')}
              onClick={() => handleTranslate(values, form.mutators.setFieldValue)}
            />

            <Tabs>
              {tabs.map((tab) => (
                <Tab {...tab}>
                  {tab.children}
                </Tab>
              ))}
            </Tabs>

            <FormInput
              type="submit"
              label={t('common.save')}
              icon="map-pin"
              disabled={submitting || pristine}
            />

          </form>
        );
      }}
    />
  );
};

SiteForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  site: PropTypes.object,
};

SiteForm.defaultProps = {
  site: {},
};

export default SiteForm;
