import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import FormInput from 'common/components/FormInput/FormInput';
import MultilingualInput from 'common/components/MultilingualInput/MultilingualInput';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import useSite from 'sites/contexts/sites';
import SiteSelect from 'sites/components/SiteSelect';
import CategorySelect from 'categories/components/CategorySelect';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import fetchJSON from 'common/utils/fetchJSON';
import Button from 'common/components/Button/Button';

const processCategoryOptions = (options) => options.filter(({ parent }) => parent === null)
  .map(({ id, name }) => ({ value: id, label: <TranslatedText value={name} /> }));

const CategoryForm = ({ onSubmit, category }) => {
  const { t, i18n } = useTranslation();
  const [children, setChildren] = useState([]);
  const { currentSite } = useSite();

  const handleOnDataLoaded = useCallback((categories) => {
    if (category && category.id) {
      setChildren(categories.find(({ parent }) => parent && category.id === parent.id));
    }
  }, [category]);

  if (currentSite && category && category.id && category.site?.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  const initialValues = category?.id
    ? {
      ...category,
      site: category?.site?.id,
      parent: category?.parent?.id,
    }
    : { site: currentSite ? currentSite.id : null };

  // eslint-disable-next-line camelcase
  const defaultLang = category ? category.site?.default_language : (
    currentSite ? currentSite.default_language : i18n.language);

  const handleTranslate = async (values, setFieldValue) => {
    let translated;
    const { name } = values;

    if (name) {
      translated = await fetchJSON({
        url: 'sites/translate',
        method: 'POST',
        payload: { items: { name }, site: currentSite.id },
      });

      setFieldValue({
        field: 'name',
        value: translated.name,
      });
    }
  };

  return (
    <>
      {initialValues.reference_category && (
        <p style={{ marginBottom: 10, fontStyle: 'italic' }}>
          {`${t('categories.initialReference')} : `}
          <TranslatedText value={initialValues.reference_category.name} />
        </p>
      )}
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{
          setFieldValue: ([field], state, utils) => {
            utils.changeValue(state, field.field, () => field.value);
          },
        }}
        render={({
          values, handleSubmit, submitting, pristine, form,
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <FormScrollError />

            <div className="columns">
              <div className="column">
                <Button
                  style={{ marginBottom: '2rem' }}
                  label="Translate"
                  icon="fa-language"
                  color="primary"
                  confirm
                  confirmMessage={t('common.translateConfirm')}
                  onClick={() => handleTranslate(values, form.mutators.setFieldValue)}
                />
                <MultilingualInput
                  name="name"
                  label={t('common.name')}
                  icon="heading"
                  value={values.name}
                  defaultLang={defaultLang}
                  required
                />
              </div>
              <div className="column is-one-third">
                <FormInput
                  name="image"
                  type="image"
                  nbCols={5}
                  maxWidth={800}
                  maxHeight={800}
                />
              </div>
            </div>
            <FormInput
              name="reference"
              label={t('common.reference')}
              icon="tag"
            />
            <div className="row">
              <FormInput
                type="custom"
                name="parent"
                label={t('common.parent')}
              >
                <CategorySelect
                  id_ne={category?.id}
                  site_null={0}
                  disabled={children && children.length !== 0}
                  onDataLoaded={handleOnDataLoaded}
                  processOptions={processCategoryOptions}
                />
              </FormInput>
              <FormInput
                type="number"
                name="ordering"
                label={t('common.index')}
              />

            </div>

            {!currentSite && (
            <FormInput
              type="custom"
              name="site"
              label={t('common.site')}
              required
            >
              <SiteSelect />
            </FormInput>
            )}
            <FormInput
              type="submit"
              label={t('common.save')}
              icon="tag"
              disabled={submitting || pristine}
            />
          </form>
        )}
      />
    </>
  );
};

CategoryForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  category: PropTypes.object,
};

CategoryForm.defaultProps = {
  category: {},
};

export default CategoryForm;
