/**
 * @param {File} image - Image File Object
 * @param {Object} crop - crop Object
 * @param {String} fileName - Name of the returned file in Promise
 */
export const getCroppedImg = async (image, fileType, crop, fileName, maxWidth, maxHeight) => {
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  let dstWidth = crop.width * scaleX;

  let dstHeight = crop.height * scaleY;

  if (dstWidth > maxWidth) {
    const ratio = maxWidth / dstWidth;

    dstWidth = maxWidth;
    dstHeight *= ratio;
  }

  if (dstHeight > maxHeight) {
    const ratio = maxHeight / dstHeight;

    dstHeight = maxHeight;
    dstWidth *= ratio;
  }

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = dstWidth;
  canvas.height = dstHeight;

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    dstWidth,
    dstHeight,
  );

  return new Promise((resolve) => canvas.toBlob((blob) => resolve(new File([blob], fileName)), fileType, 0.8));
};

export default getCroppedImg;
