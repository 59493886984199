import logo from 'assets/logo.png';
import { useAuth } from 'common/contexts/auth';
import MenuContext from 'common/contexts/menu';
import useTrans from 'common/hooks/use-trans';
import { createUploadUrl } from 'common/utils/createUrl';
import getUserDisplayName from 'common/utils/getUserDisplayName';
import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import SiteSelect from 'sites/components/SiteSelect';
import useSite from 'sites/contexts/sites';
import TranslatedText from '../TranslatedText/TranslatedText';

const Menu = () => {
  const { t } = useTranslation();
  const { menuActive } = useContext(MenuContext);
  const { user, signout } = useAuth();
  const {
    item: site, fetchItem: fetchSite, currentSite, selectCurrentSite,
  } = useSite();
  const { translateText } = useTrans();

  const handleSiteChange = useCallback((id, row) => selectCurrentSite(row?.site || null), [selectCurrentSite]);

  useEffect(() => { fetchSite(currentSite?.id); }, [fetchSite, currentSite]);

  if (!user || !user.role) {
    return null;
  }

  return (
    <aside data-test="component-aside" className={`menu is-dark ${menuActive && 'is-open'}`}>
      <div className="menu-header">
        <div className="menu-user">
          {user && (
            <>
              <button className="menu-user--logout button is-danger is-transparent" onClick={() => signout()}>
                <span className="icon">
                  <i className="fas fa-power-off" />
                </span>
              </button>
              <Link to="/profile" className="menu-user--name">
                <span className="ml has-text-weight-bold">{getUserDisplayName(user)}</span>
                <span className="icon">
                  <i className="fas fa-user" />
                </span>
              </Link>
            </>
          )}
        </div>
        <Link
          className="menu-brand"
          to="/"
          style={{
            backgroundImage: `url(${currentSite?.company_logo?.url
              ? createUploadUrl(currentSite.company_logo.url) : logo})`,
          }}
        />
        <div className="menu-header--mask" />
      </div>
      {user?.role.name === 'superadmin' && (
        <div className="menu-beach-select">
          <SiteSelect onChange={handleSiteChange} value={currentSite?.id} placeholder={t('menu.selectSite')} />
        </div>
      )}
      <div className="menu-container">
        {currentSite && site?.mapIsActive && (
          <>
            <p className="menu-label">
              <span className="icon">
                <i className="fas fa-tachometer-alt" />
              </span>
              <span>{t('menu.adminMonitor')}</span>
            </p>
            <ul className="menu-list">
              {site?.mapIsActive
                ? currentSite?.maps && currentSite?.maps.length > 0 ? currentSite?.maps.map((map) => (
                  <React.Fragment key={map.id}>
                    <li>
                      <Link to={`/sites/monitor/${map.id}`}>
                        <span className="icon mr">
                          <i className="fas fa-chart-bar" />
                        </span>
                        <span><TranslatedText value={map.name} /></span>
                      </Link>
                    </li>
                  </React.Fragment>
                )) : (
                  <li>
                    <Link to="/maps/add">
                      <span className="icon mr">
                        <i className="fas fa-plus-circle icon-emphasis" />
                      </span>
                      <span>{t('menu.adminFirstMap')}</span>
                    </Link>
                  </li>
                ) : null}
            </ul>
          </>
        )}
        <p className="menu-label">
          <span className="icon">
            <i className="fas fa-book-open" />
          </span>
          <span>{t('menu.business')}</span>
        </p>
        <ul className="menu-list">
          <li>
            {(user?.role.name === 'admin'
              || user?.role.name === 'manager'
              || (user?.role.name === 'superadmin' && currentSite?.id)) && (
                <>
                  {/* { site?.mapIsActive && (user?.role.name === 'reception'
               || user?.role.name === 'admin'
                || (user?.role.name === 'superadmin' && currentSite?.id)) && (
                <Link to="/bookings">
                  <span className="icon mr">
                    <i className="fas fa-book-open" />
                  </span>
                  <span>{t('menu.bookings')}</span>
                </Link>
               ) } */}
                  {(user?.role.name === 'admin' || (user?.role.name === 'superadmin' && currentSite?.id))
                    && (
                      <Link to="/stats">
                        <span className="icon mr">
                          <i className="fas fa-chart-line" />
                        </span>
                        <span>{t('menu.stats')}</span>
                      </Link>
                    )}
                  {(user?.role.name === 'admin' || user?.role.name === 'manager'
                    || (user?.role.name === 'superadmin' && currentSite?.id)) && (
                      <>
                        <Link to="/active-orders">
                          <span className="icon mr">
                            <i className="fas fa-barcode" />
                          </span>
                          <span>{t('menu.activesListOrders')}</span>
                        </Link>
                        <Link to="/archived-orders">
                          <span className="icon mr">
                            <i className="fas fa-barcode" />
                          </span>
                          <span>{t('menu.archivedListOrders')}</span>
                        </Link>
                        <Link to="/special-offers">
                          <span className="icon mr">
                            <i className="fas fa-gift" />
                          </span>
                          <span>{t('menu.specialOffers')}</span>
                        </Link>
                      </>
                  )}
                </>
            )}
          </li>
        </ul>
        {(user?.role.name === 'admin'
          || user?.role.name === 'manager'
          || (user?.role.name === 'superadmin' && currentSite?.id)) && (
            <>
              <p className="menu-label">
                <span className="icon">
                  <i className="fas fa-utensils" />
                </span>
                <span>{t('menu.products')}</span>
              </p>
              <ul className="menu-list">
                <li>
                  <Link to="/products">
                    <span className="icon mr">
                      <i className="fas fa-pizza-slice" />
                    </span>
                    <span>{t('menu.listProducts')}</span>
                  </Link>
                  <Link to="/categories">
                    <span className="icon mr">
                      <i className="fas fa-folder-open" />
                    </span>
                    <span>{t('menu.listCategories')}</span>
                  </Link>
                  { /* <Link to="/services">
                  <span className="icon mr">
                    <i className="fas fa-water" />
                  </span>
                  <span>{t('menu.listServices')}</span>
                </Link>
                <Link to="/categoryServices">
                  <span className="icon mr">
                    <i className="fas fa-folder-open" />
                  </span>
                  <span>{t('menu.listCategoryServices')}</span>
        </Link> */ }
                  <Link to="/options">
                    <span className="icon mr">
                      <i className="fas fa-funnel-dollar" />
                    </span>
                    <span>{t('menu.listOptions')}</span>
                  </Link>
                </li>
              </ul>
              {/* <p className="menu-label">
              <span className="icon">
                <i className="fas fa-globe-europe" />
              </span>
              <span>{t('menu.locations')}</span>
            </p>
            <ul className="menu-list">
              <li>
                <Link to="/aroundme">
                  <span className="icon mr">
                    <i className="fas fa-map-marker-alt" />
                  </span>
                  <span>{t('menu.listLocations')}</span>
                </Link>
                <Link to="/category-items">
                  <span className="icon mr">
                    <i className="fas fa-folder-open" />
                  </span>
                  <span>{t('menu.listCategoryItems')}</span>
                </Link>
              </li>
            </ul>
      */ }
            </>
        )}
        {(user?.role.name === 'admin' || (user?.role.name === 'superadmin' && currentSite?.id)) && (
          <>
            <p className="menu-label">
              <span className="icon">
                <i className="fas fa-lock" />
              </span>
              <span>{t('menu.adminForms')}</span>
            </p>

            <ul className="menu-list">
              <li>
                <Link to="/localizationmodels">
                  <span className="icon mr">
                    <i className="fas fa-map-marker-alt" />
                  </span>
                  <span>{t('menu.listLocalizationModels')}</span>
                </Link>
              </li>
              <li>
                <Link to="/hour-slot-model">
                  <span className="icon mr">
                    <i className="fas fa-clock" />
                  </span>
                  <span>{t('menu.listHourSlotModel')}</span>
                </Link>
              </li>
            </ul>

            <p className="menu-label">
              <span className="icon">
                <i className="fas fa-lock" />
              </span>
              <span>{t('menu.admin')}</span>
            </p>
            <ul className="menu-list">
              {site?.mapIsActive ? currentSite?.maps
                && currentSite.maps.length > 0 ? currentSite.maps.map((map) => (
                  <React.Fragment key={map.id}>
                    <li>
                      <Link to={`/sites/organize/${map.id}`}>
                        <span className="icon mr">
                          <i className="fas fa-chart-bar" />
                        </span>
                        <span><span>{t('menu.adminOrganize', { mapName: translateText(map.name) })}</span></span>
                      </Link>
                    </li>
                  </React.Fragment>
                )) : (
                  <li>
                    <span className="no-link">
                      <span className="icon mr">
                        <i className="fas fa-ban" />
                      </span>
                      <span style={{ opacity: '0.5' }}>{t('menu.noMapCreated')}</span>
                    </span>
                  </li>
              ) : <></>}
              {site?.mapIsActive
                ? currentSite?.maps?.length > 0 ? (
                  <li>
                    <Link to="/maps">
                      <span className="icon mr">
                        <i className="fas fa-map" />
                      </span>
                      <span>{t('menu.adminMaps')}</span>
                    </Link>
                  </li>
                ) : (
                  <li>
                    <Link to="/maps/add">
                      <span className="icon mr">
                        <i className="fas fa-plus-circle icon-emphasis" />
                      </span>
                      <span>{t('menu.adminFirstMap')}</span>
                    </Link>
                  </li>
                ) : <></>}
              <li>
                <Link to={`/sites/edit/${currentSite?.id}`}>
                  <span className="icon mr">
                    <i className="fas fa-home" />
                  </span>
                  <span>{t('menu.editSite')}</span>
                </Link>
              </li>
              <li>
                <Link to="/templates">
                  <span className="icon mr">
                    <i className="fas fa-palette" />
                  </span>
                  <span>{t('menu.handleTemplate')}</span>
                </Link>
              </li>  
              <li>
                <Link to="/waiter-areas">
                  <span className="icon mr">
                    <i className="fas fa-running" />
                  </span>
                  <span>{t('menu.listWaiterAreas')}</span>
                </Link>
              </li>
              <li>
                <Link to="/preparator-areas">
                  <span className="icon mr">
                    <i className="fas fa-utensils" />
                  </span>
                  <span>{t('menu.listPreparatorAreas')}</span>
                </Link>
              </li>
            
              <li>
                <Link to="/users">
                  <span className="icon mr">
                    <i className="fas fa-users" />
                  </span>
                  <span>{t('menu.adminEmployees')}</span>
                </Link>
              </li>

              { /*
              <li>
                <Link to="/pos">
                <span className="icon mr">
                  <i className="fas fa-desktop" />
                </span>
                <span>{t('menu.pos')}</span>
              </Link>
              </li> */ }
              <li>
                <Link to="/watches">
                  <span className="icon mr">
                    <i className="fas fa-link" />
                  </span>
                  <span>{t('menu.watches')}</span>
                </Link>
              </li>
            </ul>
          </>
        )}
        {user && user.role.name === 'superadmin' && currentSite === null && (
          <>
            <p className="menu-label">
              <span className="icon">
                <i className="fas fa-globe-europe" />
              </span>
              <span>{t('menu.referenceCatalog')}</span>
            </p>
            <ul className="menu-list">
              <li>
                <Link to="/catalog-products">
                  <span className="icon mr">
                    <i className="fas fa-pizza-slice" />
                  </span>
                  <span>{t('menu.listCatalogProducts')}</span>
                </Link>
                <Link to="/catalog-categories">
                  <span className="icon mr">
                    <i className="fas fa-tag" />
                  </span>
                  <span>{t('menu.listCatalogCategories')}</span>
                </Link>
                <Link to="/catalog-options">
                  <span className="icon mr">
                    <i className="fas fa-funnel-dollar" />
                  </span>
                  <span>{t('menu.listCatalogOptions')}</span>
                </Link>
              </li>
            </ul>
            <p className="menu-label">
              <span className="icon">
                <i className="fas fa-lock" />
              </span>
              <span>{t('menu.admin')}</span>
            </p>
            <ul className="menu-list">
              <li>
                <Link to="/users">
                  <span className="icon mr">
                    <i className="fas fa-users" />
                  </span>
                  <span>{t('menu.listUsers')}</span>
                </Link>
                {user && user.role.name === 'superadmin' && (
                  <>
                    <Link to="/sites">
                      <span className="icon mr">
                        <i className="fas fa-map-marker-alt" />
                      </span>
                      <span>{t('menu.listSites')}</span>
                    </Link>
                    <Link to="/vat">
                      <span className="icon mr">
                        <i className="fas fa-percentage" />
                      </span>
                      <span>{t('menu.listVats')}</span>
                    </Link>
                    <Link to="/reports">
                      <span className="icon mr">
                        <i className="fas fa-flag" />
                      </span>
                      <span>{t('menu.listReports')}</span>
                    </Link>
                  </>
                )}
              </li>
            </ul>
          </>
        )}
      </div>
    </aside>
  );
};

export default withRouter(Menu);
