import FormInput from 'common/components/FormInput/FormInput';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSite from 'sites/contexts/sites';

const TemplateGeneralForm = ({ template }) => {
  const { t } = useTranslation();
  const { currentSite } = useSite();

  // TEMPORARY ARRAY
  const fontArrayTitles = [
    { value: 'Lato', label: <span style={{ fontFamily: 'Lato' }}>Lato</span> },
    { value: 'LatoBold', label: <span style={{ fontFamily: 'LatoBold' }}>Lato Bold</span> },
    { value: 'Avenir', label: <span style={{ fontFamily: 'Avenir' }}>Avenir</span> },
    { value: 'AvenirBold', label: <span style={{ fontFamily: 'AvenirBold' }}>Avenir Bold</span> },
    { value: 'Roboto', label: <span style={{ fontFamily: 'Roboto' }}>Roboto</span> },
    { value: 'RobotoBold', label: <span style={{ fontFamily: 'RobotoBold' }}>Roboto Bold</span> },
    { value: 'RobotoCondensed', label: <span style={{ fontFamily: 'RobotoCondensed' }}>Roboto Condensed</span> },
    { value: 'Montserrat', label: <span style={{ fontFamily: 'Montserrat' }}>Montserrat</span> },
    { value: 'MontserratBold', label: <span style={{ fontFamily: 'MontserratBold' }}>MontserratBold</span> },
    { value: 'Bebas', label: <span style={{ fontFamily: 'Bebas' }}>Bebas Neue</span> },
    { value: 'Sniglet', label: <span style={{ fontFamily: 'Sniglet' }}>Sniglet</span> },
    { value: 'Rubik', label: <span style={{ fontFamily: 'Rubik' }}>Rubik</span> },
    { value: 'PalanquinDark', label: <span style={{ fontFamily: 'PalanquinDark' }}>Palanquin Dark</span> },
    { value: 'AbrilFatface', label: <span style={{ fontFamily: 'AbrilFatface' }}>AbrilFatface</span> },
    { value: 'ZillaSlab', label: <span style={{ fontFamily: 'ZillaSlab' }}>ZillaSlab</span> },
    { value: 'ZillaSlabBold', label: <span style={{ fontFamily: 'ZillaSlabBold' }}>ZillaSlab Bold</span> },
  ];

  const fontArrayBasics = [
    { value: 'Lato', label: <span style={{ fontFamily: 'Lato' }}>Lato</span> },
    { value: 'Avenir', label: <span style={{ fontFamily: 'Avenir' }}>Avenir</span> },
    { value: 'Roboto', label: <span style={{ fontFamily: 'Roboto' }}>Roboto</span> },
    { value: 'Montserrat', label: <span style={{ fontFamily: 'Montserrat' }}>Montserrat</span> },
    { value: 'Sniglet', label: <span style={{ fontFamily: 'Sniglet' }}>Sniglet</span> },
    { value: 'ZillaSlab', label: <span style={{ fontFamily: 'ZillaSlab' }}>ZillaSlab</span> },
  ];

  if (currentSite && template && template.site.id !== currentSite.id) {
    return (<h1>{t('common.notAllowed')}</h1>);
  }

  return (
    <>
      <section className="section" style={{ marginTop: 20 }}>
        <div className="row">
          <FormInput
            label={t('template.logo')}
            name="logo"
            type="image"
            maxWidth={800}
            maxHeight={800}
          />
          <FormInput
            label={t('template.name')}
            name="name"
            type="text"
          />
          <FormInput
            label={t('common.active')}
            name="active"
            type="checkbox"
          />
        </div>
      </section>

      <section className="section" style={{ marginTop: 20, paddingTop: 20, borderTop: '1px solid #ddd' }}>
        <h2 className="subtitle">{t('template.tendency')}</h2>
        <div className="row">
          <FormInput
            label={t('template.imageGalery')}
            name="image"
            type="image"
            maxWidth={800}
            maxHeight={1200}
          />
          <FormInput
            label={t('template.imageBackdrop')}
            name="imageBackdrop"
            type="image"
            maxWidth={800}
            maxHeight={1200}
          />
          <FormInput
            label={t('template.backdropTextColor')}
            name="backdropTextColor"
            type="color"
          />
        </div>
      </section>

      <section className="section" style={{ marginTop: 20, paddingTop: 20, borderTop: '1px solid #ddd' }}>
        <h2 className="subtitle">{t('template.primary')}</h2>
        <div className="row">
          <FormInput
            label={t('template.primaryColor')}
            name="primaryColor"
            type="color"
          />
          <FormInput
            label={t('template.primaryShadeColor')}
            name="primaryShadeColor"
            type="color"
          />
          <FormInput
            label={t('template.primaryTextColor')}
            name="primaryTextColor"
            type="color"
          />
        </div>
      </section>

      <section className="section" style={{ marginTop: 20, paddingTop: 20, borderTop: '1px solid #ddd' }}>
        <h2 className="subtitle">{t('template.secondary')}</h2>
        <div className="row">
          <FormInput
            label={t('template.secondaryColor')}
            name="secondaryColor"
            type="color"
          />
          <FormInput
            label={t('template.secondaryShadeColor')}
            name="secondaryShadeColor"
            type="color"
          />
          <FormInput
            label={t('template.secondaryTextColor')}
            name="secondaryTextColor"
            type="color"
          />
        </div>
      </section>

      <section className="section" style={{ marginTop: 20, paddingTop: 20, borderTop: '1px solid #ddd' }}>
        <h2 className="subtitle">{t('template.texts')}</h2>
        <div className="row">
          <FormInput
            label={t('template.titleFont')}
            name="titleFont"
            type="select"
            options={fontArrayTitles}
          />
          <FormInput
            label={t('template.subtitleFont')}
            name="subtitleFont"
            type="select"
            options={fontArrayTitles}
          />
          <FormInput
            label={t('template.primaryFont')}
            name="primaryFont"
            type="select"
            options={fontArrayBasics}
          />
          <FormInput
            label={t('template.cardTextColor')}
            name="cardTextColor"
            type="color"
          />
        </div>
      </section>
    </>
  );
};

TemplateGeneralForm.propTypes = {
  site: PropTypes.object,
};

TemplateGeneralForm.defaultProps = {
  site: {},
};

export default TemplateGeneralForm;
