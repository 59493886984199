import React, {
  useEffect, useContext, useCallback,
} from 'react';

import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';

import FormScrollError from 'common/components/FormScrollError/FormScrollError';

import useRouter from 'common/hooks/use-router';
import AlertsContext from 'common/contexts/alerts';
import PageHeader from 'common/components/PageHeader/PageHeader';
import PageLoader from 'common/components/PageLoader/PageLoader';
import useSite from 'sites/contexts/sites';
import FormInput from 'common/components/FormInput/FormInput';
import TemplateGeneralForm from './TemplateGeneralForm';
import TemplateProductForm from './TemplateProductForm';
import TemplateCategoriesForm from './TemplateCategoriesForm';
import useTemplate from '../../context/template';
import { Tab, Tabs } from '../../../common/components/Tabs/Tabs';

const TemplateFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { currentSite, item: site } = useSite();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, fetchItem: fetchTemplate, item: template, fetchItems: fetchTemplates, items: templates, isFetching,
  } = useTemplate();
  const { id: templateId } = router.match.params;

  useEffect(() => { fetchTemplate(templateId); fetchTemplates(); }, [fetchTemplate, fetchTemplates, templateId]);

  const onSubmit = useCallback(async (template) => {
    if (template.id) {
      if (template.active) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of templates) {
          update(item.id, { ...item, active: false });
        }
      }
      const res = await update(template.id, template);

      if (!res.error) {
        setAlert(t('sites.successEdit'), 'success');
        router.push('/templates');
      } else {
        setAlert(res.message, 'danger');
      }
    } else {
      if (template.active) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of templates) {
          update(item.id, { ...item, active: false });
        }
      }
      const res = await save(template);

      if (!res.error) {
        setAlert(t('sites.successAdd'), 'success');
        router.push(`/templates/edit/${res.id}`);
      } else {
        setAlert(res.message, 'danger');
      }
    }
  }, [update, templates, setAlert, t, router, save]);

  return (
    <>
      <PageLoader processing={isFetching} />
      <PageHeader
        title={t(`template.${template ? 'edit' : 'add'}Template`)}
        subLink={{ url: '/templates', icon: 'arrow-left', label: t('common.back') }}
      />
      <section className="section form-page" style={{ paddingBottom: 20 }}>

        <Form
          onSubmit={onSubmit}
          initialValues={
            template?.id ? {
              ...template,
              site: template?.site?.id,
              templateItem: template?.templateItem,
            } : {
              site: currentSite ? currentSite.id : null,
              showRootCategoriesPage: true,
              isPriceVisible: true,
              isProductDetailVisible: true,
              categoriesDisplayType: 'grid',
              categoriesByColumn: '1',
              productDisplayType: 'icon',
              productsByColumn: '1',
              logo: site.company_logo || null,
              image: site.images?.length ? site.images[0] : null,
              primaryColor: '#333333',
              primaryShadeColor: '#111111',
              secondaryColor: '#555555',
              secondaryShadeColor: '#333333',
              titleFont: 'MontserratBold',
              subtitleFont: 'MontserratBold',
              primaryFont: 'Lato',
              backdropTextColor: 'black',
              cardTextColor: 'black',
              primaryTextColor: 'white',
              secondaryTextColor: 'white',
            }
          }
          mutators={{
            // eslint-disable-next-line no-unused-vars
            setFieldValue: ([field], state, utils) => {
              utils.changeValue(state, field.field, () => field.value);
            },
          }}
          render={({
            handleSubmit,
            submitting,
            pristine,
            values,
          }) => {
            const tabs = [
              {
                key: 'general',
                label: t('template.general'),
                children: <TemplateGeneralForm onSubmit={onSubmit} template={template} />,
              },
              {
                key: 'categories',
                label: t('menu.categories'),
                children: <TemplateCategoriesForm onSubmit={onSubmit} template={template} values={values} />,
              },
              {
                key: 'products',
                label: t('template.products'),
                children: <TemplateProductForm onSubmit={onSubmit} template={template} values={values} />,
              },
            ];

            return (
              <form onSubmit={handleSubmit} noValidate>
                <FormScrollError />

                <Tabs>
                  {tabs.map((tab) => (
                    <Tab {...tab}>
                      {tab.children}
                    </Tab>
                  ))}
                </Tabs>

                <FormInput
                  type="submit"
                  label={t('common.save')}
                  icon="map-pin"
                  disabled={submitting || pristine}
                />
              </form>
            );
          }}
        />

      </section>
    </>
  );
};

export default TemplateFormPage;
