/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, {
  useState, useRef, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import arrayMove from 'array-move';

import CropModal from './CropModal';
import DefaultFilesList from './DefaultFilesList';
import { getCroppedImg } from './utils';

const UploadFiles = ({
  multiple,
  value,
  accept,
  uploadUrl,
  children,
  onChange,
  nbCols,
  label,
  maxSize,
  max,
  maxWidth,
  maxHeight,
  keepRatio,
  crop,
  name,
  onUpload,
  'data-cy': dataCy,
  ...rest
}) => {
  const { t } = useTranslation();
  const inputEl = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [tmpFiles, setTmpFiles] = useState(null);
  const [uploadingFiles, setUploadingFiles] = useState({});

  const handleFileSelected = useCallback(({ target: { files: inputFiles } }) => {
    if (!inputFiles.length || (files.length + inputFiles.length) > max) {
      return;
    }

    const aspect = keepRatio ? maxWidth / maxHeight : 0;
    const fileslist = [];

    for (let i = 0; i < inputFiles.length; i++) {
      const maxIndex = Math.max(...fileslist.map((currentFile) => currentFile.index));
      const file = {
        index: fileslist.length ? maxIndex + 1 : 0,
        inputFile: inputFiles.item(i),
        name: inputFiles.item(i).name,
        crop: {
          x: 0, y: 0, width: 0, height: 0, unit: 'px', aspect,
        },
        url: URL.createObjectURL(inputFiles.item(i)),
      };

      if (file.inputFile.size <= maxSize) {
        fileslist.push(file);
      }
    }

    setTmpFiles(fileslist);
    setShowModal(true);
  }, [files, setTmpFiles, keepRatio, maxWidth, maxHeight, max, maxSize]);

  const onRemoveFile = useCallback((removeFile) => () => {
    if (multiple) {
      const newFiles = files.filter((f) => f.hash !== removeFile.hash);

      onChange(newFiles);
    } else {
      onChange(null);
    }
  }, [multiple, files, onChange]);

  const sendFile = useCallback(async (file) => new Promise((resolve) => {
    const req = new XMLHttpRequest();

    req.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        setUploadingFiles((prevFiles) => ({
          ...prevFiles,
          [file.name]: {
            ...file,
            state: 'pending',
            percentage: (event.loaded / event.total) * 100,
          },
        }));
      } else {
        console.log('not computable');
      }
    });

    req.upload.addEventListener('load', () => {
      setUploadingFiles((prevFiles) => ({
        ...prevFiles,
        [file.name]: {
          ...file,
          state: 'done',
          percentage: 100,
        },
      }));
    });

    req.upload.addEventListener('error', () => {
      setUploadingFiles((prevFiles) => ({
        ...prevFiles,
        [file.name]: {
          ...prevFiles[file.name],
          state: 'error',
          percentage: 0,
        },
      }));
      resolve(null);
    });

    // eslint-disable-next-line func-names
    req.addEventListener('load', function () {
      try {
        // eslint-disable-next-line react/no-this-in-sfc
        const response = JSON.parse(this.response);

        if (onUpload) {
          onUpload(response);
        }

        resolve({
          ...response[0],
          state: 'done',
          percentage: 100,
          filename: response.filename,
        });
      } catch (e) {
        resolve({
          ...file,
          state: 'error',
          percentage: 0,
          filename: 'titi',
        });
      }
    });

    const token = window.localStorage.getItem('jwt');
    const formData = new FormData();

    formData.append('files', file.file);
    req.open('POST', uploadUrl);
    req.setRequestHeader('Authorization', `Bearer ${token}`);
    req.send(formData);
  }), [uploadUrl, onUpload, setUploadingFiles]);

  const handleCloseModal = useCallback(() => {
    inputEl.current.value = '';
    setTmpFiles(null);
    setShowModal(false);
  }, [setTmpFiles, setShowModal]);

  const handleConfirmModal = useCallback(async () => {
    setShowModal(false);

    const filesToUpload = {};

    for (const file of tmpFiles) {
      const fileObj = await getCroppedImg(file.image,
        file.inputFile.type,
        file.crop,
        file.inputFile.name,
        maxWidth,
        maxHeight);

      filesToUpload[file.name] = {
        name: file.name,
        url: URL.createObjectURL(fileObj),
        file: fileObj,
        type: file.inputFile.type,
        crop: file.crop,
        state: 'pending',
        percentage: 0,
      };
    }
    setUploadingFiles(filesToUpload);

    const promiseFiles = [];

    Object.values(filesToUpload).forEach((f) => {
      promiseFiles.push(sendFile(f));
    });
    const uploadedFiles = await Promise.all(promiseFiles);

    if (multiple) {
      const newFiles = [...files];

      uploadedFiles.forEach((file) => {
        if (file) newFiles.push(file);
      });
      setFiles(newFiles);
      onChange(newFiles);
    } else {
      setFiles(uploadedFiles);
      onChange(uploadedFiles[0]);
    }

    setUploadingFiles({});
  }, [onChange, multiple, tmpFiles, setShowModal, setUploadingFiles, files, setFiles, sendFile, maxHeight, maxWidth]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newFiles = arrayMove(files, oldIndex, newIndex);

    newFiles[0].updated_at = new Date();
    setFiles(newFiles);
    onChange([...newFiles]);
  };

  const ufiles = Object.values(uploadingFiles) ?? [];
  const fileslist = [...files, ...ufiles];

  useEffect(() => {
    if (value) {
      setFiles(multiple ? value : [value]);
    } else {
      setFiles([]);
    }
  }, [value, setFiles, multiple]);

  if (multiple) {
    return (
      <div {...rest}>
        { showModal && (
          <CropModal
            confirmModal={handleConfirmModal}
            closeModal={handleCloseModal}
            tmpFiles={tmpFiles}
            setTmpFiles={setTmpFiles}
          />
        )}
        <div className="multiple-image-uploader">
          <label className="input input-image-uploader">
            { label || t('uploader.uploadImages')}
          </label>
          <input
            name={name}
            ref={inputEl}
            data-cy={dataCy}
            type="file"
            accept={accept}
            className="file-upload"
            onChange={handleFileSelected}
            multiple
          />
          { (children
            ? children({ items: fileslist, onRemoveFile, nbCols })
            : (
              <DefaultFilesList
                axis="xy"
                nbCols={nbCols}
                ratio={maxHeight / maxWidth}
                onSortEnd={onSortEnd}
                items={fileslist}
                onRemoveFile={onRemoveFile}
              />
            )
          )}
        </div>
      </div>
    );
  }

  const style = fileslist[0] ? { backgroundImage: `url(${process.env.REACT_APP_API_URL}${fileslist[0].url})` } : null;
  const classNames = `input image-item single-image-uploader ${fileslist[0] ? 'image-load' : ''}`;

  return (
    <div {...rest}>
      { showModal && (
      <CropModal
        confirmModal={handleConfirmModal}
        closeModal={handleCloseModal}
        tmpFiles={tmpFiles}
        setTmpFiles={setTmpFiles}
      />
      )}
      <div className={classNames} style={style}>
        <label className="input input-image-uploader">
          { label || t('uploader.uploadImage')}
        </label>
        { (fileslist[0] && fileslist[0].percentage !== 100) && (
          <div className="ProgressBar">
            <div className="Progress" style={{ width: `${fileslist[0].percentage}%` }} />
          </div>
        )}

        <input
          name={name}
          ref={inputEl}
          type="file"
          accept={accept}
          className="file-upload"
          onChange={handleFileSelected}
          multiple={multiple}
        />
        { fileslist[0] && (
          <div className="image-delete">
            <button type="button" className="button is-danger" onClick={onRemoveFile()}>
              <span className="icon">
                <i className="fas fa-trash-alt" />
              </span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

UploadFiles.propTypes = {
  uploadUrl: PropTypes.string,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  nbCols: PropTypes.number,
  max: PropTypes.number,
  maxSize: PropTypes.number,
  children: PropTypes.element,
  onChange: PropTypes.func,
  keepRatio: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.any,
  crop: PropTypes.bool, // Not Used currently
  name: PropTypes.string,
  'data-cy': PropTypes.string,
  onUpload: PropTypes.func,
};

UploadFiles.defaultProps = {
  uploadUrl: `${process.env.REACT_APP_API_URL}/upload`,
  onChange() {},
  value: null,
  max: Infinity,
  maxSize: Infinity,
  maxWidth: 5000,
  maxHeight: 5000,
  nbCols: 3,
  multiple: false,
  label: null,
  keepRatio: true,
  accept: 'image/x-png,image/gif,image/jpeg',
  children: null,
  crop: false,
  name: '',
  'data-cy': '',
  onUpload: null,
};

export default UploadFiles;
