import CircleColorPicker from 'common/components/CircleColorPicker/CircleColorPicker';
import ColorPicker from 'common/components/ColorPicker/ColorPicker';
import DatePicker from 'common/components/DatePicker/DatePicker';
import Geolocation from 'common/components/Geolocation/Geolocation';
import HtmlEditor from 'common/components/HtmlEditor/HtmlEditor';
import ImageUploader from 'common/components/ImageUploader/ImageUploader';
import Select from 'common/components/Select/Select';
import TextArea from 'common/components/TextArea/TextArea';
import TimePicker from 'common/components/TimePicker/TimePicker';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

const keepEmptyValue = (value) => (value === '' ? null : value);

export const Input = ({
  type, icon, children, ...otherProps
}) => {
  const className = otherProps.className ? otherProps.className : '';
  const dataCy = otherProps.name ? otherProps.name.split('.')[0] : '';

  switch (type) {
    case 'submit':
      return (
        <div className="submit-box">
          <button
            className="button is-success"
            data-cy="submit"
            type="submit"
            disabled={otherProps.disabled}
          >
            {icon && (
              <span className="icon">
                <i className={`fas fa-${icon}`} />
              </span>
            )}
            <span>{otherProps.label}</span>
          </button>
        </div>
      );

    case 'date':
      return <DatePicker data-cy={dataCy} {...otherProps} />;

    case 'datetime':
      return (<TimePicker data-cy={dataCy} datetime {...otherProps} />);

    case 'time':
      return (<TimePicker data-cy={dataCy} {...otherProps} />);

    case 'select':
      return <Select data-cy={otherProps.name} {...otherProps} />;

    case 'image':
      return <ImageUploader data-cy={dataCy} {...otherProps} />;

    case 'geolocation':
      return <Geolocation data-cy={dataCy} {...otherProps} />;

    case 'checkbox':
      return (
        <>
          <div className={`checkbox-input ${otherProps.isVertical ? 'is-vertical' : ''}`}>
            <input
              id={otherProps.name}
              defaultValue={false}
              type="checkbox"
              data-cy={dataCy}
              {...otherProps}
              className={`is-checkradio ${className}`}
            />
            <label htmlFor={otherProps.name}>{otherProps.label || otherProps.placeholder}</label>
          </div>
        </>
      );

    case 'custom':
      return React.cloneElement(children, {
        ...otherProps,
        'data-cy': otherProps.name,
      });

    case 'circle-color':
      return <CircleColorPicker {...otherProps} />;

    case 'color':
      return <ColorPicker data-cy={dataCy} {...otherProps} />;

    case 'textarea':
      return <TextArea data-cy={dataCy} {...otherProps} />;

    case 'htmlEditor':
      return <HtmlEditor data-cy={dataCy} {...otherProps} />;

    default:
      return (
        <input
          type={type}
          data-cy={dataCy}
          {...otherProps}
          className={`input ${className} `}
        />
      );
  }
};

Input.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.element,
};

Input.defaultProps = {
  type: 'text',
  icon: '',
  children: null,
};

// eslint-disable-next-line max-len
const composeValidators = (validators) => (value) => validators.reduce((error, validator) => error || validator(value), undefined);

const FormInput = ({
  icon,
  validate,
  required,
  label,
  type,
  onChange,
  children,
  isHorizontal,
  ...rest
}) => {
  const { t } = useTranslation();

  const reqRule = (value) => (value !== '' && value != null ? undefined : t('common.fieldRequired'));
  const checkboxReq = (value) => value === true ? undefined : t('common.fieldRequired');

  let validator = !validate && required ? reqRule : validate;

  validator = (type === 'checkbox' && required) ? checkboxReq : validator;

  if (Array.isArray(validator)) {
    if (validator.length) {
      validator = composeValidators(validator);
    } else {
      validator = null;
    }
  }

  const formLabel = label && required ? `${label} *` : label;

  if (type === 'submit') {
    return (
      <div className="field">
        <div className="control">
          <Input
            type={type}
            icon={icon}
            label={formLabel}
            {...rest}
          />
        </div>
      </div>
    );
  }

  return (
    <Field
      type={type}
      icon={icon}
      validate={validator}
      label={label}
      required={required}
      parse={keepEmptyValue}
      {...rest}
    >
      {({ input, meta, ...extra }) => {
        let { className } = extra;

        if (meta.touched && meta.error) {
          className = className ? `${className} is-danger` : 'is-danger';
        }

        return (
          <div className={`field ${isHorizontal ? 'is-horizontal' : ''}  ${meta.error ? 'fieldError' : ''}`}>
            {type !== 'checkbox' && (
            <label className="label">{label}</label>
            )}
            <div
              className={`control ${icon && 'has-icons-left has-icons-right '}`}
            >
              <Input
                {...input}
                {...extra}
                className={className}
                onChange={(val, row) => {
                  onChange(val, row);
                  input.onChange(val);
                }}
              >
                {children}
              </Input>
              {icon && (
                <span className="icon is-small is-left">
                  <i className={`fas fa-${icon}`} />
                </span>
              )}
              {icon && meta.touched && meta.error && (
                <span className="icon is-small is-right">
                  <i className="fas fa-exclamation-triangle" />
                </span>
              )}
            </div>
            {rest.legend && (
            <p className="help">{rest.legend}</p>
            )}
            {meta.touched && meta.error && (
              <p className="help is-danger">{meta.error}</p>
            )}
          </div>
        );
      }}
    </Field>
  );
};

FormInput.propTypes = {
  icon: PropTypes.string,
  isHorizontal: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.element,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  required: PropTypes.bool,

};

FormInput.defaultProps = {
  isHorizontal: false,
  type: 'text',
  icon: '',
  label: '',
  onChange() { },
  children: null,
  validate: null,
  required: false,
};

export default FormInput;
